import ObjectModel from './objectmodel';
import Meta from './meta';
import { DESCRIPTION, TITLE } from './nodetypes';

export default class Asset extends ObjectModel {
  constructor(_id, _owner, _users, _teams, _userLoggedIn, _title, _description, _link, _createdTime, _updateTime, _state, _shareSetting, _version, _company, _type, _background, _tagGroups) {
    super();
    this._id = _id;
    this.title = new Meta(_title || '', [TITLE], [], null, this.userLoggedIn);
    this.description = new Meta(_description || '', [DESCRIPTION], [], null, this.userLoggedIn);
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.owner = _owner;
    this.users = _users || [];
    this.teams = _teams || [];
    this.state = _state;
    this.shareSetting = _shareSetting || 'LinkView';
    this.link = _link;
    this.version = _version || 1;
    this.company = _company || '';
    this.type = _type;
    this.background = _background;
    this.tagGroups = _tagGroups;
  }
  getJson = () => {
    const { _id, owner, title, description, link, version, state, shareSetting, users, teams, company, type, background, tagGroups } = this;
    const assetJson = {
      _id,
      owner,
      title: title.text,
      description: description.text,
      link,
      version,
      state,
      shareSetting,
      users,
      teams,
      type,
      background,
      tagGroups,
      company
    };
    return assetJson;
  }
}
