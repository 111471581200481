import Service from './Service';
import Navigation from './Navigation';
import UserAccount from './UserAccount';
import OpenAIApi from './server/OpenAIApi';
import LogicBaseAppApi from './server/LogicBaseAppApi';
import { initializeLogicBaseLatestApps, initializeTrendingTopics } from './Pagination';

const origin = (window.logictry && window.logictry.origin) || window.location.origin;

class OpenAIService extends Service {
  createQuestion = async (settings) => {
    const { question } = settings;
    if (this.openAIState && !this.openAIState.complete) return;
    this.openAIState = {
      question,
      response: '',
      complete: false,
    };
    this.emitStateUpdate();

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = async () => {
      if (xhttp.readyState === XMLHttpRequest.LOADING || xhttp.readyState === XMLHttpRequest.DONE) {
        this.openAIState = {
          question,
          response: xhttp.responseText,
          complete: xhttp.readyState === XMLHttpRequest.DONE
        };
        if (xhttp.readyState === XMLHttpRequest.DONE) {
          let count = 0;
          this.testComplete = setInterval(async() => {
            count += 1;
            if (count > 10) {
              this.openAIState = null;
              clearInterval(this.testComplete);
              this.testComplete = null;
              this.emitStateUpdate();
            } else {
              try {
                const metaData = this.openAIState.response.split('}')[0] + '}';
                const response = JSON.parse(metaData);
                const result = await LogicBaseAppApi.query({
                  u: response.url,
                });
                const url = result && result.length > 0 && result[0].u;
                if (url) {
                  setTimeout(() => {
                    Navigation.push(`/${url}`);
                    initializeLogicBaseLatestApps();
                    initializeTrendingTopics();
                    this.openAIState = null;
                    clearInterval(this.testComplete);
                    this.testComplete = null;
                    this.emitStateUpdate();
                  }, 1000)
                }
              } catch (e) {
                //
              }
            }
          }, 2000);
        }
        this.emitStateUpdate();
      }
    };
    xhttp.open('PATCH', `${origin}/v1/openai`, true);
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    if (csrfToken) xhttp.setRequestHeader("csrf-token", csrfToken.content);
    xhttp.send(JSON.stringify(settings || {}));
  }
  getCount = async () => {
    const result = await OpenAIApi.getCount();
    if (result && result.count) this.decisionsMadeCount = result.count;
    this.emitStateUpdate();
  }
  getAnswer = async (tree, resultOnly) => {
    const _id = tree._id;
    const result = await OpenAIApi.update({
      template: tree.referenceTree || _id,
      question: tree.title.text,
      answers: tree.getOpenAIPrompt(),
      resultOnly
    }, null, { _id });
    return result;
  }
  getEmbeddings = async (body) => {
    const result = await OpenAIApi.getEmbeddings(body);
    return result;
  }
  askQuestion = (messages) => {
    this.openAIState = {
      messages,
      response: '',
      complete: false,
    };
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === XMLHttpRequest.LOADING || xhttp.readyState === XMLHttpRequest.DONE) {
        this.openAIState = {
          messages,
          response: xhttp.responseText,
          complete: xhttp.readyState === XMLHttpRequest.DONE
        };
        this.emitStateUpdate();
      }
    };
    xhttp.open('POST', `${origin}/v1/openai`, true);
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    if (csrfToken) xhttp.setRequestHeader("csrf-token", csrfToken.content);
    xhttp.send(JSON.stringify(messages || []));
  }
}

const singleton = new OpenAIService();
export default singleton;
