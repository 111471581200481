/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import smoothscrollfix from '../../utils/smoothscrollfix';
import Navigation from '../../services/Navigation';
import Scroll from '../../services/Scroll';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
if (window.history && window.history.state) {
  Object.keys(window.history.state).forEach((key) => {
    if (!['logicbase', 'tree'].find((prefix) => key.startsWith(prefix))) return;
    window.history.replaceState({ [key]: 0 }, document.title)
  });
}

export default class Scrollable extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
    onScroll: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    scrollRef: PropTypes.func,
    vertical: PropTypes.bool,
    horizontal: PropTypes.bool,
    rememberScrollPosition: PropTypes.string,
    id: PropTypes.string,
    paginationHeight: PropTypes.any,
    onPagination: PropTypes.func,
    disableFlex: PropTypes.bool,
  }
  componentDidMount() {
    const { paginationHeight } = this.props;
    if (paginationHeight) document.addEventListener('scroll', this.onBodyScroll);
    Navigation.onStateUpdate(this.navigationUpdate);
    Scroll.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { paginationHeight } = this.props;
    if (paginationHeight) document.removeEventListener('scroll', this.onBodyScroll);
    if (this.nativeScrollRef) this.nativeScrollRef.removeEventListener('scroll', this.onNativeScroll);
    Navigation.offStateUpdate(this.navigationUpdate);
    Scroll.offStateUpdate(this);
  }
  navigationUpdate = (_, state) => {
    if (state === 'SCROLL_TOP') {
      const { rememberScrollPosition } = this.props;
      const state = { ...(window.history && window.history.state || {}) };
      if (state[`${rememberScrollPosition}_vertical`]) state[`${rememberScrollPosition}_vertical`] = 0;
      if (state[`${rememberScrollPosition}_horizontal`]) state[`${rememberScrollPosition}_horizontal`] = 0;
      window.history.replaceState(state, document.title);
      this.state.scrollStarted = Date.now();
      this.scrollTopTimeout(this.scrollRef, 0);
      this.scrollLeftTimeout(this.scrollRef, 0);
    }
  }
  state = {
    scrollStarted: null,
  }
  onNativeScroll = () => {
    const { paginationHeight, onPagination } = this.props;
    if (this.nativeScrollRef) {
      const { height } = this.nativeScrollRef.getBoundingClientRect();
      const { scrollTop, scrollHeight } = this.nativeScrollRef;
      if (height > (paginationHeight + 200) && (scrollTop + paginationHeight) > (scrollHeight - height) && scrollHeight !== this.lastGetMoreHeight) {
        this.lastGetMoreHeight = scrollHeight;
        if (onPagination) onPagination();
      }
    }
  }
  onBodyScroll = () => {
    const { paginationHeight, onPagination } = this.props;
    if (!paginationHeight) return;
    if (!this.scrollRef) return;
    const { height, bottom } = this.scrollRef.getBoundingClientRect();
    if (height > (paginationHeight + 200) && (window.innerHeight + paginationHeight) > bottom && height !== this.lastGetMoreHeight) {
      this.lastGetMoreHeight = height;
      if (onPagination) onPagination();
    }
  }
  onScroll = (e) => {
    const { paginationHeight, onPagination } = this.props;
    if (this.scrollRef) {
      const { height } = this.scrollRef.getBoundingClientRect();
      const { scrollTop, scrollHeight } = this.scrollRef;
      if (height > (paginationHeight + 200) && (scrollTop + paginationHeight) > (scrollHeight - height) && scrollHeight !== this.lastGetMoreHeight) {
        this.lastGetMoreHeight = scrollHeight;
        if (onPagination) onPagination();
      }
    }
    const { rememberScrollPosition, vertical, horizontal } = this.props;
    if (!e || !rememberScrollPosition) return;
    clearTimeout(this.replaceHistoryTimeout);
    this.replaceHistoryTimeout = setTimeout(() => {
      const state = { ...(window.history && window.history.state || {}) };
      if (vertical) state[`${rememberScrollPosition}_vertical`] = e.target.scrollTop;
      if (horizontal) state[`${rememberScrollPosition}_horizontal`] = e.target.scrollLeft;
      window.history.replaceState(state, document.title);
    }, 100);
  }
  onScrollRef = (e) => {
    const { rememberScrollPosition, vertical, horizontal } = this.props;
    if (!e || !rememberScrollPosition) return;
    this.state.scrollStarted = Date.now();
    if (vertical) {
      const scrollTop = window.history && window.history.state && window.history.state[`${rememberScrollPosition}_vertical`];
      if (scrollTop) this.scrollTopTimeout(e, scrollTop);
    }
    if (horizontal) {
      const scrollLeft = window.history && window.history.state && window.history.state[`${rememberScrollPosition}_horizontal`];
      if (scrollLeft) this.scrollLeftTimeout(e, scrollLeft);
    }
    const content = findParentWithClass(e);
    if (!content) return;
    content.getScrollElement().then((el) => {
      if (!el) return;
      if (this.nativeScrollRef) this.nativeScrollRef.removeEventListener('scroll', this.onNativeScroll);
      el.addEventListener('scroll', this.onNativeScroll);
      this.nativeScrollRef = el;
    });
  }
  scrollTopTimeout = (e, scrollTop) => {
    if (e.scrollTop == scrollTop || (Date.now() - this.state.scrollStarted) > 2000) {
      return;
    };
    e.scrollTo({ top: scrollTop });
    setTimeout(() => this.scrollTopTimeout(e, scrollTop));
  }
  scrollLeftTimeout = (e, scrollLeft) => {
    if (e.scrollLeft == scrollLeft || (Date.now() - this.state.scrollStarted) > 2000) {
      return;
    };
    e.scrollTo({ left: scrollLeft });
    setTimeout(() => this.scrollLeftTimeout(e, scrollLeft));
  }
  render() {
    const { allowScroll } = Scroll;
    const { children, style = {}, onKeyDown, onKeyUp, horizontal, vertical, onScroll, scrollRef, id, disableFlex } = this.props;
    const outerStyle = {
      overflow: 'hidden'
    };
    const middleStyle = {
      overflowX: (horizontal && allowScroll) ? 'auto' : 'hidden',
      overflowY: (vertical && allowScroll) ? 'auto' : 'hidden',
      display: 'flex',
      flexDirection: 'column',
      inset: 0,
    };
    if (horizontal) {
      middleStyle.marginBottom = -40;
      middleStyle.paddingBottom = 40;
    }
    if (vertical) {
      middleStyle.marginRight = -40;
      middleStyle.paddingRight = 40;
    }
    const innerStyle = {
      position: 'relative',
      flex: disableFlex ? null : 1,
    };
    ['position'].forEach((v) => {
      if (style[v] !== undefined) {
        outerStyle[v] = style[v];
        middleStyle[v] = style[v];
      }
    });
    [ 'inset', 'top', 'right', 'bottom', 'left', 'height', 'width'].forEach((v) => {
      if (style[v] !== undefined) {
        outerStyle[v] = style[v];
      }
    });
    [ 'maxHeight'].forEach((v) => {
      if (style[v] !== undefined) {
        middleStyle[v] = style[v];
      }
    });
    [ 'padding', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'display', 'alignItems', 'justifyContent', 'flexDirection', 'flexWrap'].forEach((v) => {
      if (style[v] !== undefined) {
        innerStyle[v] = style[v];
      }
    });
    return (
      <Wrapper
        style={outerStyle}
      >
        <div
          id={id}
          style={middleStyle}
          onScroll={(e) => {
            this.onScroll(e);
            if (onScroll) onScroll(e);
          }}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          ref={(e) => {
            if (!e) return;
            if (e === this.scrollRef) return;
            this.scrollRef = e;
            smoothscrollfix(e);
            this.onScrollRef(e);
            if (scrollRef) scrollRef(e);
          }}
        >
          <div style={innerStyle}>
            {children}
          </div>
        </div>
      </Wrapper>
    );
  }
}

function findParentWithClass(element) {
  // Start with the parent of the given element
  let parent = element.parentNode;

  // Keep traversing up the DOM tree until we reach the top or find the desired class
  while (parent !== null) {
      // Check if the parent has the desired class
      if (parent.tagName === 'ION-CONTENT') {
          // Return the parent if it has the desired class
          return parent;
      }

      // Move to the next parent node
      parent = parent.parentNode;
  }

  // Return null if no parent with the desired class is found
  return null;
}
