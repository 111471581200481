/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Navigation from '../../services/Navigation';
import { getLogicBaseGeoApps } from '../../services/Pagination';
import OpenStreetMapBox from '../TreeAnswerPage/OpenStreetMapBox';
import LogicBaseTopics from '../../components/LogicBaseTopics/index';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserCache from '../../services/cache/UserCache';
import Constants from '../../submodules/logictry_config/constants';
import L from 'leaflet';

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  .gm-style > div:first-child > div:first-child > div:nth-child(4) img {
    border-radius: 50%;
    overflow: hidden;
  }
  > div:nth-child(2) {
    position: absolute;
    inset: 0;
    top: 50px;
  }
  ${Constants.MediaMobile} {
    position: fixed;
    inset: 3.5rem 0 0 0;
  }
`;
const TopicsBar = styled.div``;

export default class LogicBaseMapPage extends React.PureComponent {
  constructor() {
    super();
    const { tags } = Navigation.currentSearch;
    this.cache = getLogicBaseGeoApps(['-97.733330,30.266666', tags  || '']);
  }
  componentDidMount = () => {
    this.cache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount = () => {
    this.cache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
  }
  render() {
    const apps = this.cache.currentPage;
    const ownerIds = apps.map(({ owner }) => owner);
    // const publicUsers = LogicBaseUserCache.getByIds(ownerIds);
    const users = UserCache.getUsersByIds(ownerIds);
    if (!users) return null;

    const markers = {};
    apps.forEach(({ title, description, url, geo, owner }) => {
      if (!geo || !geo.coordinates) return;
      const user = users.find(({ _id }) => owner === _id);
      const lng = geo.coordinates[0];
      const lat = geo.coordinates[1];
      const key = `${lng}_${lat}`;
      const icon = user.image && user.image.link && new L.Icon({
        iconUrl: user.image.link,
        iconRetinaUrl: user.image.link,
        iconSize: [32,32],
        iconAnchor: new L.Point(16, 32),
        popupAnchor: new L.Point(-16, -32),
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        className: 'logic-map-icon'
      });
      markers[key] = {
        lng,
        lat,
        iconUrl: user.image && user.image.link,
        label: <div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img alt={user.fullname} style={{ width: '2rem', height: '2rem', borderRadius: '50%', backgroundSize: 'cover' }} src={user.image && user.image.link || ''}></img>
              <span style={{ paddingLeft: '0.5rem' }}>{user.fullname}</span>
            </div>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => Navigation.push(`/${url}`)}>
            <h2>{title}</h2>
          </div>
          <p>{description}</p>
        </div>,
        icon
      };
    });
    return (
      <Wrapper>
        <TopicsBar>
          <LogicBaseTopics row url={'/map'} />
        </TopicsBar>
        <div>
          <OpenStreetMapBox markers={markers} />
        </div>
      </Wrapper>
    );
  }
}
