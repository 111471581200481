/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import SparkMD5 from 'spark-md5';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import Connect from '../../services/Connect';
import SidebarService from '../../services/SidebarService';
import SelectMenu from '../../components/SelectMenu/index';
import ClickableDiv from '../../components/ClickableDiv/index';
import ClickableIcon from '../../components/ClickableIcon/index';
import purifyhtml from '../../submodules/logictry_purifyhtml';
import ConnectAutoHeight from './ConnectAutoHeight';
import Scrollable from '../../components/Scrollable/index';
import OpenAI from '../../services/OpenAI';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import changeRelativeLinksToAbsoluteForNativeApp from '../../utils/changeRelativeLinksToAbsoluteForNativeApp';
import { getFontInclude } from '../../utils/fonts';

const Icon = styled.div`
  background-color: ${Constants.White};
  > div {
    display: flex;
    align-items: center;
    > div {
      text-decoration: underline;
    }
  }
  * {
    font-size: 0.75rem;
  }
`;
const CustomReportIcons = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  background: white;
  > div {
    display: flex;
    > div {
      margin: 0px 0.5rem;
      ${Constants.MediaMobile} {
        margin: 0px 0.25rem;
      }
    }
  }
`;
const ScriptForClicks = `
  document.onmousedown = (e) => {
    e = e ||  window.event;
    const element = e.target || e.srcElement;
    __recursivelyAddOnclickToAllHrefs(element);
  };
  function __recursivelyAddOnclickToAllHrefs(node) {
    if (!node) return;
    if (node.tagName === 'A') {
      node.onclick = (e) => {
        if (!node.href) return;
        e.preventDefault();
        e.stopPropagation();
        if (e.metaKey || e.altKey || node.target === '_blank') {
          window.open(node.href);
        } else {
          window.top.location.href = node.href;
        }
      }
    }
    if (!node.parentNode) return;
    __recursivelyAddOnclickToAllHrefs(node.parentNode);
  }
`;

// let blob;
// async function fetchImage(url) {
//   try {
//     const response = await fetch(url);
//     const blob = await response.blob();
//     return blobToRaw(blob);
//   } catch (error) {
//     console.error('Error fetching image:', error);
//   }
// }

// function blobToRaw(blob) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       const rawData = new Uint8Array(reader.result);
//       resolve(rawData);
//     };
//     reader.onerror = reject;
//     reader.readAsArrayBuffer(blob);
//   });
// }

// // Usage
// const imageUrl = 'https://logictrystatic.s3.amazonaws.com/images/fireworks.png';
// fetchImage(imageUrl)
//   .then(rawData => {
//     // Use the raw data here
//     console.log(rawData);
//     blob = rawData
//   });

// async function loadImage() {
//   const imgURL = `https://logictrystatic.s3.amazonaws.com/images/fireworks.png`;
//   const data = await fetch(imgURL);
//   console.log('data', data)
//   blob = await data.blob();
//   console.log('loaded', blob)
// }
// loadImage();

export default class TextView extends React.PureComponent {
  static propTypes = {
    saveChanges: PropTypes.func,
    onScroll: PropTypes.func,
  }
  state = {
    answersOnly: true,
    includeLink: true,
    showInfo: false,
    iframeSizeSet: false,
    copied: false,
    gettingOpenAI: false,
    openAIResponse: '',
  }
  async componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    Connect.onStateUpdate(this);
    Connect.scrollTopContent();
    const { gettingOpenAI } = this.state;
    const { tree } = GrayOut;
    const { useOpenAI } = tree.report;
    if (!useOpenAI || gettingOpenAI) return;
    this.setState({ gettingOpenAI: true });
    const openAIResponse = await OpenAI.getAnswer(tree);
    this.setState({ gettingOpenAI: false, openAIResponse });
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    Connect.offStateUpdate(this);
    if (this.resizeInterval) {
      clearInterval(this.resizeInterval);
      this.resizeInterval = null;
    }
  }
  changeShowInfo = (showInfo) => {
    this.setState({ showInfo });
  }
  changeAnswersOnly = (answersOnly) => {
    this.setState({ answersOnly });
  }
  changeIncludeLink = (includeLink) => {
    this.setState({ includeLink });
  }
  copy = (html) => {
    const node = document.createElement('div');
    document.body.appendChild(node);
    node.innerHTML = html;
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select().createTextRange();
      document.execCommand('copy');
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(node);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
    }
    node.innerHTML = '';
    document.body.removeChild(node);
    this.setState({ copied: true });
  }
  createPdf = () => {
    if (!this.iframeRef) return;
    const { tree } = GrayOut;
    const filename = tree.title.text || tree._id;
    const mainDocumentTitleBeforePrint = document.title;
    this.iframeRef.contentWindow.document.title = filename;
    // Must set the main document title as well to affect the filename default
    document.title = filename;
    this.iframeRef.contentWindow.print();
    document.title = mainDocumentTitleBeforePrint;
  }
  onRef = (e, formattedHtml, isCustomReport, fontName, fontSizeH1, fontSizeH2, fontSizeH3) => {
    if (!e) return;
    if (this.iframeRef && e.contains(this.iframeRef)) return;
    this.iframeRef = document.createElement('iframe');
    this.iframeRef.style.width = '100%';
    this.iframeRef.style.height = '100%';
    this.iframeRef.onload = () => {
      // Get rid of header and footer and add page break
      // const printStyle = `<style type="text/css" media="print">@page { size:  auto; margin: 0mm; } html { background-color: #FFFFFF; margin: 0px; } body { border: solid 1px white; margin: 15mm; }@media print { .pagebreak { page-break-before: always; } }</style>`;
      const parser = new DOMParser();
      const fontUrl = getFontInclude(fontName);
      const dom = parser.parseFromString(`<link rel="stylesheet" href="${window.location.origin}/css/webapp.css"><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900"><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900">${fontName ? `${fontUrl || ''}<style>body { font-family: ${fontName} !Important; } body h1 { font-size: ${fontSizeH1}; } body h2 { font-size: ${fontSizeH2}; } body h3 { font-size: ${fontSizeH3}; }</style>` : ''}<style>* { -webkit-print-color-adjust: exact !important; color-adjust: exact !important; } a { text-decoration: underline; }</style>${formattedHtml}`, 'text/html');
      let styles = [...dom.getElementsByTagName('style'), ...dom.getElementsByTagName('link')];
      const onScriptLoaded = (e) => {
        if (e && e.target) {
          const styleIndex = styles.indexOf(e.target);
          if (styleIndex < 0) return;
          styles.splice(styleIndex, 1);
        }
        if (styles.length > 0) return;
        if (!isCustomReport) {
          this.iframeRef.contentWindow.document.body.style.wordBreak = 'break-word';
          this.iframeRef.contentWindow.document.body.style.padding = '0 1.75rem';
        }
        this.iframeRef.contentWindow.document.body.innerHTML = `<main>${formattedHtml}</main>`;
        this.iframeRef.contentWindow.document.body.style.overflow = 'hidden';
        if (this.resizeInterval) clearInterval(this.resizeInterval);
        this.resizeInterval = setInterval(() => {
          if (!(this.iframeRef && this.iframeRef.contentWindow && this.iframeRef.contentWindow.document && this.iframeRef.contentWindow.document.body)) return;
          const main = this.iframeRef.contentWindow.document.getElementsByTagName('main')[0];
          main.style.display = 'flex';
          main.style.flexDirection = 'column';
          const { height } = main.getBoundingClientRect();
          this.iframeRef.style.height = `${height}px`;
          this.setState({ iframeSizeSet: true });
        }, 1);
      }
      styles.forEach((style) => {
        style.onload = onScriptLoaded;
        style.onerror = onScriptLoaded;
        this.iframeRef.contentWindow.document.head.appendChild(style);
      });
      const scriptForClicks = document.createElement('script');
      scriptForClicks.innerHTML = ScriptForClicks;
      this.iframeRef.contentWindow.document.head.appendChild(scriptForClicks);
    }
    e.appendChild(this.iframeRef);
  }
  // openInstagram = async (e) => {
  //   console.log('open')
  //   try {
  //     navigator.clipboard.write([
  //       new window.ClipboardItem({
  //         "com.instagram.sharedSticker.backgroundImage": blob,
  //       }),
  //     ]);
  //     window.open(`instagram-stories://share?source_application=530150654265703`, '_blank');
  //     console.log("Fetched image copied.");
  //   } catch (err) {
  //     console.error(err.name, err.message);
  //   }
  // }
  render() {
    const { saveChanges, onScroll } = this.props;
    const { answersOnly, includeLink, showInfo, iframeSizeSet, copied, gettingOpenAI, openAIResponse } = this.state;
    const { scrollDisabled } = Connect;
    const { tree } = GrayOut;
    const { isLoggedIn } = UserAccount;
    const { isApp } = Constants;
    const { saveOnShowReport } = SidebarService
    const { hidePrint, maxWidth, padding } = tree.report;
    const isCustomReport = tree.report.children.length > 0;
    const { isSavingProjectsAllowed, project, useOpenAI } = tree;
    const text = tree.getReport(answersOnly, includeLink, showInfo, openAIResponse && openAIResponse.result);
    const formattedHtml = changeRelativeLinksToAbsoluteForNativeApp(purifyhtml(text));
    const htmlHash = SparkMD5.hash(formattedHtml);
    const showSaveButton = (isSavingProjectsAllowed && !(saveOnShowReport));
    const showCustomReportIcons = !hidePrint || showSaveButton || !isCustomReport;
    const showLoginToChangeAnswers = !isLoggedIn && isApp && project;
    if (gettingOpenAI || (useOpenAI && !openAIResponse)) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const enableAutoResizing = Constants.isWidget && Navigation.currentSearch.autoresize !== 'false';
    return (
      <Scrollable
        vertical={!scrollDisabled}
        showVertical={!scrollDisabled}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        onScroll={onScroll}
        disableFlex={enableAutoResizing && scrollDisabled}
        rememberScrollPosition={`tree_report_${tree._id}`}
      >
        {showCustomReportIcons && <CustomReportIcons>
          <div>
            {isLoggedIn && <Icon>
              <ClickableDiv onClick={() => SidebarService.updateSelectType('Tree')}>
                <ClickableIcon style={{ transform: 'rotate(180deg)' }} small icon="fas fa-sign-in-alt" />
                <div>Edit submission</div>
              </ClickableDiv>
            </Icon>}
            {!hidePrint && <Icon>
              <ClickableDiv onClick={this.createPdf}>
                <ClickableIcon small icon="far fa-file-pdf" />
                <div>Export</div>
              </ClickableDiv>
            </Icon>}
            {!hidePrint && <Icon>
              <ClickableDiv onClick={() => this.copy(formattedHtml)}>
                <ClickableIcon small icon="far fa-copy" />
                <div>{copied ? 'Copied!' : 'Copy'}</div>
              </ClickableDiv>
            </Icon>}
            {showSaveButton && <Icon>
              <ClickableDiv onClick={saveChanges}>
                <ClickableIcon small icon="far fa-save" />
                <div>Save</div>
              </ClickableDiv>
            </Icon>}
            {showLoginToChangeAnswers && <Icon>
              <ClickableDiv onClick={() => Navigation.push(Navigation.login)}>
                <ClickableIcon small icon="fas fa-sign-in-alt" />
                <div>Login to edit submission</div>
              </ClickableDiv>
            </Icon>}
          </div>
          {!isCustomReport && <div style={{ marginTop: '0.5rem' }}>
            <div>
              <SelectMenu mini value={showInfo} options={[{ name: 'Hide Info', value: false }, { name: 'Show Info', value: true }]} onChange={this.changeShowInfo} />
            </div>
            <div>
              <SelectMenu mini value={answersOnly} options={[{ name: 'Answers Only', value: true }, { name: 'All Content', value: false }]} onChange={this.changeAnswersOnly} />
            </div>
            <div>
              <SelectMenu mini value={includeLink} options={[{ name: 'Show Link', value: true }, { name: 'Hide Link', value: false }]} onChange={this.changeIncludeLink} />
            </div>
          </div>}
        </CustomReportIcons>}
        <div key={htmlHash} ref={(e) => this.onRef(e, formattedHtml, isCustomReport, tree.report.fontFamily, tree.report.fontSizeH1, tree.report.fontSizeH2, tree.report.fontSizeH3)} style={{ maxWidth, padding, margin: 'auto' }}></div>
        {enableAutoResizing && iframeSizeSet && <ConnectAutoHeight />}
      </Scrollable>
    );
  }
}
