import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserAccount from '../../services/UserAccount';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../Button/index';
import Popup from '../Popup/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import { getAccountCollectionsEditor, getAccountCollectionsOwner, getTrendingCollections } from '../../services/Pagination/index';
import Scrollable from '../Scrollable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 3rem;
  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }
  > div:last-child {
    padding: 1.5rem 0 2rem;
    width: 100%;
    > div {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  > div {
    padding: 0rem 1rem;
    cursor: pointer;
    color: #666;
    text-align: center;
  }
`;

export default class CollectionsPopup extends BoxPopup {
  static propTypes = {
    onClose: PropTypes.func,
    app: PropTypes.object,
  }
  state = {
    content: UserAccount.isDevAccount ? 'All' : 'Owner'
  }
  constructor() {
    super();
    const { account, isDevAccount } = UserAccount;
    if (isDevAccount) this.collections = getTrendingCollections('');
    this.collectionOwner = getAccountCollectionsOwner(account._id);
    this.collectionInvited = getAccountCollectionsEditor(account._id);
  }
  componentDidMount() {
    if (this.collections) this.collections.onStateUpdate(this);
    this.collectionOwner.onStateUpdate(this);
    this.collectionInvited.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    if (this.collections) this.collections.offStateUpdate(this);
    this.collectionOwner.offStateUpdate(this);
    this.collectionInvited.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
  }
  addRemove = (collection) => {
    const { app } = this.props;
    if (!app || !collection) return;
    if (collection.apps.includes(app._id)) collection.apps = collection.apps.filter((id) => id !== app._id);
    else collection.apps.unshift(app._id);
    LogicBaseCollectionCache.update(collection);
  }
  onPagination = () => {
    const { content } = this.state;
    const cache = content === 'All' && this.collections || content === 'Owner' && this.collectionOwner || this.collectionInvited;
    if (cache.showMore && cache.allFound && cache.initialized) {
      cache.getMore();
    }
  }
  render() {
    const { isDevAccount } = UserAccount;
    const { onClose, app } = this.props;
    const { content } = this.state;
    const { currentPage } = content === 'All' && this.collections || content === 'Owner' && this.collectionOwner || this.collectionInvited;
    if (!currentPage) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const selectedStyle = {
      fontWeight: 500,
      color: 'black',
      borderRadius: '0.5rem'
    };
    return (
      <Popup
        showCloseButton
        allowBackgroundClose
        onClose={onClose}
      >
        <Scrollable
          key={content}
          vertical
          paginationHeight={200}
          onPagination={this.onPagination}
          style={{ width: '100%', maxHeight: 480 }}
        >
          <Wrapper key={currentPage.length}>
            <h1>Add / Remove Collections</h1>
            <Content>
              {isDevAccount && <div style={content === 'All' && selectedStyle || {}} onClick={() => this.setState({ content: 'All' })}>All</div>}
              <div style={content === 'Owner' && selectedStyle || {}} onClick={() => this.setState({ content: 'Owner' })}>Owner</div>
              <div style={content === 'Contributor' && selectedStyle || {}} onClick={() => this.setState({ content: 'Contributor' })}>Contributor</div>
            </Content>
            <div>
              {currentPage.map((collection) => (
                <div key={collection._id}>
                  <div>{collection.title}</div>
                  <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.addRemove(collection); }}>{collection.apps.includes(app._id) ? 'Remove' : 'Add'}</button>
                </div>
              ))}
            </div>
          </Wrapper>
        </Scrollable>
      </Popup>
    );
  }
}
