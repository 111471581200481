import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Navigation from '../../services/Navigation';
import UserCache from '../../services/cache/UserCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import TreeCache from '../../services/cache/TreeCache';
import Tree, { TreeJsonToTreeText } from '../../models/tree';
import Spreadsheet from '../../models/spreadsheet';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../../components/Popup/index';
import ClickableIcon from '../../components/ClickableIcon/index';
import TreeAnswerPage from '../TreeAnswerPage/index';
import Wysiwyg from '../../submodules/logictry_wysiwyg/src/js';
import { convertStructuredTextIntoLogicSyntax } from '../../submodules/logictry_wysiwyg/src/utils';
import ScriptLoader from '../../services/ScriptLoader';
import Dropzone from 'react-dropzone';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 6rem);
  width: 860px;
  height: 640px;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 6rem);
  > h2 {
    text-align: center;
    margin: 2rem 0 1rem;
  }

  input {
    padding: 20px;
    width: 100%;
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    > div {
      position: relative;
      cursor: pointer;
      width: 86px;
      text-align: center;
    }
    > div.underline {
      color: ${Constants.PrimaryColor};
      font-weight: 500;
    }
    > div.underline::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${Constants.PrimaryColor};
    }
  }
  > div:nth-child(3) {
    flex: 1;
    width: 100%;
    position: relative;
    > div {
      position: absolute;
      inset: 0 1rem;
    }
    > div:nth-child(2) {
      cursor: pointer;
      border: 1px dashed rgb(193, 193, 193);
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      background-color: rgba(0,0,0,0.02);
      i {
        font-size: 2rem;
      }
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  > button {
    // width: 100%;
    height: 3rem;
    color: white;
    background: ${Constants.PrimaryColor};
    margin: 1rem;
    border-radius: 0.5rem;
  }
`;

export default class CreateTemplatePopup extends React.PureComponent {
  static propTypes = {
    treeOrSpreadsheet: PropTypes.object,
    team: PropTypes.string,
    onClose: PropTypes.func,
  }
  state = {
    selection: 0,
    name: '',
    html: '',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/pdf.min.js');
    ScriptLoader.onStateUpdate(this.loadPDFHelpers);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    ScriptLoader.offStateUpdate(this.loadPDFHelpers);
    if (this.wysiwyg) {
      this.wysiwyg.unmount();
      this.wysiwyg = null;
    }
  }
  loadPDFHelpers = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/pdf.min.js')) return;
    window.pdfjsLib.disableWorker = true;
    window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://logictrystatic.s3.amazonaws.com/js/pdf.worker.min.js';
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/mammoth.browser.min.js')) {
      ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/mammoth.browser.min.js');
    }
  }
  searchText = () => {
    this.forceUpdate();
  }
  saveAndClose = () => {
    const { treeOrSpreadsheet, onClose } = this.props;
    if (treeOrSpreadsheet instanceof Tree) {
      TreeCache.updateTree(treeOrSpreadsheet);
    } else if (treeOrSpreadsheet instanceof Spreadsheet) {
      SpreadsheetCache.update(treeOrSpreadsheet);
    }
    onClose();
  }
  preview = (e, text) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClose } = this.props;
    onClose();
    Navigation.push(`${Navigation.trees}/${text}`);
  }
  treeSelected = (e, text) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClose, team } = this.props;
    onClose();
    const createTemplate = () => {
      const tree = text ? TreeCache.getTree(text) : TreeCache.createNew('');
      if (!tree || tree.error) return;
      const duplicateTree = TreeCache.duplicateTree(tree);
      if (team) duplicateTree.addTeam(team);
      TreeCache.createTree(duplicateTree);
      TreeCache.offStateUpdate(createTemplate);
    };
    TreeCache.onStateUpdate(createTemplate);
    createTemplate();
  }
  onRef = (mountNode) => {
    if (this.wysiwyg || !mountNode) return;
    this.wysiwyg = new Wysiwyg(mountNode, {
      onBlur: null,
      height: '100%',
      width: '100%',
      focusOnInit: null,
      fullscreen: true,
      disabled: false,
      initialText: '',
      onTextChanged: (html) => this.setState({ html }),
      borderRadius: 8
    });
  }
  create = () => {
    const { onClose, team } = this.props;
    const { name, html } = this.state;
    const initialJSON = {
      children: [
        {
          text: name || '',
          attributes: ['Title'],
          children: [],
        },
        {
          text: '',
          attributes: ['Description'],
          children: [],
        },
        {
          text: '',
          attributes: ['Root'],
          children: [],
        }
      ]
    };
    let newTreeText = '';
    if (html) {
      convertStructuredTextIntoLogicSyntax(html, initialJSON);
      newTreeText = TreeJsonToTreeText(initialJSON);
    }
    const tree = TreeCache.createNew(newTreeText);
    if (team) tree.addTeam(team);
    TreeCache.createTree(tree);
    onClose();
  }
  handleFileUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.type === 'application/pdf') {
        try {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const dataBuffer = event.target.result;
            const pdfData = new Uint8Array(dataBuffer);
            // Load PDF.js using the global PDFJS object
            const pdf = await window.pdfjsLib.getDocument({ data: pdfData }).promise;
            const numPages = pdf.numPages;
            let html = "";
            // Define a function to extract text with header tags
            async function extractTextWithHeaders(pageNumber) {
              if (pageNumber > numPages) {
                return;
              }
              const page = await pdf.getPage(pageNumber);
              const textContent = await page.getTextContent();
              textContent.items.forEach(function (textItem) {
                // const isHeader = textItem.height > 10;
                // if (isHeader) {
                //   if (textItem.str.trim() !== "") {
                //     html += `<h1>${textItem.str}</h1>`;
                //   }
                // } else {
                //   html += `<p>${textItem.str}</p>`;
                // }
                html += `${textItem.str}${textItem.hasEOL && '<br>' || ''}`;
              });
              await extractTextWithHeaders(pageNumber + 1);
            }
            await extractTextWithHeaders(1);
            this.wysiwyg.changeText(html);
            this.setState({ name: file.name, html, selection: 0 });
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          // setText('Error parsing PDF file.');
        }
      } else if (file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const content = event.target.result;
          try {
            const { value } = await window.mammoth.convertToHtml({ arrayBuffer: content });
            this.wysiwyg.changeText(value);
            this.setState({ name: file.name, html: value, selection: 0 });
          } catch (error) {
            // setText('Error converting Word/DOC file.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        // setText('Unsupported file format. Please upload a PDF or Word/DOC document.');
      }
    }
  };
  render() {
    const { onClose } = this.props;
    const { selection } = this.state;
    return (
      <Popup
        onClose={onClose}
        allowBackgroundClose
        showCloseButton
      >
        <Wrapper>
          <h2>Create New App</h2>
          <div>
            <div className={selection === 0 && 'underline' || ''} onClick={() => this.setState({ selection: 0 })}>Paste Text</div>
            <div className={selection === 1 && 'underline' || ''} onClick={() => this.setState({ selection: 1 })}>Upload File</div>
            {/* <div className={selection === 2 && 'underline' || ''} onClick={() => this.setState({ selection: 2 })}>Template</div> */}
          </div>
          <div>
            <div style={{ display: selection === 0 && 'flex' || 'none' }} ref={this.onRef}></div>
            <div style={{ display: selection === 1 && 'flex' || 'none' }}>
              <Dropzone onDrop={this.handleFileUpload} accept=".pdf, .doc, .docx">
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <i className="far fa-file-pdf"></i>
                    <p>Drag and drop a PDF or Word/DOC file here, or click to select one.</p>
                  </div>
                )}
              </Dropzone>
            </div>
            {/* <div>

            </div> */}
          </div>
          <button onClick={this.create}>
            Create App
          </button>
        </Wrapper>
      </Popup>
    );
  }
}
