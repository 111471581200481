/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import MaxWidth from '../../components/MaxWidth';
import Company from '../../services/Company';
import ApiKeyCache from '../../services/cache/ApiKeyCache';
import ApiKeyCallsCache from '../../services/cache/ApiKeyCallsCache';
import CompanyCache from '../../services/cache/CompanyCache';
import UserCache from '../../services/cache/UserCache';
import SubscriptionApi from '../../services/server/SubscriptionApi';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import UserAvatar from '../../components/UserAvatar/index';
import Button from '../../components/Button';
import ClickableIcon from '../../components/ClickableIcon/index';
import StatefulTextField from '../../components/StatefulTextField/index';
import StatefulButton from '../../components/StatefulButton/index';
import SelectMenu from '../../components/SelectMenu/index';
import Scrollable from '../../components/Scrollable/index';
import { ADMIN_ROLE, VIEWER_ROLE } from '../../models/permissions';
import { validateEmail } from '../../utils/regex';


const SettingsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${Constants.MediaTabletAndDesktop} {
    padding: 20px;
  }
`;
const Section = styled.div`
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: ${Constants.BottomBorderDivider};

  > div {
    padding: 20px 10px;
    display: flex;

    &:last-child {
      border-bottom: unset;
    }
  }

  button {
    padding: 0px;
    color: ${Constants.PrimaryColor};
    font-size: ${Constants.SemiSmallFontSize};
  }

  i {
    color: ${Constants.PrimaryColor};
  }

  span {
    justify-content: unset;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: ${Constants.NormalText};
  align-items: center;
`;
const Description = styled.div`

`;
const Row = styled.div`
  font-size: ${Constants.SemiSmallFontSize};
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  align-items: center;

  > *:nth-child(1) {
    width: 320px;
    margin: 0px;
    display: flex;
    > * {
      margin-right: 10px;
    }
  }
  > * {
    margin: 10px;
  }

  > *:nth-child(2) {
    padding-left: 16px;
    flex: 1;
  }

  ${Constants.MediaMobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  > Button {
    color: ${Constants.DarkOrange};
  }
`;
const UserWrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  display: flex;
  > div:nth-child(2) {
    flex: 1;
  }
`;
const UserInput = styled.input`
  margin: 0 1rem;
`;
const ApiKey = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const List = styled.div`
  > * {
    padding: 0.5rem;
    height: 3rem;
  }
`;
const Domain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CreateApiKey = styled.div`

`;
const Calls = styled.table`
  font-size: ${Constants.SmallFontSize};
  tr {
    > td:first-child {
      padding: 0.25rem 0.5rem;
    }
    > td:last-child {
      width: 52px;
      text-align: center;
    }
  }
`;
const selectionRoles = [ADMIN_ROLE, VIEWER_ROLE];

export default class CompanySettingsPage extends React.PureComponent {
  constructor() {
    super();
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    this.state = {
      warning: '',
      success: '',
      subscriptions: [],
      tagGroups: [],
      apiKeyCalls: {
        month,
        year,
      }
    }
  }
  componentDidMount() {
    ApiKeyCache.onStateUpdate(this);
    ApiKeyCallsCache.onStateUpdate(this);
    UserCache.onStateUpdate(this.getCurrentSubscriptions);
    this.initializeSubscriptions();
    this.getCurrentSubscriptions();
  }
  componentWillUnmount() {
    ApiKeyCache.offStateUpdate(this);
    ApiKeyCallsCache.offStateUpdate(this);
    UserCache.offStateUpdate(this.getCurrentSubscriptions);
  }
  initializeSubscriptions = () => {
    const { subscriptions, tagGroups } = Company.company;
    if (subscriptions.error) return;
    this.state.subscriptions = subscriptions.map((s) => ({
      subscription: JSON.parse(JSON.stringify(s)),
      users: [],
    }));
    this.state.tagGroups = JSON.parse(JSON.stringify(tagGroups));
  }
  __updateUsers = () => {
    this.state.subscriptions.forEach((s) => {
      const userIdArray = Array.from(s.subscription.users.map((u) => u._id)).filter((_id) => _id);
      const users = UserCache.getUsersByIds(userIdArray) || [];

      s.users.forEach((user) => {
        if (user._id && userIdArray.includes(user._id)) return;
        if (!user.email) return;

        // If not a valid email, just push current state
        if (validateEmail(user.email)) {
          users.push(user);
          return;
        }

        // If valid, search for the existing user
        const foundUsers = UserCache.getUsers({
          page: 1,
          search: user.email,
        });
        if (foundUsers && foundUsers.length > 0) {
          const foundUser = foundUsers[0];
          if (foundUser.email === user.email.toLowerCase()) users.push(foundUser);
          return;
        }
        users.push(user);
      });

      s.users = JSON.parse(JSON.stringify(users));
    });
  }
  getCurrentSubscriptions = () => {
    this.__updateUsers();
    this.forceUpdate();
  }
  addUser = (users) => {
    users.push({ email: '' });
    this.forceUpdate();
  }
  delete = (user) => {
    if (user._id) {
      this.state.subscriptions[0].subscription.users = this.state.subscriptions[0].subscription.users.filter(({ _id }) => _id !== user._id);
      this.state.subscriptions[0].users = this.state.subscriptions[0].users.filter(({ _id }) => _id !== user._id);
    } else {
      this.state.subscriptions[0].users = this.state.subscriptions[0].users.filter(({ email }) => email !== user.email);
    }
    this.forceUpdate();
  }
  getUserEditable = (user, subscription, i) => {
    return (
      <UserWrapper key={user._id || i}>
        <StatefulTextField
          value={user.email}
          onChange={(newEmail) => { user.email = newEmail; this.forceUpdate(); }}
          onComplete={this.getCurrentSubscriptions}
          hideUnderline
          placeholder="Email"
          width='100%'
        />
        {subscription && <div style={{ margin: '0 0 0 1rem' }}><StatefulTextField
          value={subscription.home && subscription.home._id}
          onChange={(_id) => { subscription.home = { _id }; this.forceUpdate(); }}
          hideUnderline
          placeholder="Home"
          width='200px'
        /></div>}
        <div style={{ margin: '0 0 0 1rem', width: '200px' }}>
          {subscription && ((subscription.role ? (
            <SelectMenu value={subscription.role} onChange={(val) => { if (val === 'Remove') { delete subscription.role; } else { subscription.role = val; } this.forceUpdate(); }} options={[...selectionRoles, 'Remove'].map((_role) => ({ value: _role, name: _role }))} />
          ) : (
            <SelectMenu value="Choose Role" onChange={(val) => { subscription.role = val; this.forceUpdate(); }} options={[...selectionRoles].map((_role) => ({ value: _role, name: _role }))} />
          ))) || subscription && (
            <div style={{ width: 80, textAlign: 'right', marginRight: 8, fontSize: Constants.SemiSmallFontSize }}>{subscription.role}</div>
          )}
        </div>
        <div>
          <ClickableIcon onClick={() => this.delete(user)} icon="fas fa-trash" />
        </div>
      </UserWrapper>
    )
  }
  getUser(user) {
    const usersName = user.fullname && user.username && `${user.fullname} (${user.username})` || user.fullname || user.username;
    return (
      <UserWrapper key={user._id}>
        <UserAvatar user={user} />
        <div style={{ marginLeft: '0.5rem' }}>{usersName}</div>
        {(Company.company.owner === user._id) ? ( // eslint-disable-line
          <div style={{ width: 80, textAlign: 'center', fontSize: Constants.SemiSmallFontSize }}>Owner</div>
        ) : (
          <div style={{ margin: '0px 8px' }}></div>
        )}
      </UserWrapper>
    )
  }
  getSubscriptions() {
    const { isOwnerOrAdmin } = Company.company;
    const { subscriptions } = this.state;
    if (subscriptions.length === 0) return (
      <Section>
        <Title>Subscriptions</Title>
        <Description>Subscription not found.</Description>
        {isOwnerOrAdmin && <CreateApiKey>
          <Button onClick={() => this.createSubscription()}>Create Subscription</Button>
        </CreateApiKey>}
      </Section>
    );

    if (isOwnerOrAdmin) {
      return subscriptions.map(({ subscription, users }) => {
        const subscriptionUsers = users && users.sort((a, b) => {
          const aRole = (subscription.users.find(({ _id }) => a._id === _id) || {}).role;
          const bRole = (subscription.users.find(({ _id }) => b._id === _id) || {}).role;
          const aSubscription = aRole === 'Owner' && 3 || aRole === 'Admin' && 2 || aRole === 'Viewer' && 1 || 0;
          const bSubscription = bRole === 'Owner' && 3 || bRole === 'Admin' && 2 || bRole === 'Viewer' && 1 || 0;
          if (aSubscription > bSubscription) return -1;
          if (bSubscription > aSubscription) return 1;
          return 0;
        }) || [];
        return (
          <Section key={subscription._id}>
            <Title>Subscriptions</Title>
            <Description>This section manages all users added to the company subscription.</Description>
            {subscriptionUsers.map((u, i) => this.getUserEditable(u, subscription.users.find(({ _id }) => u._id === _id), i))}
            {isOwnerOrAdmin && <CreateApiKey><Button onClick={() => this.addUser(users)}>Add User</Button></CreateApiKey>}
          </Section>
        )
      });
    }

    return subscriptions.map(({ subscription, users }) => (
      <Section key={subscription._id}>
        <Title>Subscriptions</Title>
        <Description>This section manages all users added to the company subscription.</Description>
        {users && users.map(this.getUser)}
      </Section>
    ));
  }
  getApiKeys = () => {
    const { isOwnerOrAdmin } = Company.company;
    const apiKeys = ApiKeyCache.query({});
    return (
      <Section>
        <Title>API Keys</Title>
        <Description>This section manages all of the API Keys created to allow website embedding.</Description>
        {apiKeys && apiKeys.map(({ _id, whitelist, state }, i) => (
          <>
            <Row key={_id}>
              <ApiKey>
                <div>ApiKey</div>
                <div>({_id})</div>
                {isOwnerOrAdmin && (
                  <SelectMenu value={state} onChange={(newState) => this.changeApiKeyState(apiKeys[i], newState)} options={['Live', 'Deleted'].map((_role) => ({ value: _role, name: _role }))} />
                ) || (
                  <div>{state}</div>
                )}
              </ApiKey>
              <List>
                {whitelist.map(({ domain }, j) => (
                  <Domain key={`${j}`}>
                    {isOwnerOrAdmin && (
                      <React.Fragment>
                        <StatefulTextField
                          value={domain}
                          onChange={(newDomain) => { whitelist[j].domain = newDomain; this.forceUpdate(); }}
                          hideUnderline
                          placeholder="Domain"
                          width='100%'
                        />
                        <ClickableIcon onClick={() => this.deleteDomain(apiKeys[i], domain)} icon="fas fa-trash" />
                      </React.Fragment>
                    ) || (
                      <div>{domain}</div>
                    )}
                  </Domain>
                ))}
                {isOwnerOrAdmin && <Button onClick={() => this.addDomain(apiKeys[i])}>Add Domain</Button>}
              </List>
            </Row>
            <Row key={`${_id}_calls`}>
              <div></div>
              {this.getApiKeyCalls(_id)}
            </Row>
          </>
        ))}
        {isOwnerOrAdmin && <CreateApiKey>
          <Button onClick={() => this.createApiKey()}>Create API Key</Button>
        </CreateApiKey>}
      </Section>
    )
  }
  getApiKeyCalls = (apiKey) => {
    const { year, month } = this.state.apiKeyCalls;
    const date = new Date(`${year}-${month}-01T00:00:00.000Z`);
    const apiKeyCalls = ApiKeyCallsCache.query({ month: date.toISOString() }, { _id: apiKey });
    let subscriptions;
    if (apiKeyCalls && apiKeyCalls.length > 0) {
      const { calls } = apiKeyCalls[0];
      const total = calls.reduce((prev, curr) => prev + curr.c, 0);
      subscriptions = (
        <tbody>
          {calls.map(({ _id, c }) => (
            <tr key={_id}>
              <td>{_id}</td>
              <td>{c}</td>
            </tr>
          ))}
          <tr style={{ fontWeight: 'bold' }}>
            <td>
              Total
            </td>
            <td>
              {total}
            </td>
          </tr>
        </tbody>
      );
    }
    return <Calls>
      <div style={{ padding: '0.25rem 0.5rem', marginBottom: '1rem' }}>
        <input type="month" value={`${year}-${month}`} onChange={(e) => { const [year, month] = e.target.value.split('-'); this.setState({ apiKeyCalls: { year, month } }); }}></input>
      </div>
      {subscriptions}
    </Calls>
  }
  deleteOption = (tagGroups, i, j) => {
    tagGroups[i].options.splice(j, 1);
    this.forceUpdate();
  }
  addOption = (tagGroups, i) => {
    tagGroups[i].options.push('');
    this.forceUpdate();
  }
  deleteTagGroup = (tagGroups, i) => {
    tagGroups.splice(i, 1);
    this.forceUpdate();
  }
  createTagGroup = () => {
    this.state.tagGroups.push({
      name: '',
      options: ['']
    })
    this.forceUpdate();
  }
  getTags = () => {
    const { isOwnerOrAdmin } = Company.company;
    const { tagGroups } = this.state;
    return (
      <Section>
        <Title>Tag Groups</Title>
        <Description>This section manages all content tag groups.</Description>
        {tagGroups && tagGroups.map(({ name, options }, i) => (
          <Row key={i}>
            <ApiKey>
              {isOwnerOrAdmin && (
                <Domain>
                  <StatefulTextField
                    value={name}
                    onChange={(newName) => { tagGroups[i].name = newName; this.forceUpdate(); }}
                    hideUnderline
                    placeholder="Name"
                    width={200}
                  />
                  <ClickableIcon onClick={() => this.deleteTagGroup(tagGroups, i)} icon="fas fa-trash" />
                </Domain>
              ) || (
                <div>{name}</div>
              )}
            </ApiKey>
            <List>
              {options.map((option, j) => (
                <Domain key={`${j}`}>
                  {isOwnerOrAdmin && (
                    <React.Fragment>
                      <StatefulTextField
                        value={option}
                        onChange={(newOption) => { options[j] = newOption; this.forceUpdate(); }}
                        hideUnderline
                        placeholder="Option"
                        width='100%'
                      />
                      <ClickableIcon onClick={() => this.deleteOption(tagGroups, i, j)} icon="fas fa-trash" />
                    </React.Fragment>
                  ) || (
                    <div>{option}</div>
                  )}
                </Domain>
              ))}
              {isOwnerOrAdmin && <Button onClick={() => this.addOption(tagGroups, i)}>Add Option</Button>}
            </List>
          </Row>
        ))}
        {isOwnerOrAdmin && <CreateApiKey>
          <Button onClick={() => this.createTagGroup()}>Create Tag Group</Button>
        </CreateApiKey>}
      </Section>
    )
  }
  deleteDomain = (_apiKey, _domain) => {
    _apiKey.whitelist = _apiKey.whitelist.filter(({ domain }) => domain !== _domain);
    this.forceUpdate();
  }
  addDomain = (_apiKey) => {
    _apiKey.whitelist.push({});
    this.forceUpdate();
  }
  reset = () => {
    const apiKeys = ApiKeyCache.query({});
    apiKeys.forEach((_apiKey) => {
      ApiKeyCache.revertObject(_apiKey);
    });
    this.initializeSubscriptions();
    this.getCurrentSubscriptions();
  }
  save = async () => {
    const { company } = Company;
    this.__updateUsers();

    const apiKeys = ApiKeyCache.query({});
    apiKeys.forEach((_apiKey) => {
      _apiKey.whitelist = _apiKey.whitelist.filter(({ domain }) => domain);
      ApiKeyCache.update(_apiKey);
    });

    const tagGroups = [];
    this.state.tagGroups.forEach(({ name, options }) => {
      tagGroups.push({ name: name.toLowerCase(), options: options.map((option) => option.toLowerCase()) });
    });
    company.tagGroups = tagGroups;
    CompanyCache.update(company);

    if (this.state.subscriptions[0]) {
      const usersToAdd = this.state.subscriptions[0].users.filter((user) => !this.state.subscriptions[0].subscription.users.find((u) => u._id === user._id));
      const startTime = new Date();
      const update = {
        _id: this.state.subscriptions[0].subscription._id,
        users: [...this.state.subscriptions[0].subscription.users, ...usersToAdd.map(({ _id }) => ({ _id, startTime }))],
      };
      const usersToGetByEmail = [...this.state.subscriptions[0].users.filter(({ _id, email }) => (!_id && email))];
      if (usersToGetByEmail.length > 0) {
        this.initializeSubscriptions();
        this.getCurrentSubscriptions();
        return;
      }
      await SubscriptionApi.update(update);
      window.location.reload();
    }

    this.setState({ warning: '', success: 'Success!' });
  }
  createApiKey = () => {
    ApiKeyCache.create(ApiKeyCache.createNew());
  }
  createSubscription = async () => {
    await SubscriptionApi.create({
      company: Company.company._id,
      startTime: __getYearMonthDayDate(),
      endTime: __getYearMonthDayDate(1),
      users: [],
    });
    window.location.reload();
  }
  changeApiKeyState = (apiKey, _state) => {
    apiKey.state = _state;
    this.forceUpdate();
  }
  render() {
    const { warning, success } = this.state;
    const { isOwnerOrAdmin } = Company.company;
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <MaxWidth>
          <DrawerBreadcrumb></DrawerBreadcrumb>
          <SettingsWrapper>
            {this.getSubscriptions()}
            {this.getApiKeys()}
            {this.getTags()}
            {isOwnerOrAdmin && <div style={{ maxWidth: 400, width: '100%', marginTop: 50, display: 'flex', justifyContent: 'space-around' }}>
              <Button onClick={this.reset}>Reset</Button>
              <StatefulButton
                text="Save"
                onClick={this.save}
                warning={warning}
                success={success}
              />
            </div>}
          </SettingsWrapper>
        </MaxWidth>
      </Scrollable>
    );
  }
}
function __getYearMonthDayDate(yearOffset = 0, monthOffset = 0) {
  const date = new Date();
  let month = date.getMonth() + 1 + monthOffset;
  let year = date.getFullYear() + yearOffset;
  if (month > 12) {
    month -= 12;
    year += 1;
  }
  let day = date.getDate();
  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  return new Date(`${year}-${month}-${day}T00:00:00.000Z`);
}
