import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import SelectMenu from '../../components/SelectMenu/index';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';
import Storage from '../../services/Storage';
import AssetsBox from './AssetsBox';
import SpreadsheetsBox from './SpreadsheetsBox';
import TemplateTreesBox from './TemplateTreesBox';
import ProjectTreesBox from './ProjectTreesBox';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import ListViewGridViewSelect from './ListViewGridViewSelect';
import HeaderSection from './HeaderSection';
import ContentBoxesAndLists from '../../components/ContentBoxesAndLists';
import TreeAnswerPage from '../TreeAnswerPage';
import DatabasesBox from './DatabasesBox';
import LinksBox from './LinksBox';
import Scrollable from '../../components/Scrollable/index';

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    text-align: center;
  }
  h1, h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;
const SearchBox = styled.div`
  // border: 1px solid #afafaf;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
    cursor: pointer;
  }
  input {
    width: 90px;
    transition: 0.3s all;
  }
  ${Constants.MediaTabletAndDesktop} {
    input:focus {
      width: 200px;
    }
  }
`;
const Filters = styled.div`
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-right: 4rem;
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    > div:first-child {
      font-weight: 600;
    }
    > div {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
        > label {
          margin-left: 0.5rem;
        }
      }
    }
  }

`;
// const Videos = styled.div`
//   margin-top: 2rem;
//   display: flex;
//   > div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   iframe {
//     width: 14rem;
//     height: 8rem;
//     margin: 1rem;
//   }
// `;
const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 4rem);
  justify-content: center;
  margin: 0 2rem;
  ${Constants.MediaMobile} {
    width: 100%;
    margin: 0;
  }
`
const FilterIcon = styled.i`
  cursor: pointer;
`;

const treePageSelectOptions = [{ name: 'Apps', value: 'apps' }, { name: 'Assets', value: 'assets' }, { name: 'Databases', value: 'databases' }, { name: 'Spreadsheets', value: 'spreadsheets' }, { name: 'Submissions', value: 'submissions' }];

let showFilters;
let filterState = {};

export default class TreePage extends React.PureComponent {
  onFilterChange = (name, option) => {
    let filterText = filterState || {};
    const filter = `taggroups.${name}`;
    if (!filterText[filter]) filterText[filter] = option;
    else {
      const optionArray = filterText[filter].split(',');
      const optionIndex = optionArray.indexOf(option);
      if (optionIndex < 0) optionArray.push(option);
      else optionArray.splice(optionIndex, 1);
      if (optionArray.length === 0) delete filterText[filter];
      else filterText[filter] = optionArray.join(',');
    }
    filterState = {};
    Object.keys(filterText).sort().forEach((key) => {
      filterState[key] = filterText[key];
    });
    this.forceUpdate();
  }
  getFilters = (company) => {
    const { tagGroups } = company;
    return <Filters>
      {tagGroups.map(({ name, options }, i) => (
        <div key={name}>
          <div>{capitalizeFirstLetter(name)}</div>
          <div>
            {options.map((option) => (
              <div key={option}>
                <input checked={(filterState[`taggroups.${name}`] || '').split(',').includes(option)} onChange={() => this.onFilterChange(name, option)} type="checkbox" id={option}></input>
                <label htmlFor={option}>{capitalizeFirstLetter(option)}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Filters>;
  }
  getBoxes = (searchText, invited) => {
    const filterText = (filterState && Object.keys(filterState).length > 0) ? JSON.parse(JSON.stringify(filterState)) : null;
    const treePageSelectType = Navigation.currentLocation[1] || 'apps';
    const showDeleted = Navigation.currentSearch.state === 'Deleted';
    if (treePageSelectType === 'apps') return <TemplateTreesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'assets') return <AssetsBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'databases') return <DatabasesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'links') return <LinksBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'spreadsheets') return <SpreadsheetsBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'submissions') return <ProjectTreesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    return null;
  }
  updateState = (_selectType) => {
    Storage.setContentView(_selectType);
    const queryParams = [];
    if (Navigation.currentSearch.invited) queryParams.push('invited=true');
    if (Navigation.currentSearch.state === 'Deleted') queryParams.push('state=Deleted');
    Navigation.push(`/${_selectType}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`);
    this.forceUpdate();
  }
  keyDown = (e) => {
    if (e.key === 'Enter') this.search();
  }
  search = (e) => {
    if (!this.searchRef) return;
    Navigation.addOrReplaceQueryParam('search', this.searchRef.value || '');
  }
  render() {
    const { isDevAccount } = UserAccount;
    const { company, isAuthorizedToLogIn } = Company;
    if (!UserAccount.isLoggedIn) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const treePageSelectType = Navigation.currentLocation[1] || 'apps';
    const pageTitle = treePageSelectType ? (Navigation.currentSearch.invited ? `Shared ${treePageSelectType}` : `Your ${treePageSelectType}`) : 'Home';
    if (treePageSelectType === 'Home') {
      const { home } = UserAccount.account;
      if (home && home._id) {
        return (
          <TreeAnswerPage />
        );
      }
      return (
        <Scrollable
          vertical
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <FullScreenVerticalCenterContent>
            <TextContent>
              <DrawerBreadcrumb key={pageTitle}></DrawerBreadcrumb>
            </TextContent>
          </FullScreenVerticalCenterContent>
        </Scrollable>
      )
    }
    const searchText = Navigation.currentSearch['search'];
    const invited = !!Navigation.currentSearch.invited;
    const key = `${searchText || ''}${JSON.stringify(filterState) || ''}`;
    const showDeleted = Navigation.currentSearch.state === 'Deleted';
    const treePageOptionsToDisplay = treePageSelectOptions.map(({ name, value }) => ({ name: showDeleted ? `Deleted ${name}` : name, value }))
      .filter(({ value }) => isAuthorizedToLogIn || value === 'submissions');
    return (
      <Scrollable
        vertical
        showVertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        rememberScrollPosition={'tree_page'}
      >
        <FullScreenVerticalCenterContent alignTop>
          <DrawerBreadcrumb key={pageTitle}></DrawerBreadcrumb>
          <Wrapper>
            {isDevAccount && company && showFilters && this.getFilters(company)}
            <ContentBoxesAndLists>
              <HeaderSection>
                <SelectMenu value={treePageSelectType} onChange={this.updateState} options={treePageOptionsToDisplay} />
                {isDevAccount && company && <FilterIcon onClick={() => { showFilters = !showFilters; this.forceUpdate(); }} className="fas fa-filter"></FilterIcon>}
                {!showDeleted && <SearchBox>
                  <i onClick={this.search} className="fas fa-search"></i>
                  <input ref={(e) => {this.searchRef = e;}} defaultValue={searchText} placeholder="Search" onKeyDown={this.keyDown} onBlur={this.search} />
                </SearchBox>}
                <ListViewGridViewSelect />
              </HeaderSection>
              <div key={key}>
                {this.getBoxes(searchText, invited)}
              </div>
            </ContentBoxesAndLists>
          </Wrapper>
        </FullScreenVerticalCenterContent>
      </Scrollable>
    );
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
