/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SidebarService from '../../services/SidebarService';
import WysiwygWidget from '../../submodules/logictry_wysiwyg/src/js';
import ContextMenu from '../ContextMenu';
import Cell from '../../models/cell';
import purifyhtml from '../../submodules/logictry_purifyhtml';


const letters = [];
let i3, i4;
for (i3 = 0; i3 < 26; i3++) {
  letters.push(String.fromCharCode(97 + i3));
}
for (i3 = 0; i3 < 26; i3++) {
  for (i4 = 0; i4 < 26; i4++) {
    letters.push(String.fromCharCode(97 + i3) + String.fromCharCode(97 + i4));
  }
}

const Wrapper = styled.div`
  
`;
const Table = styled.table`
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  border-collapse: collapse;
  td {
    padding: 4px;
    position: relative;
  }
  a {
    text-decoration: underline;
  }
`;
const ColNumTable = styled.table`
  border-collapse: collapse;
  height: 2rem;
`;
const RowNumTable = styled.table`
  border-collapse: collapse;
  position: absolute;
  top: 2rem;
  left: 0rem;
  bottom: 0rem;
  width: 2rem;
`;

const wysiwygCell = new Cell();

export default class Spreadsheet extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    onCellsChanged: PropTypes.func,
    spreadsheetKey: PropTypes.string,
    onFinishEditing: PropTypes.func,
    onCreateRow: PropTypes.func,
    onCreateColumn: PropTypes.func,
    onDeleteRow: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    allowEditing: PropTypes.bool,
    allowClickingLinks: PropTypes.bool,
    showRowsAndCols: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    const maxWidthOrHeight = Math.max(window.innerHeight, window.innerWidth);
    this.noOfRows = Math.ceil(maxWidthOrHeight / 35);
    this.noOfCols = Math.ceil(maxWidthOrHeight / 209);
    this.maxRows = 9999;
    this.rows = [];
    const { showRowsAndCols, allowClickingLinks } = props;
    for (let i = 0; i < this.noOfRows; i += 1) {
      const row = document.createElement('tr');
      this.rows.push(row);
      for (let j = 0; j < this.noOfCols; j += 1) {
        const cell = document.createElement('td');
        row.appendChild(cell);
        cell.style.border = '1px solid #ddd';
        cell.oncontextmenu = (e) => this.contextMenu(e, parseInt(cell.dataset.row, 10), parseInt(cell.dataset.col, 10));
        cell.onCopy = this.onCopy;
        cell.onPaste = this.onPaste;
        cell.onmousedown = (e) => this.cellClicked(e, cell);
        cell.onmousemove = (e) => this.onmousemoved(e, cell);
        const div = document.createElement('div');
        if (!allowClickingLinks) div.style.pointerEvents = 'none';
        div.style.overflow = 'hidden';
        div.style.minHeight = '21px';
        div.style.maxHeight = '200px';
        div.style.width = '200px';
        div.style.wordBreak = 'break-word';
        cell.appendChild(div);

        if (showRowsAndCols) {
          this.rowNumNodes = [];
          for (let i = 0; i < this.noOfRows; i += 1) {
            const row = document.createElement('tr');
            const cell = document.createElement('td');
            const div = document.createElement('div');
            div.style.fontWeight = '200';
            div.style.pointerEvents = 'none';
            div.style.overflow = 'hidden';
            div.style.minHeight = '21px';
            div.style.maxHeight = '200px';
            div.style.textAlign = 'center';
            div.textContent = i + 1;
            cell.appendChild(div);
            row.appendChild(cell);
            this.rowNumNodes.push(row);
          }
          this.colNumNodes = [];
          const row = document.createElement('tr');
          for (let j = 0; j < this.noOfCols; j += 1) {
            const cell = document.createElement('td');
            const div = document.createElement('div');
            if (j === 0) {
              div.style.fontWeight = '200';
              div.style.pointerEvents = 'none';
              div.style.overflow = 'hidden';
              div.style.width = '2rem';
              div.style.textAlign = 'center';
            } else {
              div.style.fontWeight = '200';
              div.style.pointerEvents = 'none';
              div.style.overflow = 'hidden';
              div.style.width = '207px';
              div.style.textAlign = 'center';
              div.textContent = letters[j - 1].toUpperCase();
            }
            cell.appendChild(div);
            row.appendChild(cell);
            this.colNumNodes.push(row);
          }
        }
      }
    }
    this.nodeHeight = {};
    this.nodeWidth = {};
  }
  state = {
    selectedCells: null,
    editingCell: null,
    lastScrollTime: Date.now(),
    lastTouchX: 0,
    lastTouchY: 0,
    scrollX: 0,
    scrollY: 0,
    startCol: 0,
    startRow: 0,
  }
  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
    wysiwygCell.onStateUpdate(this.cellUpdated);
  }
  componentDidUpdate() {
    this.updateAllCells();
  }
  componentWillUnmount() {
    wysiwygCell.offStateUpdate(this.cellUpdated);
    this.removeWysiwygWidget();
    document.removeEventListener('keydown', this.keyDown);
  }
  onCellsChanged(changes) {
    const { onCellsChanged } = this.props;
    onCellsChanged(changes);
  }
  onCopy = (e) => {
    const { selectedCells } = this.state;
    if (!selectedCells || !selectedCells[0] || !(selectedCells[0][0] >= 0 && selectedCells[0][1] >= 0)) return;
    e.preventDefault();
    e.stopPropagation();
    let textToCopy;
    if (selectedCells[0][0] === selectedCells[1][0] && selectedCells[0][1] === selectedCells[1][1]) {
      if (!this.props.data[selectedCells[0][0]] || !this.props.data[selectedCells[0][0]][selectedCells[0][1]]) textToCopy = '';
      else textToCopy = this.props.data[selectedCells[0][0]][selectedCells[0][1]].value;
    } else {
      textToCopy = this.props.data
        .slice(selectedCells[0][0], selectedCells[1][0] + 1)
        .map((_row) => _row.slice(selectedCells[0][1], selectedCells[1][1] + 1)
          .map((_cell) => (_cell && _cell.value) ? _cell.value.replace(/\t/g, '').replace(/\r/g, '').replace(/\n/g, '') : '').join('\t')).join('\r\n');
    }
    e.clipboardData.setData('text/plain', textToCopy);
  }
  onPaste = (e) => {
    const { selectedCells } = this.state;
    const { allowEditing } = this.props;
    if (!allowEditing) return;
    if (!selectedCells || !selectedCells[0] || !(selectedCells[0][0] >= 0 && selectedCells[0][1] >= 0)) return;
    e.preventDefault();
    e.stopPropagation();
    const changes = [];

    // Get plain text and html
    const text = e.clipboardData.getData('text/plain') || '';
    const values = parseData(text, '\t');
    const purifiedValues = values.map((row) => row.map((cell) => purifyhtml(cell)));

    // Format spreadsheet
    let maxCol = 0;
    purifiedValues.forEach((row) => {
      maxCol = Math.max(maxCol, row ? row.length : 0);
    });
    purifiedValues.forEach((row, i) => {
      ([...row, ...(new Array(maxCol - row.length))]).forEach((_cell, j) => {
        changes.push({ row: selectedCells[0][0] + i, col: selectedCells[0][1] + j, value: _cell || '' });
      });
    });
    this.onCellsChanged(changes);
  }
  onWheel = (e) => {
    if (e && e.touches && e.touches.length > 1 || e.ctrlKey) return;
    if (this.wysiwygMountnode) {
      const divRect = this.wysiwygMountnode.getBoundingClientRect();
      if (e.clientX >= divRect.left && e.clientX <= divRect.right &&
        e.clientY >= divRect.top && e.clientY <= divRect.bottom) {
        return;
      }
    }
    const { lastScrollTime, lastTouchX, lastTouchY } = this.state;
    const timestamp = Date.now();
    let deltaX = e.deltaX;
    let deltaY = e.deltaY;

    if (!deltaX && !deltaY && e.touches) {
      if ((timestamp - lastScrollTime) < 50) {
        deltaX = -(e.touches[0].clientX - lastTouchX) * 4;
        deltaY = -(e.touches[0].clientY - lastTouchY) * 3;
      } else {
        deltaX = 0;
        deltaY = 0;
      }
      this.state.lastTouchX = e.touches[0].clientX;
      this.state.lastTouchY = e.touches[0].clientY;
      this.state.lastScrollTime = timestamp;
    }


    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      this.state.scrollX += deltaX;
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.state.scrollY += deltaY;
    }

    if (this.state.scrollX > 160 && this.state.startCol < this.maxRows) {
      this.scrollRight();
    } else if (this.state.scrollX < -160 && this.state.startCol > 0) {
      this.scrollLeft();
    } else if (this.state.startCol <= 0 && this.state.scrollX < 0) {
      this.state.scrollX = 0;
    } else if (this.state.startCol >= this.maxRows && this.state.scrollX > 0) {
      this.state.scrollX = 0;
    }

    if (this.state.scrollY > 50 && this.state.startRow < this.maxRows) {
      this.scrollDown();
    } else if (this.state.scrollY < -50 && this.state.startRow > 0) {
      this.scrollUp();
    } else if (this.state.startRow <= 0 && this.state.scrollY < 0) {
      this.state.scrollY = 0;
    } else if (this.state.startRow >= this.maxRows && this.state.scrollY > 0) {
      this.state.scrollY = 0;
    }
  }
  onBodyRef = (e) => {
    if (!e) return;
    this.bodyRef = e;
    this.rows.forEach((_row) => e.appendChild(_row));
    this.updateAllCells();
  }
  onColNumRef = (e) => {
    if (!e) return;
    this.colNumNodes.forEach((_row) => e.appendChild(_row));
  }
  onRowNumRef = (e) => {
    if (!e) return;
    this.rowNumNodes.forEach((_row) => e.appendChild(_row));
  }
  onClose = () => this.setState({ showContextMenu: false });
  setSelectedCells(selectedCells) {
    this.clearSelectedCells();
    const { startRow, startCol } = this.state;
    const { childNodes } = this.bodyRef;
    this.state.selectedCells = selectedCells;
    this.state.editingCell = null;
    this.state.showWysiwyg = false;
    for (let row = selectedCells[0][0]; row <= selectedCells[1][0]; row += 1) {
      for (let col = selectedCells[0][1]; col <= selectedCells[1][1]; col += 1) {
        if (row === 0) {
          const nextNode = childNodes[0].childNodes[col - startCol];
          if (nextNode) this.createOverlay(nextNode);
        } else if (row !== startRow) {
          if (this.between(row, startRow, startRow + (this.noOfRows - 1)) && this.between(col, startCol, startCol + (this.noOfCols - 1))) {
            const nextNode = childNodes[row - startRow].childNodes[col - startCol];
            if (nextNode) {
              this.createOverlay(nextNode);
              if (document.activeElement) document.activeElement.blur();
              nextNode.focus();
            }
          }
        }
      }
    }
  }
  setEditingCell(editingCell, wysiwyg, clearValue) {
    const { showWysiwyg } = SidebarService;
    const { onFinishEditing, data, allowEditing } = this.props;
    const { startRow, startCol } = this.state;
    const { childNodes } = this.bodyRef;
    if (!allowEditing) return;
    this.clearSelectedCells();
    const rowIndex = Math.max(editingCell[0] - startRow, 0);
    const node = childNodes[rowIndex] && childNodes[rowIndex].childNodes[editingCell[1] - startCol];
    if (!node) return;

    const currentText = clearValue ? '' : (data[editingCell[0]] && data[editingCell[0]][editingCell[1]] && data[editingCell[0]][editingCell[1]].value) || '';
    wysiwygCell.updateText(currentText);

    // FORCE WYSIWYG
    // eslint-disable-next-line no-param-reassign
    if (showWysiwyg) {
      this.wysiwygMountnode = document.createElement('div');
      this.wysiwygMountnode.style.position = 'absolute';
      this.wysiwygMountnode.style.top = '0px';
      this.wysiwygMountnode.style.left = '0px';
      this.wysiwygMountnode.style.zIndex = '1';
      node.appendChild(this.wysiwygMountnode);
      this.wysiwygWidget = new WysiwygWidget(this.wysiwygMountnode, {
        disabled: !allowEditing,
        focusOnInit: true,
        onBlur: onFinishEditing,
        initialText: wysiwygCell.text,
        onTextChanged: wysiwygCell.updateText.bind(wysiwygCell),
        height: (window.innerWidth < 400 ? window.innerHeight : '400px'),
        width: (window.innerWidth < 480 ? window.innerWidth : '610px')
      });
      wysiwygCell.onStateUpdate(this.wysiwygCellUpdate);
    } else {
      this.rawEditorNode = this.createEditing();
      this.rawEditorNode.innerText = wysiwygCell.text;
      node.appendChild(this.rawEditorNode);
      if (document.activeElement) document.activeElement.blur();
      this.rawEditorNode.focus();
    }

    // node.firstChild.style.display = 'none';
    this.state.editingCell = editingCell;
    this.state.showWysiwyg = wysiwyg;
  }
  updateAllCells = () => {
    this.nodeHeight = {};
    this.nodeWidth = {};
    const { startCol, startRow } = this.state;
    this.bodyRef.childNodes.forEach((_row, i) => {
      _row.childNodes.forEach((_cell, j) => {
        let row = 0;
        let col = startCol + j;
        if (i !== 0) {
          row = startRow + i;
        }
        this.adjustCellSize(this.props.data, _cell, row, col);
      });
    });
    this.updateRowColNumbers();
  }
  updateRowColNumbers = () => {
    const { showRowsAndCols } = this.props;
    const { startCol, startRow } = this.state;
    if (showRowsAndCols) {
      this.bodyRef.childNodes.forEach((_row, i) => {
        if (i === 0) {
          this.colNumNodes[0].childNodes.forEach((col, j) => {
            if (j > 0) col.firstChild.textContent = letters[j - 1 + startCol].toUpperCase();
          });
        }
        const row = this.rowNumNodes[i];
        const cell = row.firstChild.firstChild;
        row.style.height = `${_row.getBoundingClientRect().height}px`;
        if (i > 0) cell.textContent = i + startRow + 1;
      });
    }
  }
  wysiwygCellUpdate = () => {
    this.wysiwygWidget.changeText(wysiwygCell.text);
  }
  removeWysiwygWidget = () => {
    if (this.wysiwygWidget) {
      wysiwygCell.offStateUpdate(this.wysiwygCellUpdate);
      this.wysiwygWidget.unmount();
      this.wysiwygWidget = null;
      this.wysiwygMountnode = null;
      this.cellUpdated();
    }
    if (this.rawEditorNode) {
      this.rawEditorNode.parentNode.removeChild(this.rawEditorNode);
      this.rawEditorNode = null;
      this.cellUpdated();
    }
  }
  scrollLeft = (_startCol) => {
    this.clearEditingCell();
    const childNodes = this.bodyRef.childNodes;
    if (_startCol) this.state.startCol = _startCol;
    else this.state.startCol += -1;
    this.state.scrollX = 0;
    childNodes.forEach((_row) => {
      const rowChildNodes = _row.childNodes;
      const updateNode = rowChildNodes[this.noOfCols - 1];
      _row.insertBefore(updateNode, rowChildNodes[0]);
    });
    this.updateAllCells();
  }
  scrollRight = () => {
    this.clearEditingCell();
    const childNodes = this.bodyRef.childNodes;
    this.state.startCol += 1;
    this.state.scrollX = 0;
    childNodes.forEach((_row) => {
      const rowChildNodes = _row.childNodes;
      const updateNode = rowChildNodes[0];
      _row.appendChild(updateNode);
    });
    this.updateAllCells();
  }
  scrollDown = () => {
    this.clearEditingCell();
    const childNodes = this.bodyRef.childNodes;
    this.state.startRow += 1;
    this.state.scrollY = 0;
    const updateNode = childNodes[1];
    this.bodyRef.appendChild(updateNode);
    this.updateAllCells();
  }
  scrollUp = () => {
    this.clearEditingCell();
    const childNodes = this.bodyRef.childNodes;
    this.state.startRow += -1;
    this.state.scrollY = 0;
    const updateNode = childNodes[this.noOfRows - 1];
    this.bodyRef.insertBefore(updateNode, childNodes[1]);
    this.updateAllCells();
  }
  clearSelectedCells() {
    const { childNodes } = this.bodyRef;
    this.clearEditingCell();
    childNodes.forEach((r) => r.childNodes.forEach((c) => c.childNodes.forEach((n) => {
      if (n.className === 'spreadsheet-selection-overlay') c.removeChild(n);
    })));
    this.state.selectedCells = null;
  }
  clearEditingCell() {
    const { startRow, startCol, editingCell } = this.state;
    const { childNodes } = this.bodyRef;
    if (!editingCell) return;
    const row = childNodes[Math.max(editingCell[0] - startRow, 0)];
    if (!row || !row.childNodes) return;
    const node = row.childNodes[editingCell[1] - startCol];
    if (!node || !node.firstChild) return;
    // node.firstChild.style.display = null;
    if (node.childNodes.length > 1) {
      this.removeWysiwygWidget();
    }
    this.state.editingCell = null;
    const newSelectedCells = [editingCell, editingCell];
    this.setSelectedCells(newSelectedCells);
  }
  onmousemoved = (e, cell) => {
    if (e.buttons !== 1) return;
    const { selectedCells } = this.state;
    if (!selectedCells || !selectedCells[0] || !(selectedCells[0][0] >= 0 && selectedCells[0][1] >= 0)) return;
    e.preventDefault();
    e.stopPropagation();

    // Detect the row and column and initialize settings
    const _row = parseInt(cell.dataset.row, 10);
    const _col = parseInt(cell.dataset.col, 10);

    let startRow = selectedCells[0][0];
    let startCol = selectedCells[0][1];
    let endRow = selectedCells[1][0] >= 0 ? selectedCells[1][0] : startRow;
    let endCol = selectedCells[1][1] >= 0 ? selectedCells[1][1] : startCol;

    if (_row < startRow) {
      startRow = _row;
    } else {
      endRow = _row;
    }
    if (_col < startCol) {
      startCol = _col;
    } else {
      endCol = _col;
    }

    const newSelectedCells = [[startRow, startCol], [endRow, endCol]];

    const sameSelection = JSON.stringify(selectedCells) === JSON.stringify(newSelectedCells);
    if (sameSelection) return;
    this.setSelectedCells(newSelectedCells);
  }
  cellClicked = (e, cell) => {
    // If buttons exists, check that it registers the left button
    if (e.button && e.button !== 0 || e.buttons && e.buttons !== 1) return;

    const { selectedCells, editingCell } = this.state;
    if (selectedCells || editingCell) {
      if (selectedCells) e.preventDefault();
      e.stopPropagation();
    }

    // Detect the row and column and initialize settings
    const _row = parseInt(cell.dataset.row, 10);
    const _col = parseInt(cell.dataset.col, 10);
    const { showContextMenu } = this.state;
    if (showContextMenu) this.setState({ showContextMenu: false });
    if (editingCell && editingCell[0] === _row && editingCell[1] === _col) return;

    // Calculate the new selected cells
    let newSelectedCells;
    if (selectedCells && e.shiftKey) {
      newSelectedCells = [[Math.min(selectedCells[0][0], _row), Math.min(selectedCells[0][1], _col)], [Math.max(selectedCells[1][0], _row), Math.max(selectedCells[1][1], _col)]];
    } else {
      newSelectedCells = [[_row, _col], [_row, _col]];
    }
    const sameSelection = JSON.stringify(selectedCells) === JSON.stringify(newSelectedCells);
    if (this.doubleClickTimeout && sameSelection) {
      this.setEditingCell([_row, _col], true);
      return;
    }
    this.doubleClickTimeout = null;

    // Dont open if editing not allowed
    if (sameSelection) {
      if (editingCell) return;
      this.doubleClickTimeout = setTimeout(() => { this.doubleClickTimeout = null; }, 500);
      this.setEditingCell([_row, _col], false);
    } else {
      this.doubleClickTimeout = setTimeout(() => { this.doubleClickTimeout = null; }, 500);
      this.setSelectedCells(newSelectedCells);
    }
  }
  createOverlay(node) {
    const { allowClickingLinks } = this.props;
    const selectedOverlay = document.createElement('div');
    selectedOverlay.className = 'spreadsheet-selection-overlay';
    if (allowClickingLinks) selectedOverlay.style.pointerEvents = 'none';
    selectedOverlay.style.position = 'absolute';
    selectedOverlay.style.top = '0px';
    selectedOverlay.style.left = '0px';
    selectedOverlay.style.right = '0px';
    selectedOverlay.style.bottom = '0px';
    selectedOverlay.style.border = '1px double #2185d0';
    selectedOverlay.style.backgroundColor = 'rgba(33,133,208,.15)';
    node.appendChild(selectedOverlay);
  }
  createEditing() {
    const selectedOverlay = document.createElement('textarea');
    selectedOverlay.style.position = 'absolute';
    selectedOverlay.style.backgroundColor = 'inherit';
    selectedOverlay.style.left = '0px';
    selectedOverlay.style.top = '0px';
    selectedOverlay.style.width = 'calc(100% - 8px)';
    selectedOverlay.style.height = 'calc(100% - 8px)';
    selectedOverlay.style.margin = '4px';
    selectedOverlay.style.padding = '0px';
    selectedOverlay.style.resize = 'none';
    selectedOverlay.onwheel = (e) => { e.stopPropagation(); }
    selectedOverlay.ontouchmove = (e) => { e.stopPropagation(); }
    selectedOverlay.oninput = (e) => { wysiwygCell.updateText(e.target.value); }
    return selectedOverlay;
  }
  cellUpdated = () => {
    const { editingCell } = this.state;
    if (!editingCell) return;
    const { text } = wysiwygCell;

    // eslint-disable-next-line no-param-reassign
    const value = text.replace(/\t/g, '').replace(/\r/g, '').replace(/\n/g, '');
    const row = editingCell[0];
    const col = editingCell[1];
    this.onCellsChanged([{ row, col, value }]);
  }
  keyDown = (e) => {
    if (!(document.activeElement === document.body || this.bodyRef.contains(document.activeElement))) return;
    const { data, allowEditing } = this.props;
    const { selectedCells, editingCell } = this.state;
    if (editingCell) {
      if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        this.clearEditingCell();
        if (!allowEditing) return;
        if (this.editingCellRef) {
          const newSelectedCells = [editingCell, editingCell];
          this.setSelectedCells(newSelectedCells);
        }
      } else if (!(e.metaKey || e.ctrlKey) && e.key.length === 1 && e.key.match(/[a-z0-9]/i) && this.wysiwygWidget) {
        e.preventDefault();
        e.stopPropagation();
        wysiwygCell.updateText(`${wysiwygCell.text}${e.key}`);
      }
    } else if (selectedCells) {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
      } else if ((e.key === 'Backspace' || e.key === 'Delete') && selectedCells) {
        e.preventDefault();
        e.stopPropagation();
        if (!allowEditing) return;
        const minX = selectedCells[0][0];
        const minY = selectedCells[0][1];
        const maxX = selectedCells[1][0];
        const maxY = selectedCells[1][1];
        const changes = [];
        for (let i = minX; i <= maxX; i += 1) {
          for (let j = minY; j <= maxY; j += 1) {
            changes.push({ row: i, col: j, value: '' });
          }
        }
        this.onCellsChanged(changes);
      } else if (!e.shiftKey && ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        if (!selectedCells || !(selectedCells[0][0] === selectedCells[1][0] && selectedCells[0][1] === selectedCells[1][1])) return;
        e.preventDefault();
        e.stopPropagation();
        const { startRow, startCol } = this.state;
        const { childNodes } = this.bodyRef;
        const newSelectedCells = [[selectedCells[0][0], selectedCells[0][1]], [selectedCells[1][0], selectedCells[1][1]]];
        if (e.key === 'ArrowLeft') {
          const currentCell = childNodes[0].childNodes[newSelectedCells[0][1] - startCol];
          if (!currentCell) return;
          if (selectedCells[0][1] > 0) {
            newSelectedCells[0][1] = selectedCells[0][1] - 1;
            if (newSelectedCells[0][1] === startCol - 1) this.scrollLeft();
          }
        } else if (e.key === 'ArrowRight') {
          newSelectedCells[0][1] = Math.min(selectedCells[0][1] + 1, this.maxRows);
          const currentCell = childNodes[0].childNodes[newSelectedCells[0][1] - startCol];
          if (!currentCell) return;
          const rect = currentCell.getBoundingClientRect();
          if (rect.x + rect.width > window.innerWidth) this.scrollRight();
        } else if (e.key === 'ArrowUp') {
          newSelectedCells[0][0] = Math.max(selectedCells[0][0] - 1, 0);
          const currentCell = childNodes[newSelectedCells[0][0] - startRow].childNodes[newSelectedCells[0][1] - startCol];
          if (!currentCell) return;
          if (selectedCells[0][0] > 0) {
            newSelectedCells[0][0] = selectedCells[0][0] - 1;
            if (startRow > 0 && newSelectedCells[0][0] === startRow) this.scrollUp();
          }
        } else if (e.key === 'ArrowDown') {
          if (newSelectedCells[0][0] === 0 && startRow !== 0) newSelectedCells[0][0] = Math.min(startRow + 1, this.maxRows);
          else newSelectedCells[0][0] = Math.min(selectedCells[0][0] + 1, this.maxRows);
          const currentCell = childNodes[newSelectedCells[0][0] - startRow].childNodes[newSelectedCells[0][1] - startCol];
          if (!currentCell) return;
          const rect = currentCell.getBoundingClientRect();
          if (rect.y + rect.height > window.innerHeight) this.scrollDown();
        }
        newSelectedCells[1] = newSelectedCells[0];
        this.setSelectedCells(newSelectedCells);
      } else if (!(e.metaKey || e.ctrlKey) && e.key.length === 1 && (e.key.match(/[a-z0-9 /[]/i))) {
        if (!allowEditing) return;
        // e.preventDefault();
        // e.stopPropagation();
        this.setEditingCell(selectedCells[0], false, true);
      } else if ((e.metaKey || e.ctrlKey) && e.key === 'a') {
        e.preventDefault();
        e.stopPropagation();
        let maxCol = 0;
        data.forEach((row) => {
          maxCol = Math.max(maxCol, row ? row.length : 0);
        });
        const newSelectedCells = [[0, 0], [data.length - 1, maxCol - 1]];
        this.setSelectedCells(newSelectedCells);
      }
    }
  }
  contextMenu = (e, i, j) => {
    e.preventDefault();
    e.stopPropagation();
    const { allowEditing } = this.props;
    if (!allowEditing) return;
    this.setState({
      showContextMenu: true,
      coordX: e.clientX,
      coordY: e.clientY,
      doubleClick: {
        row: i,
        column: j,
      },
    });
  }
  adjustCellSize = (data, cellNode, row, col) => {
    const { selectedCells } = this.state;
    const { showWysiwyg } = SidebarService;
    const e = cellNode.firstChild;
    if (e) {
      const wysiwygDataset = showWysiwyg ? 'true' : 'false';
      const cellValue = data[row] && data[row][col] && data[row][col].value ? data[row][col].value : '';
      if (cellNode.dataset.value !== cellValue || parseInt(cellNode.dataset.row, 10) !== row || parseInt(cellNode.dataset.col, 10) !== col || cellNode.dataset.wysiwyg !== wysiwygDataset) {
        cellNode.dataset.row = row;
        cellNode.dataset.col = col;
        cellNode.dataset.value = cellValue;
        cellNode.dataset.wysiwyg = wysiwygDataset;
        if (showWysiwyg) e.innerHTML = purifyhtml(cellValue);
        else e.textContent = cellValue;
      }

      if (row === 0) {
        cellNode.style.borderBottom = '1.5px solid #d7d7d7';
      }
      cellNode.style.backgroundColor = '#ffffff';

      if (selectedCells) {
        if (this.between(row, selectedCells[0][0], selectedCells[1][0]) && this.between(col, selectedCells[0][1], selectedCells[1][1])) {
          if (cellNode.childNodes.length === 1) this.createOverlay(cellNode);
        } else if (cellNode.childNodes.length > 1) {
          cellNode.removeChild(cellNode.lastChild);
        }
      }
    }
  }
  wheelRef = (e) => {
    if (e) {
      e.onwheel = (evt) => this.onWheel(evt);
      e.ontouchmove = (evt) => this.onWheel(evt);
    }
  }
  between = (x, min, max) => x >= min && x <= max;
  getContextMenu = () => {
    const { onCreateRow, onCreateColumn, onDeleteRow, onDeleteColumn } = this.props;
    const { coordX, coordY, doubleClick, selectedCells, editingCell } = this.state;
    let startRow = editingCell ? editingCell[0] : selectedCells ? selectedCells[0][0] : doubleClick.row;
    let endRow = editingCell ? editingCell[0] : selectedCells ? selectedCells[1][0] : doubleClick.row;
    let startColumn = editingCell ? editingCell[1] : selectedCells ? selectedCells[0][1] : doubleClick.column;
    let endColumn = editingCell ? editingCell[1] : selectedCells ? selectedCells[1][1] : doubleClick.column;
    let rows = endRow - startRow + 1;
    let columns = endColumn - startColumn + 1;
    if (!this.between(doubleClick.row, startRow, endRow) || !this.between(doubleClick.column, startColumn, endColumn)) {
      startRow = doubleClick.row;
      endRow = doubleClick.row;
      startColumn = doubleClick.column;
      endColumn = doubleClick.column;
      rows = 1;
      columns = 1;
      const newSelectedCells = [[startRow, startColumn], [endRow, endColumn]];
      setTimeout(() => this.setSelectedCells(newSelectedCells));
    }
    else if (editingCell) setTimeout(() => this.clearEditingCell());
    return (
      <ContextMenu
        menu={[{
          label: rows > 1 ? `Insert ${rows} Rows Above` : 'Insert Row Above',
          onClick: () => onCreateRow(startRow, rows),
        }, {
          label: columns > 1 ? `Insert ${columns} Columns Left` : 'Insert Column Left',
          onClick: () => onCreateColumn(startColumn, columns),
        }, {
          seperator: true
        }, {
          label: rows > 1 ? `Delete ${rows} Rows` : 'Delete Row',
          onClick: () => onDeleteRow(startRow, rows),
        }, {
          label: columns > 1 ? `Delete ${columns} Columns` : 'Delete Column',
          onClick: () => onDeleteColumn(startColumn, columns),
        }]}
        onClose={this.onClose}
        coordX={coordX}
        coordY={coordY}
      />
    );
  }
  render() {
    const { data, spreadsheetKey, showRowsAndCols } = this.props;
    // Check for changed spreadsheet to clear the editing focus
    if (this.spreadsheetKey !== spreadsheetKey) {
      this.spreadsheetKey = spreadsheetKey;
      this.state.selectedCells = null;
      this.state.editingCell = null;
    }
    const { showContextMenu } = this.state;
    if (!data) return null;
    return (
      <Wrapper
        onCopy={this.onCopy}
        onPaste={this.onPaste}
        ref={this.wheelRef}
      >
        {showRowsAndCols && <ColNumTable>
          <tbody ref={this.onColNumRef} />
        </ColNumTable>}
        {showRowsAndCols && <RowNumTable>
          <tbody ref={this.onRowNumRef} />
        </RowNumTable>}
        <Table style={{ left: showRowsAndCols ? '2rem' : 0, top: showRowsAndCols ? '2rem' : 0 }} onCopy={this.onCopy} onPaste={this.onPaste}>
          <tbody onCopy={this.onCopy} onPaste={this.onPaste} ref={this.onBodyRef} />
        </Table>
        {showContextMenu && this.getContextMenu()}
      </Wrapper>
    );
  }
}
function parseData(str, delimiter) {
  // Remove last line break
  str = str.replace(/\r?\n$|\r$|\n$/g, "");
  // Last caracter is the delimiter
  if (str.charCodeAt(str.length-1) == 9) {
      str += "\0";
  }
  // user-supplied delimeter or default comma
  delimiter = (delimiter || ",");

  var arr = [];
  var quote = false;  // true means we're inside a quoted field
  // iterate over each character, keep track of current row and column (of the returned array)
  for (var row = 0, col = 0, c = 0; c < str.length; c++) {
      var cc = str[c], nc = str[c+1];
      arr[row] = arr[row] || [];
      arr[row][col] = arr[row][col] || '';

      // If the current character is a quotation mark, and we're inside a quoted field, and the next character is also a quotation mark, add a quotation mark to the current column and skip the next character
      if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue; }

      // If it's just one quotation mark, begin/end quoted field
      if (cc == '"') { arr[row][col] += cc; quote = !quote; continue; }

      // If it's a comma and we're not in a quoted field, move on to the next column
      if (cc == delimiter && !quote) { ++col; continue; }

      // If it's a newline (CRLF) and we're not in a quoted field, skip the next character and move on to the next row and move to column 0 of that new row
      if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue; }

      // If it's a newline (LF or CR) and we're not in a quoted field, move on to the next row and move to column 0 of that new row
      if (cc == '\n' && !quote) { ++row; col = 0; continue; }
      if (cc == '\r' && !quote) { ++row; col = 0; continue; }

      // Otherwise, append the current character to the current column
      arr[row][col] += cc;
  }

  return arr.map((c) => c.map((r) => {
    if (r.length > 2 && r.startsWith('"') && r.endsWith('"')) return r.substring(1, r.length - 1);
    return r;
  }));
}
