/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';
import LogicBasePage from '../../containers/LogicBasePage';
import LoginPage from '../../containers/LoginPage/index';
import RegisterPage from '../../containers/RegisterPage/index';
import LogicBaseUserPage from '../../containers/LogicBaseUserPage/index';
import LogicBaseAppPage from '../../containers/LogicBaseAppPage/index';
import TreeAnswerPage from '../../containers/TreeAnswerPage/index';
import LogicBaseMapPage from '../../containers/LogicBaseMapPage/index';
import ResetPasswordPage from '../../containers/ResetPasswordPage/index';
import SettingsPage from '../../containers/SettingsPage/index';
import AppLandingPage from '../../containers/AppLandingPage/index';
import LogicBaseLeaderboard from '../../containers/LogicBaseLeaderboard/index';
import LogicBaseNotificationPage from '../../containers/LogicBaseNotificationPage';
import LogicBaseFollowPage from '../../containers/LogicBaseFollowPage';
import LogicBaseAnalyticsPage from '../../containers/LogicBaseAnalyticsPage';
import LogicBaseCollectionsPage from '../../containers/LogicBaseCollectionsPage';
import LogicBaseCollectionPage from '../../containers/LogicBaseCollectionPage';
import LogicBaseCollectionEditPage from '../../containers/LogicBaseCollectionEditPage';
import LogicBaseDrawer from '../../components/LogicBaseDrawer';
import WindowSize from '../../services/WindowSize';
import DrawerService from '../../services/Drawer';
import NotFoundPage from '../../containers/NotFoundPage';
import LogicBaseUsersPage from '../../containers/LogicBaseUsersPage';
import LoginToViewPage from '../../containers/LoginToViewPage';
import LogicBaseExpertsPage from '../../containers/LogicBaseExpertsPage';
import LogicBaseAppCreatePage from '../../containers/LogicBaseAppCreatePage';

const Wrapper = styled.div`
  inset: 0;
  overflow: hidden;
  > div:nth-child(2) {
    background: white;
    @media (min-width: 960px) {
      display: none;
    }
  }
  > div:nth-child(3) {
    position: fixed;
    top: 0px;
    left: -210px;
    bottom: 0px;
    width: 210px;
    @media (min-width: 960px) {
      position: absolute;
      inset: 0 calc(100% - 210px) 0 0;
      transform: translateX(0px) !Important;
    }
  }
  > div:nth-child(1) {
    inset: 0;
    @media (min-width: 960px) {
      inset:0 0 0 210px;
  }
`;
const DrawerWrapper = styled.div`
  @media (max-width: 959px) {
    z-index: 1;
  }
`;


function LogicBaseRoutes({ positionAbsolute }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  Navigation.useNavigate = useNavigate();
  Navigation.location = useLocation();
  const { isWidget } = Constants;

  if (isWidget) return (
    <Routes key={window.location.href}>
      <Route path="collections/:id" element={<LogicBaseCollectionPage />} />
      <Route path="/apps/:id" element={<TreeAnswerPage />} />
      <Route path="/:id" element={<TreeAnswerPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );

  const specialRoute = ['login', 'register', 'reset-password'].includes(Navigation.currentLocation[1]);
  if (specialRoute) {
    if (UserAccount.isLoggedIn) setTimeout(() => { Navigation.replace(Navigation.home); });
  }

  const loggedIn = (UserAccount.state === 'loggedIn');
  const { isDevAccount } = UserAccount;
  const { mobile } = WindowSize;
  const { open } = DrawerService;
  if (mobile && open) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  const mobileAbsolute = ['login', 'register', 'reset-password', 'legal'].includes(Navigation.currentLocation[1]);
  return (
    <Wrapper
      key={`${window.location.pathname}${window.location.search}${JSON.stringify(Navigation.location.state)}`}
      style={{ position: (mobile && !mobileAbsolute) ? 'relative' : 'absolute' }}
    >
      <div style={{ position: (mobile && !mobileAbsolute) ? 'relative' : 'absolute' }}>
        <Routes>
          <Route path="/" element={<LogicBasePage />} />
          <Route path="account" element={loggedIn && <LogicBaseUserPage /> || <LoginToViewPage />} />
          <Route path="account/notifications" element={loggedIn && <LogicBaseNotificationPage /> || <LoginToViewPage />} />
          <Route path="account/settings" element={loggedIn && <SettingsPage /> || <LoginToViewPage />} />
          <Route path="account/followers" element={loggedIn && <LogicBaseFollowPage /> || <LoginToViewPage />} />
          <Route path="account/following" element={loggedIn && <LogicBaseFollowPage /> || <LoginToViewPage />} />
          <Route path="account/follow-requests" element={loggedIn && <LogicBaseFollowPage /> || <LoginToViewPage />} />
          {isDevAccount && <Route path="analytics" element={<LogicBaseAnalyticsPage />} />}
          <Route path="apps/create" element={loggedIn && <LogicBaseAppCreatePage /> || <LoginToViewPage />} />
          <Route path="apps/:id" element={<LogicBaseAppPage />} />
          <Route path=":id/likes" element={<LogicBaseUsersPage />} />
          <Route path="comments/:id/likes" element={<LogicBaseUsersPage />} />
          <Route path="about" element={<AppLandingPage app="636eb4ded7f57b480873a3b3" />} />
          <Route path="ideas" element={<AppLandingPage app="62c1e6d31e608b3bf12b00ba" />} />
          <Route path="ideas/apply" element={<AppLandingPage app="62c21548bad9fa4ae43e3b59" />} />
          <Route path="ideas/submit" element={<AppLandingPage app="63b834cbcdd5713fba176994" />} />
          <Route path="legal" element={<AppLandingPage app="5f38503f916d230a6bdf43a2" />} />
          <Route path="legal/controlling-terms" element={<AppLandingPage app="5f385208916d230a6bdf43a3" />} />
          <Route path="legal/definitions" element={<AppLandingPage app="5f384f9d916d230a6bdf43a1" />} />
          <Route path="legal/partner/advocate/agreement" element={<AppLandingPage app="5f5a330c014f57074bd5e017" />} />
          <Route path="legal/platform/terms" element={<AppLandingPage app="5f385275916d230a6bdf43a4" />} />
          <Route path="legal/platform/connect/terms" element={<AppLandingPage app="5f385453916d230a6bdf43a5" />} />
          <Route path="legal/privacy" element={<AppLandingPage app="5f3841ce916d230a6bdf439f" />} />
          <Route path="legal/professional-services/agreement" element={<AppLandingPage app="5f38554b916d230a6bdf43a6" />} />
          <Route path="legal/terms" element={<AppLandingPage app="5f3843f7916d230a6bdf43a0" />} />
          <Route path="creators" element={<LogicBaseLeaderboard />} />
          <Route path="creators/apply" element={<AppLandingPage app="63a795cb5da6aa2c9e7c2376" />} />
          <Route path="experts" element={<LogicBaseExpertsPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="map" element={<LogicBaseMapPage />} />
          <Route path="partners" element={<AppLandingPage app="64065da705b20223984c7783" />} />
          <Route path="partners/apply" element={<AppLandingPage app="64065e8005b20223984c7784" />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="search" element={<LogicBasePage />} />
          <Route path="search/:id" element={<LogicBasePage />} />
          <Route path="collections" element={<LogicBaseCollectionsPage />} />
          <Route path="collections/create" element={loggedIn && <LogicBaseCollectionEditPage /> || <LoginToViewPage />} />
          <Route path="collections/:id/subscribers" element={<LogicBaseUsersPage />} />
          <Route path="collections/:id" element={<LogicBaseCollectionPage />} />
          <Route path="collections/:id/edit" element={loggedIn && <LogicBaseCollectionEditPage /> || <LoginToViewPage />} />
          <Route path="users" element={<LogicBaseLeaderboard />} />
          <Route path="users/:id" element={<LogicBaseUserPage />} />
          <Route path="@:id" element={<LogicBaseUserPage />} />
          <Route path="@:id/followers" element={<LogicBaseFollowPage />} />
          <Route path="@:id/following" element={<LogicBaseFollowPage />} />
          <Route path="*" element={<LogicBaseAppPage />} />
        </Routes>
      </div>
      {open && <DrawerWrapper style={{ position: 'fixed', inset: 0, background: 'rgba(0,0,0,0.6)' }} onClick={() => DrawerService.toggle()}></DrawerWrapper> || <div></div>}
      <DrawerWrapper style={{ transform: open ? 'translateX(210px)' : 'translateX(0px)', transition: (mobile && open) ? '0.3s all' : null }}>
        <LogicBaseDrawer />
      </DrawerWrapper>
    </Wrapper>
  );
}
 
 export default LogicBaseRoutes;
 