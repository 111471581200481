import React from 'react';
import Constants from '../../submodules/logictry_config/constants';
import Connect from '../../services/Connect';

export default class ConnectAutoHeight extends React.PureComponent {
  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }
  onRef = (e) => {
    if (!e || this.ref === e) return;
    this.ref = e;
    const { isWidget } = Constants;
    if (isWidget) {
      if (this.observer) this.observer.disconnect();
      this.observer = new MutationObserver(this.resize);
      this.observer.observe(e.parentNode, {
        childList: true,
        subtree: true,
      });
      this.resize();
    }
  }
  resize = () => {
    if (!this.ref) return;
    const { parentNode } = this.ref;
    parentNode.style.height = 0;
    const setHeight = parentNode.scrollHeight + 1;
    parentNode.style.height = null;
    Connect.setHeight(setHeight);
  }
  render() {
    return (
      <div style={{ height: 0 }} ref={this.onRef} />
    );
  }
}
