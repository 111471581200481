import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import UserCache from '../../services/cache/UserCache';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import UserProfileHeader from '../../components/UserProfileHeader';
import Scrollable from '../../components/Scrollable';
import shortenLikesCount from '../../utils/shortenLikesCount';

const UserProfile = styled.div`
  color: ${Constants.Gray};
  margin-top: 0;
  margin-bottom: 3rem;
  @media (max-width: 959px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  h1 {
    font-weight: 600;
    font-size: ${Constants.VeryLargeFontSize};
    color: ${Constants.DarkText};
  }
  p {
    margin: 0;
  }
  > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div:nth-child(3) {
      margin-bottom: 0.5rem;
      font-size: ${Constants.SmallFontSize};
    }
    > * {
      width: unset !important;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export default class LogicBaseCollectionPage extends React.PureComponent {
  state = {
    overrideRelationship: null,
    appname: Navigation.currentLocation[2]
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  subscribe = (subscription, collection) => {
    const { isLoggedIn } = UserAccount;
    if (!isLoggedIn) return Navigation.push(Navigation.login);
    if (subscription) {
      setTimeout(() => {
        LogicBaseCollectionSubscriptionCache.__deleteQueryFromCache({ fr: UserAccount.account._id, c: collection._id });
        LogicBaseCollectionSubscriptionCache.delete(subscription, null, true);
      });
      collection.followers -= 1;
      this.setState({ overrideRelationship: 'Subscribe' });
    } else {
      setTimeout(() => {
        const newSubscription = { fr: UserAccount.account._id, c: collection._id };
        LogicBaseCollectionSubscriptionCache.__deleteQueryFromCache(newSubscription);
        LogicBaseCollectionSubscriptionCache.create(LogicBaseCollectionSubscriptionCache.createObject(newSubscription), true);
      });
      collection.followers += 1;
      this.setState({ overrideRelationship: '✓ Subscribed' });
    }
  }
  render() {
    const { appname } = this.state;
    const { mobile } = WindowSize;
    const { account, isLoggedIn } = UserAccount;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const notFound = <FullScreenVerticalCenterContent><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;
    const collections = LogicBaseCollectionCache.query({ u: appname.toLowerCase() });
    if (!collections) return loading;
    if (collections.length === 0) return notFound;
    const collection = collections[0];
    const publicApps = LogicBaseAppCache.getByIds(collection.apps);
    if (!publicApps) return loading;
    const publicUser = LogicBaseUserCache.get(collection.owner);
    if (!publicUser) return loading;
    const users = UserCache.getUsersByIds([collection.owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    const editProfileButtonStyle = { cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)', color: 'black', padding: '0.25rem 1rem', borderRadius: '0.5rem', flexShrink: 0, margin: '0 0 2rem' };
    const isOwner = isLoggedIn && collection.owner === account._id;
    const contributors = collection.editors && UserCache.getUsersByIds(collection.editors) || [];
    const subscription = (isLoggedIn && LogicBaseCollectionSubscriptionCache.query({ fr: UserAccount.account._id, c: collection._id }) || [])[0];
    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '0 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasecollection_${collection.title}`}
      >
        <UserProfile>
          <div>
            {isOwner && <div style={editProfileButtonStyle} onClick={() => Navigation.push(`${Navigation.collections}/${appname}/edit`)}>Edit Collection</div>}
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              {collection && ([user, ...contributors].map((user) => (
                <UserProfileHeader
                  key={user && user._id}
                  user={user}
                  small
                  hideFollow
                  hideProfile
                />
              )))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', margin: '1rem 0' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => Navigation.push(`${Navigation.collections}/${appname}/subscribers`)}><span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{shortenLikesCount(collection.followers || 0)}</span>{collection.followers === 1 ? 'subscriber' : 'subscribers'}</div>
              <div><span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{collection.apps.length || 0}</span>{collection.apps.length === 1 ? 'app' : 'apps'}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h1 style={{ margin: '0.5rem 0' }}>{collection.title}</h1>
              <div style={{ cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)', color: 'black', padding: '0.25rem 1rem', borderRadius: '0.5rem', flexShrink: 0 }} onClick={() => this.subscribe(subscription, collection)}>{this.state.overrideRelationship ? this.state.overrideRelationship : subscription ? '✓ Subscribed' : 'Subscribe'}</div>
            </div>
            {collection.description && <p>{collection.description}</p>}
          </div>
        </UserProfile>
        {publicApps.length > 0 && <LogicBaseAppList showUser apps={publicApps} />}
      </Scrollable>
    );
  }
}
