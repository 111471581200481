// import React from 'react';
// import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Service from './Service';
import Connect from './Connect';
import Constants from '../submodules/logictry_config/constants';
import Answerable from '../models/answerable';
import ForEachAnswer from '../models/foreachanswer';
import Tree, { recursivelyExpandChildrenAnswered, recursivelyExpandToCategories } from '../models/tree';
import Text from '../models/text';
import Answer from '../models/answer';
import ChecklistItem from '../models/checklistitem';
// import Subtree from '../models/subtree';
import ScrollIt from '../utils/Scroll';
import Questionable from '../models/questionable';
import { SEARCH_TEXT } from '../models/nodetypes';
import Action from '../models/action';
import Wiki from '../models/wiki';
import Category from '../models/category';
import { FORM_ATTR } from '../models/nodeattributes';
import Subtree from '../models/subtree';
import Node from '../models/node';

class TreeDisplay extends Service {
  __activeObject;
  __lastActiveObject;
  __tree;
  get editing() {
    return this.__tree.editing;
  }
  get tree() {
    return this.__tree;
  }
  get activeObject() {
    return this.__activeObject;
  }
  get activeObjectParents() {
    return this.__activeObject && Array.from(this.__activeObject.parents) || [];
  }
  get lastActiveObject() {
    return this.__lastActiveObject;
  }
  get lastActiveObjectParents() {
    return this.__lastActiveObject && Array.from(this.__lastActiveObject.parents) || [];
  }
  activeOrLastActiveObjectAndParents() {
    if (this.activeObject) return { activeObject: this.activeObject, activeObjectParents: this.activeObjectParents };
    if (this.lastActiveObject) return { activeObject: this.lastActiveObject, activeObjectParents: this.lastActiveObjectParents };
    return { activeObject: null, activeObjectParents: [] };
  }
  get activeObjectParent() {
    return this.activeObjectParents.slice(-1)[0];
  }
  get activeObjectExists() {
    return this.__activeObject !== null;
  }
  isActiveNodeOnly(node) {
    return this.__activeObject === node;
  }
  isActive(node) {
    return this.__activeObject === node && node.parents.length === this.activeObjectParents.length && node.parents.every((_parent) => (this.activeObjectParents.includes(_parent)));
  }
  isActiveOrParentOfActive(node) {
    return this.__activeObject && (this.__activeObject === node || (this.activeObjectParents && this.activeObjectParents.includes(node)));
  }
  isLastActiveOrParentOfActive(node) {
    return this.__lastActiveObject && (this.__lastActiveObject === node || (this.lastActiveObjectParents && this.lastActiveObjectParents.includes(node)));
  }
  addScrollableParent(div) {
    this.__scrollableParents.add(div);
  }
  findPaneRef(node) {
    if (node == null) return null;
    const foundScrollableParent =  node && this.__scrollableParents.has(node);
    if (foundScrollableParent) return node;
    return this.findPaneRef(node.parentNode);
  }
  onGoToNextQuestion = (_goToNext) => {
    // We are using an interval because of delays in html layout
    // Mostly this deals with asynchronous delays due to Wysiwyg layouts
    if (this.scrollToInterval) clearInterval(this.scrollToInterval);
    this.scrollToIntervalAttempts = 0;
    this.scrollToInterval = setInterval(() => {
      this.scrollToIntervalAttempts += 1;
      if (this.scrollToIntervalAttempts > 20) {
        if (this.scrollToInterval) clearInterval(this.scrollToInterval);
        return;
      }
      if (document.activeElement === document.body) return;
      const paneRef = this.findPaneRef(document.activeElement);
      if (!paneRef) return;
      if (this.scrollToInterval) clearInterval(this.scrollToInterval);
      if (!(this.editing || (this.activeObject && this.activeObject.goToNextOnSelection) || _goToNext)) return;
      ScrollIt(document.activeElement, paneRef);
      if (Constants.isWidget) {
        const { top } = document.activeElement.getBoundingClientRect();
        Connect.centerContent(top + window.pageYOffset);
      }
    }, 20);
  }
  scrollTop(instant) {
    // Find all scrollable children
    const scrollChildren = getScrollChildren(document.body);
    scrollChildren.forEach((c) => ScrollIt(0, c, instant ? 0 : 200));
    if (Constants.isWidget) Connect.scrollTopContent();
  }
  setTree(_tree) {
    const _previousTree = this.__tree;
    if (_previousTree === _tree) return;

    if (Constants.isDevelopment) window.tree = _tree;

    // Handle onChangeActiveObject with tree as delegate
    if (_previousTree) delete _previousTree.onRemoveActiveObject;

    if (!_tree || _tree.error) {
      this.__activeObject = null;
      this.__lastActiveObject = null;
      this.__tree = null;
      return;
    }
    this.__tree = _tree;
    this.__scrollableParents = new Set();

    const mergeOldToNewTree = !!(_previousTree && (!_previousTree._id || _previousTree._id === _tree._id) && _previousTree.root && _previousTree.root.children);
    const treeWasJustCreated = !_tree._id || (_previousTree && !_previousTree._id);

    // eslint-disable-next-line no-param-reassign
    _tree.onRemoveActiveObject = (node) => {
      if (this.__activeObject && (node === this.__activeObject || this.__activeObject.parents.includes(node))) this.clearAllActiveObjects();
      if (!this.__activeObject && this.__lastActiveObject && (node === this.__lastActiveObject || this.__lastActiveObject.parents.includes(node))) this.clearAllActiveObjects();
    }

    if (mergeOldToNewTree) this.__mergeExpandedOldToNewTree(_previousTree.root, _tree.root);
    else if (!this.editing) recursivelyExpandChildrenAnswered(_tree.root);
    if (this.editing) recursivelyExpandToCategories(_tree.root);

    if (treeWasJustCreated) {
      // Either a tree not yet created or one that was just created
      this.clearAllActiveObjects();
      if (_tree.root.children[0]) this.addActiveObject(_tree.root.children[0]);
      else if (_tree.root.text) this.addActiveObject(_tree.root);
      else if (_tree.title.text) this.addActiveObject(_tree.title);
      else if (_tree.description.text) this.addActiveObject(_tree.description);
    } else {
      this.clearAllActiveObjects();
    }
  }
  isExpanded(node) {
    return node.expanded;
    // return node.expanded && parents.every((_parent) => _parent.expanded);
  }
  setExpanded(node, expanded, expandChildren) {
    node.setExpanded(expanded, true);
    node.parents.forEach((_parent) => { _parent.setExpanded(true, true); });
    if (expandChildren) this.__recursivelyExpandChildren(node, expanded);
  }
  __recursivelyExpandChildren(node, expanded) {
    node.children.forEach((child) => {
      child.setExpanded(expanded, true);
      this.__recursivelyExpandChildren(child, expanded);
    });
  }
  clearAllActiveObjects() {
    this.__activeObject = null;
    this.__lastActiveObject = null;
    this.emitStateUpdate();
  }
  clearActiveObject() {
    this.saveLastActiveObject();
    this.__activeObject = null;
    this.emitStateUpdate();
  }
  removeActiveObject(_activeObject) {
    if (!this.__activeObject || _activeObject.key !== this.__activeObject.key) return;
    this.clearActiveObject();
  }
  addActiveObject(_activeObject, _goTo) {
    if (this.__activeObject && this.__activeObject.key === _activeObject.key) return;
    this.saveLastActiveObject();
    this.__activeObject = _activeObject;
    this.setExpanded(this.__activeObject, true);
    this.emitStateUpdate();
    if (_goTo && this.onGoToNextQuestion) this.onGoToNextQuestion(true);
  }
  goToActiveObject() {
    if (this.onGoToNextQuestion) this.onGoToNextQuestion(true);
  }
  saveLastActiveObject() {
    if (this.__activeObject) {
      this.__lastActiveObject = this.__activeObject;
    }
  }
  goToNextQuestion(goBack, goToFirstUnansweredQuestion) {
    if (!this.__activeObject) return;
    const chain = [];
    let previousChild;
    let found = false;
    let foundNext = false;
    const __findChild = (_currentChild, _chain) => {
      if (found instanceof Answerable) return;
      if (foundNext) return;
      if (found && goBack) return;
      if (found === true && (this.editing || !_currentChild.isTextHidden)) {
        if (_currentChild instanceof Questionable || _currentChild instanceof Action || _currentChild instanceof Subtree || _currentChild instanceof Text) {
          chain.push(_currentChild);
        }
        if (this.editing && !_currentChild.isOneOfTypes([SEARCH_TEXT])) {
          foundNext = true;
        } else if (_currentChild instanceof Questionable) {
          const someAnswersHaveText = _currentChild.answers.some((a) => a.text);
          const isUnanswered = _currentChild.answersChecked.length === 0;
          const isUnsweredOrOKToGoAnyway = (!goToFirstUnansweredQuestion || isUnanswered);
          const notAnotherAnswerIsCurrentChild = !_currentChild.children.includes(this.__activeObject);
          if (!_currentChild.hide && someAnswersHaveText && notAnotherAnswerIsCurrentChild && isUnsweredOrOKToGoAnyway) foundNext = true;
        } else if (_currentChild instanceof Answer) {
          const doesNotContainText = !_currentChild.text;
          const notAnotherAnswerHasText = !_currentChild.parent.answers.some((a) => a.text);
          const notAnotherAnswerIsCurrentCHild = !_currentChild.parent.children.includes(this.__activeObject);
          const isUnsweredOrOKToGoAnyway = (!goToFirstUnansweredQuestion || _currentChild.parent.answersChecked.length === 0);
          if (!_currentChild.hide && doesNotContainText && notAnotherAnswerHasText && notAnotherAnswerIsCurrentCHild && isUnsweredOrOKToGoAnyway) foundNext = true;
        } else if (_currentChild instanceof ChecklistItem && !_currentChild.hide) {
          foundNext = true;
        } else if (!goToFirstUnansweredQuestion && _currentChild instanceof Text) {
          foundNext = true;
        } else if (_currentChild instanceof Action) {
          foundNext = true;
        } else if (_currentChild instanceof Subtree) {
          foundNext = true;
        }
        if (foundNext) {
          found = _currentChild;
          if (!goToFirstUnansweredQuestion) {
            this.__activeObject = _currentChild;
            this.setExpanded(this.__activeObject, true);
          }
          return;
        }
      } else if (_currentChild === this.__activeObject && _chain.length === this.activeObjectParents.length && _chain.every((_parent) => (this.activeObjectParents.includes(_parent)))) {
        found = true;
      }
      if (found && goBack) {
        if (previousChild) {
          found = previousChild;
          this.__activeObject = previousChild;
          this.setExpanded(this.__activeObject, true);
        }
        return;
      }
      if (!found) previousChild = _currentChild;
      if (this.editing || !((found === true && _currentChild instanceof Answerable && !_currentChild.checked) || (_currentChild instanceof ForEachAnswer))) {
        const parentChainIsWiki = _chain.find((_parent) => _parent instanceof Wiki);
        const allowRecursingChildren = !_currentChild.isNodeHidden && !(found === true && parentChainIsWiki && parentChainIsWiki.contentFormatOption === FORM_ATTR && _currentChild instanceof Category);
        if (_currentChild instanceof Tree) __findChild(_currentChild.root, [..._chain, _currentChild]);
        else if (this.editing || allowRecursingChildren) _currentChild.children.forEach((_child) => __findChild(_child, [..._chain, _currentChild]));
      }
    };
    __findChild(this.__tree.root, [this.__tree]);
    if (this.editing) __findChild(this.__tree.branches, [this.__tree]);
    if (this.editing) __findChild(this.__tree.report, [this.__tree]);
    if (found instanceof Node) {
      // Only update if found is a new node
      this.emitStateUpdate();
      if (this.onGoToNextQuestion) this.onGoToNextQuestion(true);
    }
    return chain;
  }
  __mergeExpandedOldToNewTree(_oldNode, _newNode) {
    // eslint-disable-next-line no-param-reassign
    _newNode.setExpanded(_oldNode.expanded);
    if (!_oldNode.expanded) return;
    _newNode.children.forEach((_child) => {
      const oldNodeFound = _oldNode.children.find((child) => child.isMatchingNode(_child));
      if (oldNodeFound) this.__mergeExpandedOldToNewTree(oldNodeFound, _child);
    });
  }
}

function getScrollChildren(node) {
  let nodes = [];  
  if (node == null) {
    return nodes;
  }
  if (node.scrollHeight > node.clientHeight) {
    nodes.push(node);
  }
  Object.values(node.children).forEach((c) => {
    const newNodes = getScrollChildren(c);
    if (newNodes.length > 0) nodes = [...nodes, ...newNodes];
  })
  return nodes;
}

const singleton = new TreeDisplay();
export default singleton;
