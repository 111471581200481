import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import Button from '../Button/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import Constants from '../../submodules/logictry_config/constants';
import Company from '../../services/Company';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TagGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  text-align: center;
  > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div {
      cursor: pointer;
      padding: 0.5rem 1rem;
      text-align: center;
      border-radius: 1.5rem;
      margin: 0.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
    }
  }
`;

export default class TagsBox extends BoxPopup {
  toggleOption = (name, option) => {
    const { domainObject } = this.props;
    domainObject.toggleTag(name, option);
    this.forceUpdate();
  }
  render() {
    const { domainObject } = this.props;
    const { tagGroups: companyTagGroups } = Company.company
    const tagGroups = domainObject.tagGroups || [];
    return (
      <div>
        <Popup
          onClose={this.close}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Tags</h1>
            {companyTagGroups.map(({ name: companyName, options }) => (
              <TagGroup key={companyName}>
                <h2>{companyName}</h2>
                <div>
                  {options.map((option) => {
                    const found = tagGroups.find(({ name, options }) => name === companyName && options.includes(option));
                    return (
                      <div key={option} style={{ backgroundColor: found ? Constants.PrimaryColor : 'rgb(242, 242, 242)', color: found ? 'white' : null }} onClick={() => this.toggleOption(companyName, option)}>{option}</div>
                    )
                  })}
                </div>
              </TagGroup>
            ))}
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.saveAndClose} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
