import React from 'react';
import styled from 'styled-components';
import { getLatestSubmissions } from '../../services/Pagination';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import UserApi from '../../services/server/UserApi';
import StatApi from '../../services/server/StatApi';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import UserProfileHeader from '../../components/UserProfileHeader';

const Leaderboard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  max-width: 52rem;
  margin: auto;
  h1 {
    margin: 2rem 0;
    text-align: center;
  }
`;
const Content = styled.div`
  margin-top: 2rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  > div {
    padding: 0rem 1rem;
    cursor: pointer;
    color: #666;
  }
`;
let content = 'users';

export default class LogicBaseAnalyticsPage extends React.PureComponent {
  constructor() {
    super();
    this.latestSubmissions = getLatestSubmissions('');
  }
  state = {
    users: [],
    count: 0
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    this.latestSubmissions.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserApi.getCount().then(({ count }) => { this.setState({ count }); });
    StatApi.query({ page: 1, count: 1000 }).then(({ stats, appStats }) => {
      if (!stats || !stats[0] || !stats[0].durations) return;
      stats[0].durations.reverse();
      this.setState({ stats, appStats });
    });
    this.changeContent(content);
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    this.latestSubmissions.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    UserCache.offStateUpdate(this);
  }
  changeContent = (_content) => {
    content = _content;
    const { users, sessions } = this.state;
    if (content === 'users' && (!users || users.length === 0)) {
      UserApi.query({ page: 1, count: 1000 }).then((users) => { this.setState({ users }); });
    } else if ((content === 'sessions' || content === 'appstats') && (!sessions || sessions.length === 0)) {
      // SessionApi.query({ page: 1, count: 15 }).then((res) => {
      //   if (!res) return;
      //   const { sessions, appSessions } = res;
      //   this.setState({ sessions, appSessions });
      // });
    }
    this.forceUpdate();
  }
  onPagination = () => {
    if (this.latestSubmissions.showMore && this.latestSubmissions.allFound && this.latestSubmissions.initialized) {
      this.latestSubmissions.getMore();
    }
  }
  render() {
    const { users, count, stats } = this.state;
    const { mobile } = WindowSize;
    const { isDevAccount } = UserAccount;
    if (!isDevAccount) return null;
    const { currentPage } = this.latestSubmissions;
    const selectedStyle = {
      fontWeight: 500,
      color: 'black',
      borderRadius: '0.5rem'
    };

    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '0 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        rememberScrollPosition={`logicbaseanalytics`}
        onPagination={this.onPagination}
      >
        <Leaderboard ref={(e) => { this.appListRef = e; }}>
          <h1>Analytics</h1>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <h2>{count} users</h2>
          </div>
          <Content>
            <div style={content === 'users' && selectedStyle || {}} onClick={() => this.changeContent('users')}>Users</div>
            <div style={content === 'sessions' && selectedStyle || {}} onClick={() => this.changeContent('sessions')}>Sessions</div>
            {/* <div style={content === 'appstats' && selectedStyle || {}} onClick={() => this.changeContent('appstats')}>App Stats</div> */}
            <div style={content === 'submissions' && selectedStyle || {}} onClick={() => this.changeContent('submissions')}>Submissions</div>
          </Content>
          {content === 'users' && users && users.map((user) => (
            <div key={user._id} style={{ marginBottom: '1rem' }}>
              <UserProfileHeader
                user={user}
                medium
              />
              <div key={user._id} style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center' }}>
                <div>{user.originalSubdomain || 'Logictry'}</div>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(user.createdTime).toLocaleString()}</div>
              </div>
            </div>
          ))}
          {content === 'sessions' && stats && stats[0].durations.map(({ _id, u, t, au, at }) => {
            // const sessionUsers = [];
            // sessions.forEach((session) => {
            //   const user = userProfiles.find(({ _id }) => _id === session._id.user);
            //   const duration = session && session.durations.find((d) => d._id === _id);
            //   if (!duration) return null;
            //   sessionUsers.push({ user, duration: duration.t });
            // });
            // sessionUsers.sort((a, b) => b.duration - a.duration);
            return (
              <div key={_id}>
                <h3 style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', }}>
                  <div><b>Day {_id}</b></div>
                  <div>Logged In = {u} users for {formatTime(t)} time</div>
                  {au && at && <div>Logged Out = {au} users for {formatTime(at)} time</div>}
                </h3>
                {/* {sessionUsers.map(({ user, duration }) => {
                  return <div key={user._id} style={{ display: 'flex', alignItems: 'center', margin: '0.5rem 0' }}>
                    <UserProfileHeader
                      user={user}
                      small
                    />
                    {duration && <div style={{ whiteSpace: 'nowrap' }}>{formatTime(duration)} time</div>}
                  </div>
                })} */}
              </div>
            )
          })}
          {/* {content === 'appstats' && publicApps && publicApps.length > 0 && days.map((day) => {
            let apps = [];
            appStats.forEach((appStat) => {
              const duration = appStat.durations.find((d) => d._id === day);
              if (!duration) return;
              const publicApp = publicApps.find((app) => app._id === appStat._id.app);
              if (!publicApp) return;
              apps.push({ app: publicApp, usage: { users: (duration.u || 0) + (duration.au || 0), time: (duration.t || 0) + (duration.at || 0) } });
            });
            apps.sort((a, b) => b.usage.time - a.usage.time);
            return (
              <div key={day}>
                <h3 style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', }}>
                  <div><b>Day {day}</b></div>
                </h3>
                <LogicBaseAppList apps={apps.map(({ app }) => app)} showUser listView={!mobile} small usage={apps.map(({ usage }) => usage)} />
              </div>
            )
          })} */}
          {content === 'submissions' && <LogicBaseAppList apps={currentPage} showUser listView={!mobile} showTime />}
          {/* {currentPage.map(({ createdTime, question, questionAnswers, answer }) => (
            <div>
              <h2>{question}</h2>
              <div>{(new Date(createdTime)).toLocaleString()}</div>
              <div>{questionAnswers.map(({ q, a }) => (
                <div>
                  <div>{q}</div>
                  <div>{a.join(' | ')}</div>
                </div>
              ))}</div>
              <div>{answer}</div>
            </div>
          ))} */}
        </Leaderboard>
      </Scrollable>
    )
  }
}
function formatTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  return `${hours} hr ${minutes} min ${remainingSeconds} sec`;
}
