import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
// import GrayOut from '../../services/TreeDisplay';
// import Speech from '../../services/Speech';
// import Navigation from '../../services/Navigation';
import TreeCreateNode from '../../services/TreeCreateNode';
import preventDefault from '../../utils/preventDefault';
import Tooltip from '../../components/Tooltip/index';
import TooltipText from '../../translations/tooltips/en.json';
import { NODE_DISPLAY_TEXT_TYPES, BASIC_PALETTE_TYPES } from '../../models/nodetypes';

const Wrapper = styled.div`
  padding: 0px 3px 0px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    width: 180px;
    text-align: left;
    margin: 3px auto;
    font-size: ${Constants.SemiSmallFontSize};
  }
`;
const NodeButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  > div:first-child {
    flex: 1;
  }
`;
const SiblingButton = styled.div`
  padding: 8px;
`;
const ChildButton = styled.div`
  padding: 8px;
  transform: rotateY(180deg) rotate(45deg);
`;

export default class TreeEditBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    basicTypes: PropTypes.bool,
  }
  getText = (phrase, command) => {
    const splitCommand = phrase.split(command);
    if (splitCommand.length === 2) return this.firstCharToUpper(splitCommand[1].trim());
    return '';
  }
  getButton = (text, siblingOptions, childOptions) => {
    const { node } = this.props;
    const { parents, parent } = node;
    const sibling = parent;
    const showSibling = siblingOptions.includes(text);
    const showChild = childOptions.includes(text);
    const tooltipText = TooltipText[text];
    return (
      <NodeButton key={text}>
        <Tooltip text={tooltipText}><div>{NODE_DISPLAY_TEXT_TYPES[text] || text}</div></Tooltip>
        {this.getSiblingButton(text, sibling, parents.slice(0, -1), showSibling, sibling && sibling.children && (sibling.children.indexOf(node) + 1))}
        {this.getChildButton(text, node, parents, showChild)}
      </NodeButton>
    );
  }
  getSiblingButton = (text, node, parents, show, index) => <SiblingButton role="button" tabIndex={-1} onClick={(e) => preventDefault(this.getAction)(e, text, node, parents, index)} style={{ opacity: show ? 1 : 0.3, pointerEvents: show ? 'unset' : 'none' }}><i className="fas fa-arrow-down"></i></SiblingButton>;
  getChildButton = (text, node, parents, show) => <ChildButton role="button" tabIndex={-1} onClick={(e) => preventDefault(this.getAction)(e, text, node, parents, 0)} style={{ opacity: show ? 1 : 0.3, pointerEvents: show ? 'unset' : 'none' }}><i className="fas fa-arrow-down"></i></ChildButton>;
  getAction = (text, node, parents, index) => TreeCreateNode.createNode(text, node, parents, index);
  firstCharToUpper = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const { node, basicTypes } = this.props;
    const { parent } = node;
    const childOptions = TreeCreateNode.getCreateOptions(node);
    const siblingOptions = TreeCreateNode.getCreateOptions(parent);
    const totalOptions = Array.from(new Set([...childOptions, ...siblingOptions])).sort(new Intl.Collator('en').compare);
    return (
      <Wrapper>
        {totalOptions.filter((type) => {
          const isBasicType = BASIC_PALETTE_TYPES.includes(type);
          return basicTypes ? isBasicType : !isBasicType
        }).map((option) => this.getButton(option, siblingOptions, childOptions))}
      </Wrapper>
    );
  }
}

