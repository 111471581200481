/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import LogicBaseTopics from '../../components/LogicBaseTopics';
import { getLogicBaseTopLikesApps, getTrendingCollections, getLogicBaseLatestApps } from '../../services/Pagination';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import LogicBaseAppLists from '../../components/LogicBaseAppLists';
import Scrollable from '../../components/Scrollable';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';

const Wrapper = styled.div`
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: absolute;
`;
const Banner = styled.div`
  background-size: contain;
  height: 340px;
  width: 100%;
  color: white;
  font-size: 2em;
  background: radial-gradient(ellipse at 50% 0%, #f9ebd2 0%,#f19494 30%, #9c6972 100%);
  border-radius: 1rem;

  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
  }
  div {
    margin: 0;
    font-size: 1rem;
  }
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;
    height: 400px;
    h1 {
      font-size: 1.8rem;
    }
    div {
      font-size: 0.9rem;
    }
  }
  > div {
    flex: 1;
    height: 100%;
  }
  > div:first-child {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    @media (max-width: 1200px) {
      flex: unset;
      height: unset;
    }
  }
  > div:last-child {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100%;
    flex: 1;
    > div:first-child {
      display: flex;
      align-items: center;
      margin: 2rem auto;
      @media (max-width: 1200px) {
        margin: 1rem auto;
      }
      @media (max-width: 400px) {
        gap: 1rem;
      }
      gap: 2rem;
      > div:first-child {
        > div:first-child {
          font-size: 0.75rem;
          text-transform: uppercase;
        }
        > div:nth-child(2) {
          font-size: 1.25rem;
          font-weight: 500;
          @media (max-width: 400px) {
            font-size: 1rem;
          }
        }
      }
      > div:last-child {
        border: 2px solid white;
        border-radius: 2rem;
        padding: 0 1rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 600;
        transition: 0.3s all;
        &:hover {
          background: white;
          color: #d68689;
        }
      }
    }
    > div:last-child {
      background-image: url(https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/07cd47a9c8542159c77de4b365b68d2e/Untitled%20design%20%2826%29.png);
      flex: 1;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(2px);
    }
  }
`;
const Content = styled.div`
  inset: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-bottom: 4rem;
  h2 {
    font-weight: 500;
    margin: 0 0 0.75rem;
    cursor: pointer;
  }
  p {
    margin: 0 0 0.5rem;
  }
  > div:not(:first-child) {
    height: 240px;
  }
`;
export default class LogicBasePage extends React.PureComponent {
  constructor() {
    super();
    this.mostLikesCache = getLogicBaseTopLikesApps('');
    this.mostRecentCache = getLogicBaseLatestApps('');
    this.collections = getTrendingCollections('');
  }
  state = {
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || ''
  }
  componentDidMount() {
    this.mostLikesCache.onStateUpdate(this);
    this.mostRecentCache.onStateUpdate(this);
    this.collections.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.mostLikesCache.offStateUpdate(this);
    this.mostRecentCache.offStateUpdate(this);
    this.collections.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
      this.forceUpdate();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { q } = this.state;
    if (q) {
      const showTopics = (!q || q === 'topic');
      if (mobile) return <>
        <div style={{ padding: '0 1rem' }}>
          {showTopics && <LogicBaseTopics url={'/search'} row />}
        </div>
        <div style={{ top: (q || mobile) ? 0 : '3rem', padding: '0 1rem' }}>
          <LogicBaseAppLists showUser />
        </div>
      </>
      return (
        <Wrapper>
          <Content>
            <div>
              {showTopics && <LogicBaseTopics url={'/search'} row />}
            </div>
            <div style={{ top: (!showTopics || mobile) ? 0 : '3rem' }}>
              <LogicBaseAppLists showUser />
            </div>
          </Content>
        </Wrapper>
      );
    }

    // let mostLikedApps = this.mostLikesCache.currentPage;
    // let mostRecentApps = this.mostRecentCache.currentPage;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const notFound = <FullScreenVerticalCenterContent><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;
    const collections = this.collections.currentPage;
    if (!collections || collections.length === 0) return loading;
    if (!collections) return loading;
    if (collections.length === 0) return notFound;
    const featured = LogicBaseAppCache.getByIds(['6643c847191fbf0e2bb8f491', '6626afcbade0ec0e297e5e17', '65e794b860ad185e51d8d923']);

    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '1rem 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        paginationHeight={400}
        onPagination={this.onPagination}
        rememberScrollPosition={`logicbasepage`}
      >
        <Content>
          <Banner>
            <div>
              <h1>Welcome to Logictry</h1>
              <div>Become an expert faster by learning from experts</div>
            </div>
            <div onClick={() => Navigation.site('https://www.amazon.com/dp/1961098520')}>
              <div>
                <div>
                  <div>Featured Expert</div>
                  <div>Bailey Browder</div>
                </div>
                <div>View Book</div>
              </div>
              <div />
            </div>
          </Banner>
          {/* <div>
            <h2>Featured</h2>
            <Scrollable
              horizontal
              onScroll={this.onScroll}
              scrollRef={(e) => { this.topicsRef = e; }}
              rememberScrollPosition={`logicbasepage_mostliked`}
            >
              {featured && <LogicBaseAppList showUser apps={featured} horizontal medium />}
            </Scrollable>
          </div> */}
          {collections.map((collection) => {
            const loading = <FullScreenVerticalCenterContent key={collection._id}><LoadingIndicator /></FullScreenVerticalCenterContent>;
            const notFound = <FullScreenVerticalCenterContent key={collection._id}><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;
            const publicApps = LogicBaseAppCache.getByIds(collection.apps);
            if (!publicApps) return loading;
            const publicUser = LogicBaseUserCache.get(collection.owner);
            if (!publicUser) return loading;
            const users = UserCache.getUsersByIds([collection.owner]);
            if (!users) return loading;
            const user = users[0];
            if (!user || !user._id) return notFound;
            return <div key={collection._id}>
              <h2 onClick={() => Navigation.push(`/collections/${collection.url}`)}>{collection.title}</h2>
              <Scrollable
                horizontal
                onScroll={this.onScroll}
                scrollRef={(e) => { this.topicsRef = e; }}
                rememberScrollPosition={`logicbasepage_${collection.url}`}
              >
                {/* <p style={{ color: Constants.LightTextColor, marginBottom: '1rem' }}>{collection.description}</p> */}
                <LogicBaseAppList showUser apps={publicApps} horizontal medium appCollection={collection.url} />
              </Scrollable>
            </div>
          })}
        </Content>
      </Scrollable>
    );
  }
}
