import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';
import ChangeableImage from '../../components/ChangeableImage/index';
import AssetCache from '../../services/cache/AssetCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: hidden;
  // padding: 20px;
  font-size: ${Constants.SmallFontSize};
  display: flex;
  align-items center;
  justify-content: center;
`;

export default class AssetPage extends React.PureComponent {
  componentDidMount() {
    AssetCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    AssetCache.offStateUpdate(this);
  }
  render() {
    const assetId = Navigation.currentLocation[2];
    const asset = AssetCache.get(assetId);
    if (!asset) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (asset.error) return <FullScreenVerticalCenterContent><h1>{`Asset Error: ${asset.error}`}</h1></FullScreenVerticalCenterContent>;

    return (
      <Wrapper>
        <ChangeableImage
          style={{
            width: Constants.BoxSize,
            height: Constants.BoxSize,
            backgroundSize: 'cover',
            borderRadius: Constants.BoxRadius,
            border: '1px dashed'
          }}
          backgroundImage={asset.link}
          disableEditing
          showUrl
        />
      </Wrapper>
    );
  }
}
