import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';

const Wrapper = styled.form`
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem 3rem;
  max-width: 500px;
  h1 {
    text-align: center;
  }
  label {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  }
  input {
    margin-right: 8px;
  }
  h2 {
    margin-top: 2rem;
    font-size: 1rem;
  }
  code {
    font-size: 0.8rem;
  }
  details {
    margin-top: 2rem;
  }
  summary {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export default class EmbedCodePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    domainObject: PropTypes.object,
  }
  state = {
    autoresize: true,
    autoscroll: true,
    allowredirects: true,
    allowfullscreen: true,
    onlyscrollfullscreen: false,
    onclickshowfullscreen: false,
    showplaceholder: false
  }
  render() {
    const { autoresize, autoscroll, allowredirects, allowfullscreen, onlyscrollfullscreen, onclickshowfullscreen, showplaceholder } = this.state;
    const { onClose, domainObject } = this.props;
    const queryParams = `autoresize=${autoresize.toString()}&autoscroll=${autoscroll.toString()}&allowredirects=${allowredirects.toString()}&allowfullscreen=${allowfullscreen.toString()}&onlyscrollfullscreen=${onlyscrollfullscreen.toString()}&onclickshowfullscreen=${onclickshowfullscreen.toString()}&showplaceholder=${showplaceholder.toString()}`;
    const url = domainObject.url ? `https://${window.location.hostname}/${domainObject.url}?${queryParams}`
      : `https://${window.location.hostname}/apps/${domainObject._id}?apikey=YOUR_API_KEY&${queryParams}`;
    return (
      <div>
        <Popup
          onClose={onClose}
          allowBackgroundClose
          showCloseButton
        >
          <Wrapper>
            <h1>Embed App</h1>
            <h2>Embed Code</h2>
            <div>
              <code style={{ wordBreak: 'break-all' }}>{`<iframe style="border: 0; width: 100%; height: 100%;" src="${url}"></iframe><script src="https://${window.location.hostname}/js/connect/v1.js"></script>`}</code>
            </div>
            <details>
              <summary>&nbsp;&nbsp;Embed Settings</summary>
              <label>
                <input type="checkbox" checked={autoresize} onChange={() => this.setState({ autoresize: !autoresize })}></input>
                Auto resize content to fit size?
              </label>
              <label>
                <input type="checkbox" checked={autoscroll} onChange={() => this.setState({ autoscroll: !autoscroll })}></input>
                Auto scroll content into view?
              </label>
              <label>
                <input type="checkbox" checked={allowredirects} onChange={() => this.setState({ allowredirects: !allowredirects })}></input>
                Allow parent window redirects?
              </label>
              <label>
                <input type="checkbox" checked={allowfullscreen} onChange={() => this.setState({ allowfullscreen: !allowfullscreen })}></input>
                Allow the user to expand fullscreen?
              </label>
              <label>
                <input type="checkbox" checked={onlyscrollfullscreen} onChange={() => this.setState({ onlyscrollfullscreen: !onlyscrollfullscreen })}></input>
                Only allow scrolling when fullscreen?
              </label>
              <label>
                <input type="checkbox" checked={onclickshowfullscreen} onChange={() => this.setState({ onclickshowfullscreen: !onclickshowfullscreen })}></input>
                Open content fullscreen when clicked?
              </label>
              <label>
                <input type="checkbox" checked={showplaceholder} onChange={() => this.setState({ showplaceholder: !showplaceholder })}></input>
                Show placeholder image instead of content?
              </label>
            </details>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
