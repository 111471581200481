/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import UserProfileHeader from '../../components/UserProfileHeader';
import LogicBaseAppList from '../../components/LogicBaseAppList/index';
import { getUserAppSaves, getUserPublicApps, getUserPublicSubmissions } from '../../services/Pagination';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import shortenLikesCount from '../../utils/shortenLikesCount';

const Wrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  padding: 2rem 1rem;
`;
const Content = styled.div`
  margin-top: 2rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  > div {
    padding: 0rem 1rem;
    cursor: pointer;
    color: #666;
  }
`;
const AppLists = styled.div`
  ${Constants.StaticTextMaxWidth};
  width: 100%;
`;
const defaultContent = 'pins';
export default class LogicBaseUserPage extends React.PureComponent {
  state = {
    username: ((Navigation.currentLocation[1] === 'users' ? Navigation.currentLocation[2] : Navigation.currentLocation[1].slice(1)) || '').toLowerCase(),
    isAccount: UserAccount.isLoggedIn && Navigation.currentLocation[1] === 'account',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    LogicBaseRelationshipCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.userPublicApps) this.userPublicApps.offStateUpdate(this);
    if (this.userPublicSubmissions) this.userPublicSubmissions.offStateUpdate(this);
    if (this.userAppSaves) this.userAppSaves.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    LogicBaseRelationshipCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  onPagination = () => {
    const s = Navigation.currentSearch.content || defaultContent;
    const cache = s === 'posts' && this.userPublicApps || s ==='submissions' && this.userPublicSubmissions || s === 'pins' || this.userAppSaves;
    if (cache && cache.showMore && cache.allFound && cache.initialized) {
      cache.getMore();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { username, isAccount } = this.state;

    if (isAccount) {
      this.user = UserAccount.account;
    } else if (username) {
      const users = UserCache.getUsers({ username });
      this.user = users && (users.length === 0 ? { error: 'NotFound' } : users[0]);
    }
    if (!this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (this.user && !this.user.error && !this.userPublicApps) {
      this.userPublicApps = getUserPublicApps(this.user._id);
      this.userPublicApps.onStateUpdate(this);
      this.userPublicSubmissions = getUserPublicSubmissions(this.user._id);
      this.userPublicSubmissions.onStateUpdate(this);
      this.userAppSaves = getUserAppSaves(this.user._id);
      this.userAppSaves.onStateUpdate(this);
    }

    if (this.user && this.user.error) return <FullScreenVerticalCenterContent><h1>User Not Found</h1></FullScreenVerticalCenterContent>;
    if (!this.userPublicApps || !this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { isLoggedIn, account } = UserAccount;
    const publicUser = LogicBaseUserCache.get(this.user._id);
    const relationship = (isLoggedIn && LogicBaseRelationshipCache.query({ fr: UserAccount.account._id, fe: this.user._id }) || [])[0];
    if (!publicUser) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const answersArePrivate = !publicUser.socialSettings || !publicUser.socialSettings.a || publicUser.socialSettings.a === 'private';
    const answersAreSubscriberOnly = publicUser.socialSettings && publicUser.socialSettings.a === 'followers';
    const answersArePublic = !answersArePrivate && !answersAreSubscriberOnly;
    const isUserAccount = isLoggedIn && account._id === this.user._id;
    const showAnswers = isUserAccount || (answersAreSubscriberOnly && (relationship && !relationship.requested)) || answersArePublic;

    let apps;
    let allFound;
    // const showPosts = publicUser.apps > 0;
    // const showDecisions = !showPosts || publicUser.submissions > 0;
    const s = Navigation.currentSearch.content || defaultContent;
    let errorMessage = '';
    if (s === 'pins') {
      const saves = this.userAppSaves.currentPage;
      allFound = this.userAppSaves.allFound;
      if (saves) {
        apps = LogicBaseAppCache.getByIds(saves.map(({ app }) => app));
        if (apps) {
          allFound = true;
        }
      }
      errorMessage = 'No pins yet';
    } else if (s === 'submissions') {
      if (showAnswers) {
        apps = this.userPublicSubmissions.currentPage;
        allFound = this.userPublicSubmissions.allFound;
        errorMessage = 'No submissions yet';
      } else {
        apps = [];
        allFound = true;
        if (answersArePrivate) errorMessage = 'Their submissions are private';
        if (answersAreSubscriberOnly) errorMessage = 'You must follow them to see their submissions';
      }
    } else if (s === 'posts') {
      apps = this.userPublicApps.currentPage;
      allFound = this.userPublicApps.allFound;
      errorMessage = 'No posts yet';
    }
    const selectedStyle = {
      fontWeight: 500,
      color: 'black',
      borderRadius: '0.5rem'
    };
    const setSelected = (content) => {
      Navigation.replace(`${window.location.pathname}${content !== defaultContent ? `?content=${content}` : ''}`);
      this.forceUpdate();
    }
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: '1rem 0 2rem' }}
        rememberScrollPosition={`logicbaseuser_${this.user._id}`}
      >
        <Wrapper>
          <UserProfileHeader
            user={this.user}
          />
          <Content>
            <div style={s === 'pins' && selectedStyle || {}} onClick={() => setSelected('pins')}>Pins ({shortenLikesCount(publicUser.pins || 0)})</div>
            <div style={s === 'posts' && selectedStyle || {}} onClick={() => setSelected('posts')}>Posts ({shortenLikesCount(publicUser.apps || 0)})</div>
            {isAccount && <div style={s === 'submissions' && selectedStyle || {}} onClick={() => setSelected('submissions')}>Submissions ({shortenLikesCount(publicUser.submissions || 0)})</div>}
          </Content>
          {apps && allFound && (apps.length > 0 && (
            <AppLists>
              <LogicBaseAppList apps={apps} showUser listView={!mobile} />
            </AppLists>
          ) || (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>{errorMessage}</div>
          ))}
        </Wrapper>
      </Scrollable>
    );
  }
}
