import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getTrendingCollections } from '../../services/Pagination';
import Scrollable from '../../components/Scrollable';
import LogicBaseAppList from '../../components/LogicBaseAppList';

const UserProfile = styled.div`
  margin-top: 0;
  margin-bottom: 3rem;
  @media (max-width: 959px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  h1 {
    font-weight: 600;
    font-size: ${Constants.VeryLargeFontSize};
    margin: 0;
  }
  > div:first-child {
    margin-bottom: 4rem;
    // text-align: center;
  }
  > div:last-child {
    // max-width: 38rem;
    margin: auto;
    margin-bottom: 1rem;
  }
`;
const Search = styled.input`
  border: 1px solid #C1C1C1;
  border-radius: 1.5rem;
  overflow: hidden;
  line-height: 18px;
  padding: 1rem 3rem 1rem 1rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 52rem;
  display: block;
`;

export default class LogicBaseCollectionsPage extends React.PureComponent {
  constructor() {
    super();
    this.collections = getTrendingCollections('');
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    this.collections.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    this.collections.offStateUpdate(this);
    clearTimeout(this.__searchTimeout);
  }
  onPagination = () => {
    if (this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
      this.forceUpdate();
    }
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.runSearch();
    } else {
      clearTimeout(this.__searchTimeout);
      this.__searchTimeout = setTimeout(() => {
        this.runSearch();
      }, 500);
    }
  }
  runSearch = () => {
    this.searchText();
  }
  searchText = () => {
    const { searchInput } = this;
    this.textToSearch = searchInput.value.toLowerCase();
    this.forceUpdate();
  }
  render() {
    const { mobile } = WindowSize;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const collections = this.collections.currentPage;
    if (!collections || collections.length === 0) return loading;
    const search = this.textToSearch || '';
    const searchResults = search && LogicBaseCollectionCache.query({ search });
    const showCollections = search ? searchResults : collections || this.previousCollections;
    this.previousCollections = showCollections || this.previousCollections;
    return (
      <Scrollable
        vertical
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '0 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasecollections`}
      >
        <Search
          key="collectionssearch"
          onKeyDown={this.onKeyDown}
          ref={(e) => { this.searchInput = e; }}
          placeholder={"Search collections"}
          defaultValue={search}
        ></Search>
        <UserProfile>
          <div>
            <h1>Collections</h1>
            <p>Community-curated collections for the best resources, decision-making guides, and more…</p>
          </div>
        </UserProfile>
        <div>
          {showCollections && <LogicBaseAppList apps={showCollections} collection showUser />}
        </div>
      </Scrollable>
    );
  }
}
