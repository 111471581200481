import Service from './Service';
import Navigation from './Navigation';
import UserAccount from './UserAccount';
import Company from './Company';
import ApiKeyApi from './server/ApiKeyApi';
import EmailApi from './server/EmailApi';
import PaymentApi from './server/PaymentApi';
import SpreadsheetApi from './server/SpreadsheetApi';
import TreeApi from './server/TreeApi';
import UserApi from './server/UserApi';
import Server from './server';
import SidebarService from './SidebarService';
import TreeCache from './cache/TreeCache';
import TreeDisplay from './TreeDisplay';
import DatabaseCollectionDocumentApi from './server/DatabaseCollectionDocumentApi';
import Constants from '../submodules/logictry_config/constants';

const { isWidget, documentReferrer, apikey } = Constants;
const initialUrl = window.location.href;

function postMessageToParent(msg) {
  window.parent.postMessage(JSON.stringify(msg), '*');
}
class SubtreeUpdate extends Service {

}

export const subtreeUpdate = new SubtreeUpdate();
class Connect extends Service {
  scrollDisabled = Constants.isWidgetOnSameDomain;
  async incrementApiKeyCall() {
    const { currentLocation } = Navigation;
    if (!isWidget) return;
    const allowWithoutAPIKey = Constants.isLogicWiki && (currentLocation.length === 2 || (currentLocation.length === 3 && currentLocation[1] === 'collections'));
    const allowedEndWithHosts = ['logictry.com', 'localhost', 'worldlogicday.com'];
    const allowedExactMatchHosts = ['logictryuserfiles.s3.amazonaws.com', 'logictrystaginguserfiles.s3.amazonaws.com', 'logictrystagingcompanyfiles.s3.amazonaws.com', 'logictrydevuserfiles.s3.amazonaws.com', 'logictrydevcompanyfiles.s3.amazonaws.com', 'logictrycompanyfiles.s3.amazonaws.com'];
    const isWhiteListDomain = documentReferrer && (allowedEndWithHosts.find((h) => documentReferrer.endsWith(h)) || allowedExactMatchHosts.includes(documentReferrer));
    if (isWhiteListDomain || allowWithoutAPIKey) {
      this.loadSuccess = true;
    } else {
      const results = await ApiKeyApi.incrementApiKey(apikey, documentReferrer);
      if (results && results.success) this.loadSuccess = true;
      else this.loadSuccess = false;
    }
    this.emitStateUpdate();
  }
  setHeight(height) {
    if (!isWidget) return;
    if (height === this.lastHeightSent) return;
    this.lastHeightSent = height;
    postMessageToParent({
      src: initialUrl,
      height,
    });
  }
  setHeightFullscreen() {
    if (!isWidget) return;
    this.setHeight('fullscreen');
  }
  toggleFullscreen(fullscreen, placeholder) {
    if (!isWidget) return;
    this.lastHeightSent = null;
    postMessageToParent({
      src: initialUrl,
      toggleFullscreen: fullscreen,
      placeholder
    });
  }
  centerContent(center) {
    if (!isWidget) return;
    postMessageToParent({
      src: initialUrl,
      center,
    });
  }
  scrollTopContent() {
    if (!isWidget) return;
    postMessageToParent({
      src: initialUrl,
      scrollTopContent: true,
    });
  }
  redirect(url) {
    if (!isWidget) return;
    postMessageToParent({
      src: initialUrl,
      redirect: url,
    });
  }
  treeCacheUpdate(status, data) {
    if (!isWidget) return;
    postMessageToParent({
      src: initialUrl,
      status,
      data,
    });
  }
  setSelectType(selectType, saveOnShowReport) {
    if (!isWidget) return;
    postMessageToParent({
      src: initialUrl,
      selectType,
      saveOnShowReport
    });
  }
  sendIframeMessage(msg) {
    if (this.__iframe && this.__iframe.contentWindow && this.__iframe.contentWindow.postMessage) {
      this.__iframe.contentWindow.postMessage(JSON.stringify(msg), '*');
    }
  }
  toggleScrollDisabled(disable) {
    this.scrollDisabled = disable;
    this.emitStateUpdate();
  }
}

const singleton = new Connect();
if (isWidget) {
  TreeCache.onCreate = () => singleton.treeCacheUpdate('onCreate');
  TreeCache.onCreateSuccess = (tree) => {
    if (tree.type === 'Project') Navigation.replace(`${Navigation.trees}/${tree._id}${window.location.search}`);
    singleton.treeCacheUpdate('onCreateSuccess', tree);
  }
  TreeCache.onCreateFail = (error) => singleton.treeCacheUpdate('onCreateFail', error);
  TreeCache.onUpdate = () => singleton.treeCacheUpdate('onUpdate');
  TreeCache.onUpdateSuccess = () => singleton.treeCacheUpdate('onUpdateSuccess');
  TreeCache.onUpdateFail = (error) => singleton.treeCacheUpdate('onUpdateFail', error);
  TreeCache.onDelete = () => singleton.treeCacheUpdate('onDelete');
  TreeCache.onDeleteSuccess = () => singleton.treeCacheUpdate('onDeleteSuccess');
  TreeCache.onDeleteFail = (error) => singleton.treeCacheUpdate('onDeleteFail', error);
}
const handleMessage = (evt) => {
  let data;
  try {
    data = JSON.parse(evt.data);
  } catch(e) {
    //
  }
  if (!data) return null;
  if (!data.parentWebsite && data.src === initialUrl) return null;

  if (data.innerHeight) singleton.innerHeight = data.innerHeight;
  if (data.innerWidth) singleton.innerWidth = data.innerWidth;
  if (data.website) {
    const { currentSearch } = Navigation;
    return singleton.toggleScrollDisabled(data.fullscreen === 'false' && currentSearch.autoresize !== 'false');
  }
  if (data.fullscreen === 'true' || data.fullscreen === 'false') {
    singleton.emitStateUpdate();
  }
  if (data.toggleFullscreen) {
    subtreeUpdate.emitStateUpdate(data);
    return singleton.toggleFullscreen(data.toggleFullscreen);
  }
  if (data.height) return subtreeUpdate.emitStateUpdate(data);
  if (data.selectType) {
    if (Constants.isApp || data.force) return SidebarService.updateSelectType(data.selectType, data.saveOnShowReport);
  }
  if (data.status) return singleton.onTreeCacheUpdate && singleton.onTreeCacheUpdate(data.status, data.data);
  if (data.request) {
    if (data.request.action === 'GetAccount') {
      const { account, isLoggedIn } = UserAccount;
      return singleton.sendIframeMessage({ ...data, response: { body: isLoggedIn ? account : {} } });
    }
    if (data.request.action === 'GetConfig') {
      const { tree } = TreeDisplay;
      return singleton.sendIframeMessage({ ...data, response: { body: (tree && tree.customSource && tree.customSource.config) || {} } });
    }
    if (data.request.action === 'NetworkRequest') {
      if (!data.request.path) return singleton.sendIframeMessage({ ...data, response: { body: { error: 'Missing path' } } });
      const urlParts = data.request.path.split('?');
      const pathParts = urlParts[0].split('/').filter((s) => s);
      const params = {
        collection: pathParts[2],
      };
      if (pathParts[4]) params.document = pathParts[4];
      const query = {};
      if (urlParts[1]) {
        urlParts[1].split('&').forEach((pair) => {
          const keyVals = pair.split('=');
          query[keyVals[0]] = keyVals[1];
        });
      }
      const isAccountApi = data.request.path.startsWith('/v1/auth');
      const isEmailApi = data.request.path.startsWith('/v1/emails');
      const isPaymentApi = data.request.path.startsWith('/v1/payments');
      const isSpreadsheetApi = data.request.path.startsWith('/v1/spreadsheets');
      const isAppApi = data.request.path.startsWith('/v1/apps');
      const isUserApi = data.request.path.startsWith('/v1/users');
      if (data.request.method === 'GET') {
        if (isUserApi) {
          if (query.ids) return UserApi.getUsers(query.ids.split(',')).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isSpreadsheetApi) {
          return SpreadsheetApi.get(query.id).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isAppApi) {
          if (data.request.path.startsWith('/v1/apps/count')) {
            return TreeApi.getCount({ ...query }).then((body) => {
              return singleton.sendIframeMessage({ ...data, response: { body } });
            });
          }
          if (query.id) {
            return TreeApi.getTree(query.id).then((body) => {
              return singleton.sendIframeMessage({ ...data, response: { body } });
            });
          }
          return TreeApi.getAccountTrees({ ...query }).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isPaymentApi) {
          return PaymentApi.query({ ...query }).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (params && params.collection) {
          return DatabaseCollectionDocumentApi.query(query, params, true).then((response) => {
            singleton.sendIframeMessage({ ...data, response })
          });
        }
      }
      if (data.request.method === 'DELETE') {
        if (!params.document) return singleton.sendIframeMessage({ ...data, response: { body: { error: 'Missing document param' } } });
        if (params && params.collection) return DatabaseCollectionDocumentApi.delete(params, null, null, true).then((response) => singleton.sendIframeMessage({ ...data, response }));
      }
      if (!data.request.body) return singleton.sendIframeMessage({ ...data, response: { body: { error: 'Missing body' } } });
      if (data.request.method === 'POST') {
        if (isAccountApi) {
          return Server.registerAccount(data.request.body).then((user) => {
            const body = user && user._id && { _id: user._id, email: user.email } || user.error && { error: user.error } || {};
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isEmailApi) {
          return EmailApi.create(data.request.body).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isPaymentApi) {
          return PaymentApi.create(data.request.body).then((body) => {
            return singleton.sendIframeMessage({ ...data, response: { body } });
          });
        }
        if (isAppApi) {
          const { _id, text } = data.request.body;
          if (_id) {
            return TreeApi.getTree(_id).then((template) => {
              if (!template || !template._id) return singleton.sendIframeMessage({ ...data, response: { body: { error: 'Not Found' } } });
              return TreeApi.createTree({ type: template.type, owner: UserAccount.account._id, company: Company.company && Company.company._id || '', userLoggedIn: UserAccount.account._id, children: template.children, shareSetting: template.shareSetting }).then((body) => {
                return singleton.sendIframeMessage({ ...data, response: { body } });
              });
            });
          } else if (text) {
            return TreeApi.createTree({ type: 'Template', owner: UserAccount.account._id, company: Company.company && Company.company._id || '', userLoggedIn: UserAccount.account._id, children: { text }, shareSetting: 'LinkView' }).then((body) => {
              return singleton.sendIframeMessage({ ...data, response: { body } });
            });
          }
        }
        if (params && params.collection) return DatabaseCollectionDocumentApi.create(data.request.body, query, params, true).then((response) => singleton.sendIframeMessage({ ...data, response }));
      }
      if (data.request.method === 'PATCH') {
        if (params && params.collection) return DatabaseCollectionDocumentApi.update(data.request.body, query, params, true).then((response) => singleton.sendIframeMessage({ ...data, response }));
        if (isAppApi && data.request.body._id) {
          if (data.request.body.state === 'Deleted') {
            return TreeApi.deleteTree(data.request.body._id).then((body) => {
              return singleton.sendIframeMessage({ ...data, response: { body } });
            });
          } else {
            return TreeApi.getTree(data.request.body._id).then((body) => {
              const tree = TreeCache.createFromJson({ editing: true, ...body });
              tree.updateChildrenJsonOrText(data.request.body.children);
              TreeCache.updateTree(tree);
              return singleton.sendIframeMessage({ ...data, response: { body: { success: true } } });
            });
          }
        }
      }
      return singleton.sendIframeMessage({ ...data, response: { body: { error: 'Invalid Request' } } });
    }
    if (data.request.action === 'LocationUpdate') {
      if (data.request.location) Navigation.replace(`${window.location.pathname}${window.location.search}#${data.request.location}`);
      return singleton.sendIframeMessage({ ...data, response: { body: { location: window.location.href } } });
    }
  }
  return null;
}
if (window.parent) {
  postMessageToParent({
    src: initialUrl,
  });
}
if (window.addEventListener) {
  window.addEventListener("message", handleMessage, false);
} else {
  window.attachEvent("onmessage", handleMessage);
}
export default singleton;
