import React from "react";
import styled from "styled-components";
import ProgressBar from '../../components/ProgressBar';
import Constants from '../../submodules/logictry_config/constants';
import OpenAI from '../../services/OpenAI';
import ScrollIt from "../../utils/Scroll";

const Wrapper = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: white;
  > div:first-child {
    width: 100%;
    max-width: 320px;
    text-align: center;
    margin: 1rem;
    flex-shrink: 0;
  }
  > div:last-child {
    * {
      font-family: monospace !Important;
    }
    width: calc(100% + 20px);
    overflow: auto;
    margin-right: -20px;
    padding-right: 20px;
    > h1 {
      padding: 1rem 2rem;
    }
    > div {
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 1rem 2rem;
      padding-bottom: 120px;
    }
    flex: 1;
  }
`;
const Text = styled.div`
  transition: 1s all;
  position: absolute;
  inset: 0;
  text-align: center;
`;
const Texts = [
  'Logic Bot is working hard for you!',
  'Logic Bot is coming up with questions!',
  'Better questions help you get better answers!',
  'This is prompt engineering at its best!',
  'Get ready to make the best decision!',
  'Almost ready!',
  'Just putting the last finishing touches!',
  `Don't give up on Logic Bot!`,
  'Almost there!',
  'Almost there!',
  'Almost there!',
];

// https://giphy.com/gifs/workhard-frenchbulldog-metalfrenchie-XbQesBg4di7St1NbqY
const LOGIC_BOT = "https://logictrystatic.s3.amazonaws.com/images/logicbot_danger_will_robinson.webp";
// const LOGIC_BOT = "https://logictrystatic.s3.amazonaws.com/images/logicbot_barbie_typing.gif"
// const LOGIC_BOT = "https://logictrystatic.s3.amazonaws.com/images/logicbot__barbie.gif"
// const LOGIC_BOT = "https://logictrystatic.s3.amazonaws.com/images/logicbot__frenchie_typing.gif"
// const LOGIC_BOT = "https://logictrystatic.s3.amazonaws.com/images/logicbot_barbie_typing2.gif"

class RobotsAreBusy extends React.PureComponent {
  state = {
    progress: 0,
    reponse: '',
    letters: 0,
  }
  componentDidMount() {
    this.updateProgressInterval = setInterval(this.updateProgress, 500);
    OpenAI.onStateUpdate(this.openAIUpdate);
    this.textUpdateInterval = setInterval(this.textUpdate, 40);
  }
  componentWillUnmount() {
    clearInterval(this.updateProgressInterval);
    OpenAI.offStateUpdate(this.openAIUpdate);
    clearInterval(this.textUpdateInterval);
  }
  openAIUpdate = () => {
    if (!OpenAI.openAIState) return;
    const { response } = OpenAI.openAIState;
    const testResult = response.split('}').slice(1).join('}');
    let question = '';
    try {
      question = testResult.split('"d":')[0].split('"sq": "')[1].split('",')[0];
    } catch(e) {
      //
    }
    let description = '';
    try {
      description = testResult.split('"ts":')[0].split('"d": "')[1].split('",')[0];
    } catch(e) {
      //
    }
    // let topics = '';
    // try {
    //   topics = testResult.split('"cq":')[0].split('"ts": [')[1].split('],')[0];
    // } catch(e) {
    //   //
    // }
    let clarifyQuestions = [];
    try {
      testResult.split('"cq": ')[1].split('{').forEach((cq, i) => {
        if (i === 0) return;
        try {
          const clarifyQuestion = cq.split('}')[0];
          let question = '';
          let answers = '';
          try {
            question = clarifyQuestion.split('"q": "')[1].split('",')[0];
          } catch (e) {
            //
          }
          try {
            answers = clarifyQuestion.split('"a": [')[1].split('],')[0];
          } catch (e) {
            //
          }
          clarifyQuestions.push(`${i}. ${question}\n${answers.split('",').map((a) => ` - ${a.replaceAll('"','').trim()}`).join('\n')}`);
        } catch(e) {
          //
        }
      });
    } catch(e) {
      //
    }
    let answerText;
    try {
      answerText = testResult.split('"a": "')[1].split('"')[0];
    } catch(e) {
      //
    }
    let text = `Great question!\n\n${question}`;
    if (description) text += `\n\n${description}`;
    if (clarifyQuestions.length > 0) text += `\n\n${clarifyQuestions.join('\n\n')}`;
    if (answerText) text += `\n\n${answerText}`;
    this.openAIText = text;
  }
  textUpdate = () => {
    if (!this.openAIText) return;
    let { letters } = this.state;
    if (this.openAIText.length > letters) letters += 1;
    this.setState({ response: this.openAIText.slice(0, letters), letters });
  }
  updateProgress = () => {
    this.setState({ progress: this.state.progress + 1 });
  }
  render() {
    const { response } = this.state;
    if (this.wrapperRef) ScrollIt(999999999, this.wrapperRef);
    return (
      <Wrapper>
        <div>
          <img alt="Fred the Logic Bot" style={{ width: '100%' }} src={LOGIC_BOT}></img>
        </div>
        <div ref={(e) => { this.wrapperRef = e; }}>
          <h1 style={{ textAlign: 'center' }}>Logic Bot will answer you!</h1>
          <br></br>
          <div
            style={{ whiteSpace: 'pre-wrap' }}
          >{response}</div>
        </div>
      </Wrapper>
    );
  }
};
export default RobotsAreBusy;
