/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Chatbot from '../../services/Chatbot';
import TreeDisplay from '../../services/TreeDisplay';
import CreateChildren from './CreateChildren';
import { TEXT } from '../../models/nodetypes';
import Answerable from '../../models/answerable';
import ChatbotModel from '../../models/chatbot';

const Wrapper = styled.div`

`;
export default class ChatbotBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  constructor() {
    super();
    Chatbot.initialize();
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    TreeDisplay.onStateUpdate(this.displayUpdate);
    TreeDisplay.addActiveObject(node);
    this.goToNextQuestionAndIncludeChain();
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    TreeDisplay.offStateUpdate(this.displayUpdate);
    clearTimeout(this.goToNextTimeout);
  }
  goToNextQuestionAndIncludeChain() {
    const chain = TreeDisplay.goToNextQuestion(null, true);
    chain.reverse();
    const nextNonTextIndex = chain.findIndex((node, i) => {
      if (i === 0) return false;
      return !(node.isType(TEXT))
    });
    chain.reverse();
    if (nextNonTextIndex > 1) chain.splice(chain.length - nextNonTextIndex);
    else if (nextNonTextIndex === -1) chain.splice(1);
    chain.forEach((node) => {
      const objectToAdd = (node instanceof Answerable) ? node.parent : node;
      Chatbot.addToChain(objectToAdd)
    });
    const nextActiveObject = chain.slice(-1)[0];
    if (nextActiveObject && (nextNonTextIndex > 1 || nextNonTextIndex === -1)) TreeDisplay.addActiveObject(nextActiveObject);
  }
  displayUpdate = () => {
    const { node } = this.props;
    const { updateRate } = node;
    const { activeObject } = TreeDisplay;
    if (!activeObject) return;
    if (activeObject instanceof ChatbotModel) return;

    // If already in the chain
    let objectToAdd = (activeObject instanceof Answerable) ? activeObject.parent : activeObject;
    const isInCurrentChain = Chatbot.chainIncludes(objectToAdd);
    if (isInCurrentChain) Chatbot.splitChain(objectToAdd);
    else Chatbot.addToChain(objectToAdd);
    clearTimeout(this.goToNextTimeout);

    // If answerable this means that the user is entering something and it should not contine
    if (activeObject instanceof Answerable) return;
    const { answersChecked } = objectToAdd;
    const isText = activeObject.isType(TEXT);
    if (!(isText || answersChecked)) return;
    this.goToNextTimeout = setTimeout(() => {
      if (activeObject !== TreeDisplay.activeObject) return;
      const { answersChecked } = objectToAdd;
      const questionAnswered = !!(answersChecked && answersChecked.length > 0 && !objectToAdd.multipleAnswers);
      if (isText || questionAnswered) TreeDisplay.goToNextQuestion();
      this.goToNextTimeout = null;
    }, updateRate);
  }
  render() {
    const { node } = this.props;
    const { key } = node;
    return (
      <Wrapper key={key}>
        <div>
          <CreateChildren node={node} />
        </div>
      </Wrapper>
    );
  }
}
