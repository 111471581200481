import Model from './model';

export default class LogicBaseApp extends Model {
  constructor(_id, createdTime, owner, title, description, image, topics, url, likes, views, address, geo, saves, color, searchIndex) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.owner = owner;
    this.title = title;
    this.description = description;
    this.image = image;
    this.topics = topics;
    this.url = url;
    this.likes = likes;
    this.views = views;
    this.address = address;
    this.geo = geo;
    this.saves = saves;
    this.color = color;
    this.searchIndex = searchIndex;
  }
  getJson = () => {
    const { _id, owner, title, description, image, topics, url, likes, views, address, geo, saves, color, searchIndex } = this;
    return {
      _id,
      o: owner,
      t: title,
      d: description,
      i: image,
      ts: topics,
      u: url,
      l: likes,
      v: views,
      a: address,
      g: geo,
      s: saves,
      c: color,
      si: searchIndex
    }
  }
}
