/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import TreeCache from '../../services/cache/TreeCache';
import GoogleDriveCache from '../../services/cache/GoogleDriveCache';
import TreeEditBox from './TreeEditBox';
import SelectMenu from '../../components/SelectMenu/index';
import FileGroup from '../../models/filegroup';
import TreeCreateNode from '../../services/TreeCreateNode';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';

import SidebarService from '../../services/SidebarService';
import ClickableIcon from '../../components/ClickableIcon';
import { SINGLE_ANSWER_ATTR, FIXED_ATTR, OPEN_ENDED_ATTR, MULTIPLE_ANSWERS_ATTR, NONE_ATTR, CARD_ATTR, HORIZONTAL_ATTR, VERTICAL_ATTR, CONTENT_HEIGHT_ATTR, COLLAPSIBLE_ATTR } from '../../models/nodeattributes';
import { TextVisbilityOptions } from '../../models/node';
import Filter, { SelectionOptions } from '../../models/filter';
import Answerable from '../../models/answerable';
import Questionable from '../../models/questionable';
import Category from '../../models/category';
import Data from '../../models/data';
import SplitPane from '../../models/splitpane';
import Pane from '../../models/pane';
import VersioningList from '../../components/VersioningList';
import Autofill from '../../models/autofill';
import Wiki from '../../models/wiki';
import Tooltip from '../../components/Tooltip/index';
import DataSchemaField from '../../models/dataschemafield';
import ConditionalOperator from '../../models/conditionaloperator';
import CalculationOperator from '../../models/calculationoperator';
import Calculation from '../../models/calculation';
import SearchLogic from '../../models/searchlogic';
import Search from '../../models/search';
import Root from '../../models/root';
import AutoCreateLogic from '../../models/autocreatelogic';
import SocialShare from '../../components/SocialShare/index';
import DataSchema from '../../models/dataschema';
import DataSchemaKey from '../../models/dataschemakey';
import DataSchemaValue from '../../models/dataschemavalue';
import TemplateTreeSettings from '../../components/ObjectSettings/TemplateTreeSettings';
import ProjectTreeSettings from '../../components/ObjectSettings/ProjectTreeSettings';
import Text from '../../models/text';
import Scrollable from '../../components/Scrollable/index';
import { FONTS } from '../../utils/fonts';
import Report from '../../models/report';

const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  font-size: ${Constants.SemiLargeFontSize};
  * {
    font-size: ${Constants.SemiSmallFontSize};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // border-top-left-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.4);
  // filter: saturate(0.3) brightness(1.6);
  background-color: ${Constants.White};
  h3 {
    text-align: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const AnswerButtons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Divider = styled.div`
  width: 100%;
  background-color: #5a5959;
  * {
    color: ${Constants.White};
  }
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
`;
const SelectMenus = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  > * {
    margin: 0px 8px 0px 4px;
  }
  select {
    width: 100%;
    padding-left: 20px;
  }
  input::placeholder {
    color: rgba(255,255,255,0.3);
  }
`;
const AccessStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 5px 20px;
  > i {
    margin-right: 10px;
  }
`;

const LockedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 5px 0px;
  > i {
    margin-right: 10px;
  }
`;
const SelectNumber = styled.div`
  display: flex;
  > div: first-child {
    white-space: nowrap;
  }
  * {
    padding: 9.5px 6px;
  }
  input {
    padding-left: 20px;
    width: 100%;
  }
`;
const SelectButton = styled.div`
  text-decoration: underline;
  padding: 9.5px 6px;
`;

let showSettings = true;
let showBasicTypes = true;
let showAdvancedTypes = false;

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    saveChanges: PropTypes.func,
    checkout: PropTypes.func,
    checkin: PropTypes.func,
    showEditor: PropTypes.bool,
    showMini: PropTypes.bool,
  };
  componentDidMount() {
    SidebarService.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    TreeCreateNode.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    const { tree } = this.props;
    tree.onStateUpdate(this);
    this.__updateAutoCreateCallback = {};
  }
  componentWillUnmount() {
    SidebarService.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    TreeCreateNode.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    const { tree } = this.props;
    tree.offStateUpdate(this);
    Object.keys(this.__updateAutoCreateCallback).forEach(this.removeUpdateAutoCreateCallback);
  }
  getFormButton(selectType) {
    return <div key="tasks"><Tooltip text="View questions"><ClickableIcon disabled={selectType === 'Tree'} onClick={() => SidebarService.updateSelectType('Tree')} icon="fas fa-tasks" /></Tooltip></div>;
  }
  getPdfButton(selectType) {
    return <div key="word">
      <Tooltip text="View report"><ClickableIcon disabled={selectType === 'Text'} onClick={() => SidebarService.updateSelectType('Text')} icon="fas fa-file-pdf" /></Tooltip>
    </div>;
  }
  getChatButton(selectType) {
    return <div key="chat">
      <Tooltip text="View chat interface"><ClickableIcon disabled={selectType === 'Chat'} onClick={() => SidebarService.updateSelectType('Chat')} icon="fas fa-comment" /></Tooltip>
    </div>;
  }
  getTextVisibilitySetting = (activeObject) => {
    const { textVisibilitySetting } = activeObject;
    return <SelectMenu value={textVisibilitySetting} onChange={(value) => { this.changeValue(activeObject, 'textVisibility', value); }} options={TextVisbilityOptions} />;
  }
  getSelectionSetting = (activeObject) => {
    if (!(activeObject instanceof Filter)) return null;
    const { selectionType } = activeObject;
    return <SelectMenu value={selectionType} onChange={(value) => { this.changeValue(activeObject, 'selectionType', value); }} options={SelectionOptions} />;
  }
  getActiveObjectSettings = (activeObject) => {
    const { editing } = GrayOut;
    if (!editing) return null;
    if (activeObject instanceof Questionable) {
      const { choicesVisibilityOption, choicesVisibilityOptions, userInputType, answerChoiceType, multipleAnswers, userInputTypesOptions, answerQuantityOptions, answerChoiceTypeOptions, showAnswerInputFormatOptions, userInputFormat, userInputFormatOptions, sortOption, sortOptions, horizontal, directionOptions, wrapOption, wrapOptions, showFilteredOption, showFilteredOptions, requiredOption, requiredOptions, showChildrenAsOption, showChildrenAsOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={userInputType} onChange={(value) => { this.changeValue(activeObject, 'answerInputType', value); }} options={userInputTypesOptions} />
          {this.getTextVisibilitySetting(activeObject)}
          {showFilteredOption && showFilteredOptions && <SelectMenu value={showFilteredOption} onChange={(value) => { activeObject.showFilteredOption = value; }} options={showFilteredOptions} />}
          {this.getSelectionSetting(activeObject)}
          <SelectMenu value={multipleAnswers ? MULTIPLE_ANSWERS_ATTR : SINGLE_ANSWER_ATTR} onChange={(value) => { this.changeValue(activeObject, 'answerQuantity', value); }} options={answerQuantityOptions} />
          <SelectMenu value={answerChoiceType} onChange={(value) => { this.changeValue(activeObject, 'answerChoices', value); }} options={answerChoiceTypeOptions} />
          {showAnswerInputFormatOptions && <SelectMenu value={userInputFormat} onChange={(value) => { this.changeValue(activeObject, 'userInputFormat', value); }} options={userInputFormatOptions} />}
          <SelectMenu value={sortOption} onChange={(value) => { activeObject.sortOption = value; }} options={sortOptions} />
          <SelectMenu value={horizontal ? HORIZONTAL_ATTR : VERTICAL_ATTR} onChange={(value) => { activeObject.updateDirectionOption(value); }} options={directionOptions} />
          <SelectMenu value={wrapOption} onChange={(value) => { activeObject.wrapOption = value; }} options={wrapOptions} />
          <SelectMenu value={requiredOption} onChange={(value) => { activeObject.requiredOption = value; }} options={requiredOptions} />
          <SelectMenu value={choicesVisibilityOption} onChange={(value) => { activeObject.choicesVisibilityOption = value; }} options={choicesVisibilityOptions} />
          <SelectMenu value={showChildrenAsOption} onChange={(value) => { activeObject.showChildrenAsOption = value; }} options={showChildrenAsOptions} />
          {/* <SelectNumber key={activeObject.key}><div>Default Value:</div>
            <input
              defaultValue={defaultValue}
              onBlur={(e) => activeObject.updateDefaultValue(e.target.value)}
              onKeyUp={(e) => activeObject.updateDefaultValue(e.target.value)}
              onChange={(e) => activeObject.updateDefaultValue(e.target.value)}
            />
          </SelectNumber> */}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Root) {
      const { saveOnSubmit, maxWidth, backgroundColor, backgroundImage, primaryColorSetting, fontFamily } = activeObject;
      return (
        <SelectMenus>
          <SelectNumber key={maxWidth}><div>Max Width:</div><input
            defaultValue={maxWidth}
            onBlur={(e) => activeObject.updateMaxWidth(e.target.value)}
            type="text"
            placeholder="100%"
          ></input></SelectNumber>
          <SelectNumber key={saveOnSubmit}><div>Auto Save:</div><input
            defaultValue={saveOnSubmit}
            onBlur={(e) => activeObject.updateSaveOnSubmit(e.target.value)}
            type="text"
            placeholder="Node"
          ></input></SelectNumber>
          <SelectNumber key={backgroundColor}>
            <div>Background Color:</div>
            <input
              style={{ width: 60, height: 40 }}
              defaultValue={backgroundColor}
              onBlur={(e) => activeObject.updateBackgroundColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={backgroundImage}>
            <div>Background Image:</div>
            <input
              defaultValue={backgroundImage}
              onBlur={(e) => activeObject.updateBackgroundImage(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={primaryColorSetting}>
            <div>Primary Color:</div>
            <input
              style={{ width: 60, height: 40 }}
              defaultValue={primaryColorSetting}
              onBlur={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={fontFamily}>
            <div>Font Style:</div>
            <select defaultValue={fontFamily || 'Montserrat'} onChange={(e) => { activeObject.fontFamily = e.target.value; }}>
              {FONTS.map((font) => (
                <option key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </option>
              ))}
            </select>
          </SelectNumber>
        </SelectMenus>
      );
    }
    if (activeObject instanceof Report) {
      const { backgroundColor, backgroundImage, primaryColorSetting, fontFamily } = activeObject;
      return (
        <SelectMenus>
          <SelectNumber key={backgroundColor}>
            <div>Background Color:</div>
            <input
              style={{ width: 60, height: 40 }}
              defaultValue={backgroundColor}
              onBlur={(e) => activeObject.updateBackgroundColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={backgroundImage}>
            <div>Background Image:</div>
            <input
              defaultValue={backgroundImage}
              onBlur={(e) => activeObject.updateBackgroundImage(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={primaryColorSetting}>
            <div>Primary Color:</div>
            <input
              style={{ width: 60, height: 40 }}
              defaultValue={primaryColorSetting}
              onBlur={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber key={fontFamily}>
            <div>Font Style:</div>
            <select defaultValue={fontFamily || 'Montserrat'} onChange={(e) => { activeObject.fontFamily = e.target.value; }}>
              {FONTS.map((font) => (
                <option key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </option>
              ))}
            </select>
          </SelectNumber>
        </SelectMenus>
      );
    }
    if (activeObject instanceof AutoCreateLogic) {
      const { dataSourceOption, dataSourceOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={dataSourceOption} onChange={(value) => { activeObject.dataSourceOption = value; }} options={dataSourceOptions} />
          <SelectButton onClick={() => this.updateAutoCreate(activeObject)}>{this.__updateAutoCreateCallback[activeObject.key] ? 'Updating...' : 'Update'}</SelectButton>
        </SelectMenus>
      );
    }
    if (activeObject instanceof SearchLogic) {
      const { searchTypeOption, searchTypeOptions, location, threshold, distance, minMatchCharLength } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={searchTypeOption} onChange={(value) => { activeObject.searchTypeOption = value; }} options={searchTypeOptions} />
          {this.getSelectNumber('Location:', location, (val) => activeObject.updateLocation(val), '0', '100')}
          {this.getSelectNumber('Threshold:', threshold, (val) => activeObject.updateThreshold(val), '0', '1')}
          {this.getSelectNumber('Distance:', distance, (val) => activeObject.updateDistance(val), '0', '1000')}
          {this.getSelectNumber('MinCharMatchLength:', minMatchCharLength, (val) => activeObject.updateMinCharMatchLength(val), '0', '30')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Search) {
      const { inputTypeOption, inputTypeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={inputTypeOption} onChange={(value) => { activeObject.inputTypeOption = value; }} options={inputTypeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof ConditionalOperator || activeObject instanceof CalculationOperator) {
      const { typeOption, typeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={typeOption} onChange={(value) => { activeObject.typeOption = value; }} options={typeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchema) {
      const { formatOption, formatOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={formatOption} onChange={(value) => { activeObject.formatOption = value; }} options={formatOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaField) {
      const { sortOption, sortOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={sortOption} onChange={(value) => { activeObject.sortOption = value; }} options={sortOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaKey) {
      const { layoutOption, layoutOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={layoutOption} onChange={(value) => { activeObject.layoutOption = value; }} options={layoutOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaValue) {
      const { typeOption, typeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={typeOption} onChange={(value) => { activeObject.typeOption = value; }} options={typeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Answerable) {
      const { preselectOption, preselectOptions, inputTypeOption, inputTypeOptions } = activeObject;
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
          <SelectMenu value={preselectOption} onChange={(value) => { activeObject.preselectOption = value; }} options={preselectOptions} />
          {inputTypeOption && inputTypeOptions && <SelectMenu value={inputTypeOption} onChange={(value) => { activeObject.inputTypeOption = value; }} options={inputTypeOptions} />}
        </SelectMenus>
      );
    }
    if (activeObject instanceof FileGroup) {
      const { openEnded, selectOption, requiredOption, requiredOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={openEnded ? OPEN_ENDED_ATTR : FIXED_ATTR} onChange={(value) => { activeObject.updateAnswerChoiceType(value); }} options={selectOption} />
          <SelectMenu value={requiredOption} onChange={(value) => { activeObject.requiredOption = value; }} options={requiredOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Category) {
      const { card, displayOptions, defaultCategorySetting, expandedOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={card ? CARD_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateDisplayOption(value); }} options={displayOptions} />
          {this.getTextVisibilitySetting(activeObject)}
          <SelectMenu value={defaultCategorySetting} onChange={(value) => { activeObject.updateExpandedOption(value); }} options={expandedOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Calculation) {
      const { decimals } = activeObject;
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
          {this.getSelectNumber('Decimals:', decimals, (val) => activeObject.updateDecimals(val), '0', '10')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Autofill) {
      const { readWriteOption, readWriteOptions, delimiter, delimiterOptions, dataSource, dataSourceOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={delimiter} onChange={(value) => { activeObject.updateDelimiterOption(value); }} options={delimiterOptions} />
          <SelectMenu value={dataSource} onChange={(value) => { activeObject.updateDataSourceOption(value); }} options={dataSourceOptions} />
          <SelectMenu value={readWriteOption} onChange={(value) => { activeObject.readWriteOption = value; }} options={readWriteOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Data) {
      const { delimiter, delimiterOptions, dataSource, dataSourceOptions, readWriteOption, readWriteOptions, emptyCellsOption, emptyCellsOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={delimiter} onChange={(value) => { activeObject.updateDelimiterOption(value); }} options={delimiterOptions} />
          <SelectMenu value={dataSource} onChange={(value) => { activeObject.updateDataSourceOption(value); }} options={dataSourceOptions} />
          <SelectMenu value={readWriteOption} onChange={(value) => { activeObject.readWriteOption = value; }} options={readWriteOptions} />
          <SelectMenu value={emptyCellsOption} onChange={(value) => { activeObject.emptyCellsOption = value; }} options={emptyCellsOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof SplitPane) {
      const { horizontal, directionOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={horizontal ? HORIZONTAL_ATTR : VERTICAL_ATTR} onChange={(value) => { activeObject.updateDirectionOption(value); }} options={directionOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Pane) {
      const { contentHeight, sizingOptions, collapsible, resizingOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={contentHeight ? CONTENT_HEIGHT_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateSizingOption(value); }} options={sizingOptions} />
          <SelectMenu value={collapsible ? COLLAPSIBLE_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateResizingOption(value); }} options={resizingOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Wiki) {
      const { maxDepth, contentFormatOption, contentFormatOptions, showProgressOption, showProgressOptions, navButtonsOption, navButtonsOptions, pageTitlesOption, pageTitlesOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu value={contentFormatOption} onChange={(value) => { activeObject.contentFormatOption = value; }} options={contentFormatOptions} />
          <SelectMenu value={showProgressOption} onChange={(value) => { activeObject.showProgressOption = value; }} options={showProgressOptions} />
          <SelectMenu value={navButtonsOption} onChange={(value) => { activeObject.navButtonsOption = value; }} options={navButtonsOptions} />
          <SelectMenu value={pageTitlesOption} onChange={(value) => { activeObject.pageTitlesOption = value; }} options={pageTitlesOptions} />
          {this.getSelectNumber('Max Depth:', maxDepth, (val) => activeObject.updateMaxDepth(val), '1', '3')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Text) {
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
        </SelectMenus>
      )
    }
    return null;
  }
  getSelectNumber(text, defaultValue, onUpdate, min, max) {
    return (
      <SelectNumber key={`${text}`}><div>{text}</div><input
        defaultValue={defaultValue}
        onChange={(e) => onUpdate(e.target.value)}
        onKeyUp={(e) => onUpdate(e.target.value)}
        onBlur={(e) => onUpdate(e.target.value)}
        type="number"
        min={min}
        max={max}
      ></input></SelectNumber>
    )
  }
  getAnswerButtons = () => {
    const { selectType } = SidebarService;
    const { tree } = this.props;
    const { project } = tree;
    const answerButtons = [];
    answerButtons.push(<div key="arrow_up"><Tooltip text="Scroll to top"><ClickableIcon disabled={selectType !== 'Tree'} onClick={this.scrollTop} icon="fa fa-arrow-up" /></Tooltip></div>);
    if (!project) answerButtons.push(<div key="edit"><Tooltip text="Edit"><ClickableIcon onClick={() => Navigation.push(`/apps/${tree._id}/edit`)} icon="fas fa-edit" /></Tooltip></div>);
    answerButtons.push(this.getFormButton(selectType));
    answerButtons.push(this.getPdfButton(selectType));
    if (!project) answerButtons.push(this.getChatButton(selectType));
    answerButtons.push(<div key="social_share"><SocialShare tree={tree} /></div>);
    answerButtons.push((tree.type === 'Template' && (
      <TemplateTreeSettings key="settings" object={tree} />
    ) || (
      <ProjectTreeSettings key="settings" object={tree} />
    )))
    return answerButtons;
  }
  getCodeButton = (tree, disableCode, selectType) => <div><Tooltip text="Switch to code"><ClickableIcon disabled={!tree || !tree._id || disableCode || selectType === 'Code'} onClick={() => SidebarService.updateSelectType('Code')} icon="fas fa-code" /></Tooltip></div>;
  getVersionContent = (marginTop) => {
    const { showVersions, showWysiwyg, showNodeTypes } = SidebarService;
    const { tree } = this.props;
    const { version, diffs, currentVersionDisplayed } = tree;
    if (!version || !diffs) return null;
    const htmlIcon = <div><Tooltip text="Toggle Wysiwyg"><ClickableIcon grayedOut={!showWysiwyg} onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} icon="fab fa-html5" /></Tooltip></div>;
    const versionIcon = <div><Tooltip text="Toggle Versions"><ClickableIcon grayedOut={!showVersions} onClick={() => SidebarService.updateShowVersions(!showVersions)} icon="fas fa-code-branch" /></Tooltip></div>;
    const showNodeTypesIcon = <div><Tooltip text="Toggle Node Types"><ClickableIcon grayedOut={!showNodeTypes} onClick={() => SidebarService.updateShowNodeTypes(!showNodeTypes)} icon="fas fa-info" /></Tooltip></div>;

    if (!showVersions) return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
    ];
    return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
      <Divider key="versions_divider"><div style={{ width: '100%', textAlign: 'center' }}>Versions</div></Divider>,
      <VersioningList
        key="versioning_list"
        node={tree}
        diffs={diffs}
        version={version}
        currentVersionDisplayed={currentVersionDisplayed}
        showVersion={this.showVersion}
        diffCache={TreeCache.diffCache}
      />
    ]
  }
  scrollTop = () => GrayOut.scrollTop();
  showVersion = (version, deltas, index) => {
    const { tree } = this.props;
    tree.showVersion(version, deltas.slice(0, index));
    GrayOut.clearAllActiveObjects();
  }
  changeValue = (activeObject, type, value) => {
    if (type === 'answerQuantity') activeObject.updateAnswerQuantity(value);
    else if (type === 'answerChoices') activeObject.updateAnswerChoiceType(value);
    else if (type === 'answerInputType') activeObject.updateAnswerInputType(value);
    else if (type === 'userInputFormat') activeObject.updateAnswerFormatType(value);
    else if (type === 'textVisibility') activeObject.updateTextVisibilityOption(value);
    else if (type === 'selectionType') activeObject.updateSelectionType(value);
  }
  updateAutoCreate(activeObject) {
    const drive = GoogleDriveCache.get(activeObject.text);
    if (!this.__updateAutoCreateCallback[activeObject.key]) this.__updateAutoCreateCallback[activeObject.key] = () => this.updateAutoCreate(activeObject);
    if (!drive) {
      GoogleDriveCache.onStateUpdate(this.__updateAutoCreateCallback[activeObject.key]);
      return this.forceUpdate();
    }
    this.removeUpdateAutoCreateCallback(activeObject.key);
    activeObject.convertDriveToTree(drive);
    return this.forceUpdate();
  }
  removeUpdateAutoCreateCallback = (key) => {
    GoogleDriveCache.offStateUpdate(this.__updateAutoCreateCallback[key]);
    delete this.__updateAutoCreateCallback[key];
  }
  viewAsProject = () => {
    const { tree } = this.props;
    if (!tree._id) return;
    Navigation.push(`${Navigation.trees}/${tree._id}`);
  }
  viewAsTemplate = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.trees}/${tree._id}/edit`);
  }
  createProject = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.trees}/${tree._id}?autocreate`);
  }
  render() {
    const { saveChanges, showEditor, tree, checkout, checkin, showMini } = this.props;
    const { isEditor, isCheckoutAllowed, isCheckedOut, isCheckinAllowed, isOwnerOrAdmin, isLocked, isDeleted } = tree;
    const { activeObject, activeObjectParents } = GrayOut.activeOrLastActiveObjectAndParents();
    const activeSettings = this.getActiveObjectSettings(activeObject);
    const { selectType, showVersions } = SidebarService;

    if (showMini) return null;

    if (showEditor) {
      const top = 0;
      const width = 240;
      const height = '100%';
      const borderBottomLeftRadius = 0;
      const borderTopLeftRadius = 0;
      const marginTop = 10;
      const overflowY = null;
      // const disableGraph = !tree || !tree._id || selectType === 'Graph';
      const showActiveObjectButtons = activeObject && activeObjectParents && selectType === 'Tree';
      const textHtmlWysiwygLine = this.getVersionContent(marginTop);

      const scrollTopIcon = () => <div><Tooltip text="Scroll to top"><ClickableIcon disabled={selectType !== 'Tree'} onClick={this.scrollTop} icon="fa fa-arrow-up" /></Tooltip></div>;
      const saveIcon = (disabled) => <div><Tooltip text="Save"><ClickableIcon disabled={disabled} onClick={() => saveChanges()} icon="fas fa-save" /></Tooltip></div>;
      const previewIcon = () => <div><Tooltip text="Preview"><ClickableIcon disabled={!tree._id} onClick={() => this.viewAsProject()} icon="fas fa-eye" /></Tooltip></div>;
      const treeIcon = () => <div><Tooltip text="Switch to tree"><ClickableIcon disabled={selectType === 'Tree'} onClick={() => SidebarService.updateSelectType('Tree')} icon="fas fa-tasks" /></Tooltip></div>;
      const graphIcon = () => <div><Tooltip text="Switch to doc"><ClickableIcon disabled={selectType === 'Doc'} onClick={() => SidebarService.updateSelectType('Doc')} icon="fas fa-file" /></Tooltip></div>;

      if (isDeleted) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <LockedMessage><i className="fa fa-trash" /><div>Deleted</div></LockedMessage>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {scrollTopIcon()}
              {saveIcon(true)}
              {previewIcon()}
            </AnswerButtons>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {treeIcon()}
              {graphIcon()}
              {this.getCodeButton(tree, false, selectType)}
            </AnswerButtons>
            {textHtmlWysiwygLine}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }

      if (isLocked) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <LockedMessage><i className="fa fa-lock" /><div>Locked</div></LockedMessage>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {scrollTopIcon()}
              {saveIcon(true)}
              {previewIcon()}
            </AnswerButtons>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {treeIcon()}
              {graphIcon()}
              {this.getCodeButton(tree, false, selectType)}
            </AnswerButtons>
            {textHtmlWysiwygLine}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (!isEditor) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            {isOwnerOrAdmin && isCheckedOut && !isCheckinAllowed && <AccessStatus><i className="fa fa-lock" /><div>Already In-use</div></AccessStatus>}
            {isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed && <AccessStatus role="button" onClick={() => checkout()}><i className="fa fa-lock" /><div>Start Editing</div></AccessStatus>}
            {!isOwnerOrAdmin && <div style={{ textAlign: 'center', padding: 10 }}>You dont have permission to edit</div>}
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {scrollTopIcon()}
              {saveIcon(true)}
              {previewIcon()}
            </AnswerButtons>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {treeIcon()}
              {graphIcon()}
              {this.getCodeButton(tree, false, selectType)}
            </AnswerButtons>
            {textHtmlWysiwygLine}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (showVersions) {
        return (
          <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
            <Scrollable
              vertical
              showVertical
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus role="button" onClick={() => checkin()}><i className="fa fa-unlock" /><div>Finish Editing</div></AccessStatus>}
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
              <div style={{ flex: 1, height: 40 }}></div>
            </Scrollable>
          </Wrapper>
        );
      }

      return (
        <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus role="button" onClick={() => checkin()}><i className="fa fa-unlock" /><div>Finish Editing</div></AccessStatus>}
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {scrollTopIcon()}
              {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
              {previewIcon()}
            </AnswerButtons>
            <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
              {treeIcon()}
              {graphIcon()}
              {this.getCodeButton(tree, false, selectType)}
            </AnswerButtons>
            {textHtmlWysiwygLine}
            {showActiveObjectButtons && <Divider onClick={() => { if (activeSettings) showSettings = !showSettings; this.forceUpdate(); }}><div>{`${activeObject.attributes[0]} Settings`}</div>{activeSettings && <i className={showSettings ? 'fas fa-caret-up' : 'fas fa-caret-down' } />}</Divider>}
            {showActiveObjectButtons && showSettings && activeSettings}
            {showActiveObjectButtons && showEditor && <Divider onClick={() => { showBasicTypes = !showBasicTypes; this.forceUpdate(); }}><div>Basic Types</div><i className={showBasicTypes ? 'fas fa-caret-up' : 'fas fa-caret-down' } /></Divider>}
            {showActiveObjectButtons && showBasicTypes && showEditor && <TreeEditBox basicTypes node={activeObject} />}
            {showActiveObjectButtons && showEditor && <Divider onClick={() => { showAdvancedTypes = !showAdvancedTypes; this.forceUpdate(); }}><div>Advanced Types</div><i className={showAdvancedTypes ? 'fas fa-caret-up' : 'fas fa-caret-down' } /></Divider>}
            {showActiveObjectButtons && showAdvancedTypes && showEditor && (
              <TreeEditBox node={activeObject} />
            )}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>
      );
    }
    const width = 40;
    const marginTop = 0;
    const buttons = this.getAnswerButtons();
    const top = 0;
    const height = '100%';
    return (
      <div>
        <Wrapper style={{ top, width, height }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Buttons style={{ marginTop, flexDirection: 'column', justifyContent: 'center' }}>
              {buttons}
            </Buttons>
          </Scrollable>
        </Wrapper>
      </div>
    );
  }
}
