import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Popup from '../../components/Popup/index';
import Constants from '../../submodules/logictry_config/constants';
import StatefulButton from '../../components/StatefulButton';
import SidebarService from '../../services/SidebarService';
import CreateProject from '../../services/CreateProject';
import { validateEmail } from '../../utils/regex';

const Wrapper = styled.div`
  // display: flex;
  max-width: 600px;
  padding: 40px;

  a {
    color: ${Constants.PrimaryColor};
  }
  input {
    margin: 20px 0px 40px 0px;
    width: 100%;
    border-bottom: 2px solid ${Constants.PrimaryColor} !Important;
  }
`;

class LoggedOutSavePopup extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    onClose: PropTypes.func,
  }
  componentDidMount() {
    CreateProject.onStateUpdate(this);
  }
  componentWillUnmount() {
    CreateProject.offStateUpdate(this);
  }
  state = {
    warning: '',
    creating: false,
  }
  accept = () => {
    const { tree } = this.props;
    const email = this.inputRef.value;
    if (email === '') return this.setState({ warning: 'Email blank' });
    if (validateEmail(email)) return this.setState({ warning: 'Invalid email' });
    if (SidebarService.selectType === 'Text') tree.reportHasBeenViewed();
    this.setState({ creating: true, warning: '' });
    return CreateProject.registerAndCreateProject(tree, email, null, (success) => {
      if (success) return this.props.onClose(true);
      return this.setState({ warning: 'Something went wrong', creating: false });
    });
  }
  render() {
    const { creating, warning } = this.state;
    const { isRegisteringAndCreatingProject, numberOfQueuedUploads } = CreateProject;
    return (
      <Popup
        allowBackgroundClose={!isRegisteringAndCreatingProject}
        showCloseButton={!isRegisteringAndCreatingProject}
        onClose={() => !isRegisteringAndCreatingProject && this.props.onClose()}
        absolute
      >
        <Wrapper>
          <img alt="logictry" style={{ margin: '0px 0px 10px 0px', height: 40 }} src="https://logictrystatic.s3.amazonaws.com/images/logo062623.svg" />
          <p>Please provide your email so we can save your results.</p>
          <input
            ref={(e) => { this.inputRef = e; }}
            type="email"
            name="email"
            autoComplete
            placeholder="Email"
          />
          <StatefulButton
            variant="raised"
            onClick={this.accept}
            text="Submit"
            warning={warning}
            status={isRegisteringAndCreatingProject && numberOfQueuedUploads > 0 && `* ${numberOfQueuedUploads} upload(s) remaining`}
            state={creating ? 'busy' : null}
          />
        </Wrapper>
      </Popup>
    );
  }
}
export default LoggedOutSavePopup;
