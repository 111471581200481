import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import TreeDisplay from '../../services/TreeDisplay';

const UserInput = styled.textarea`
  resize: none;
  overflow: hidden;
  min-height: ${Constants.defaultLineHeight};
  padding: 0px;
  overflow-y: auto;
`;
const Wrapper = styled.div`
  position: relative;
`;
const HiddenUserInput = styled(UserInput)`
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  overflow: auto;
  opacity: 0;
`;

export default class AutoGrowTextArea extends React.PureComponent {
  static propTypes = {
    innerRef: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }
  state = {
    height: 21,
  }
  onChange = (e) => {
    const { onChange } = this.props;
    this.autoGrow(e.target);
    if (onChange) onChange(e.target.value.replace(/\r?\n/g, '<br/>'));
  }
  autoGrow = (element) => {
    if (!element || !element.style) return;
    if (!this.hiddenRef) return;
    this.hiddenRef.value = this.inputRef.value;
    const { scrollHeight } = this.hiddenRef;
    this.setState({ height: scrollHeight });
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  updateRef = (e) => {
    const { innerRef } = this.props;
    this.inputRef = e;
    if (!e) return;
    if (innerRef) innerRef(e);
  }
  render() {
    const { onFocus, onBlur, placeholder, tabIndex, disabled, defaultValue, className } = this.props;
    const { mobile } = WindowSize;
    const { height } = this.state;
    if (this.inputRef && this.inputRef.value !== defaultValue) {
      this.inputRef.value = defaultValue;
      this.autoGrow(this.inputRef);
    }
    const width = '100%';
    return (
      <Wrapper style={{ height, width }}>
        <HiddenUserInput style={{ height: 1, width, fontSize: mobile ? '16px' : 'unset' }} ref={(e) => { this.hiddenRef = e }} />
        <UserInput
          disabled={disabled}
          onKeyDown={this.onKeyDown}
          style={{ height, width, minWidth: mobile ? '180px' : 'unset' }}
          tabIndex={tabIndex > -1 ? tabIndex : -1}
          ref={(e) => { this.updateRef(e); this.autoGrow(e); }}
          onFocus={(e) => { this.autoGrow(e.target); if (onFocus) onFocus(e); }}
          onChange={this.onChange}
          onBlur={(e) => { this.autoGrow(e.target); if (onBlur) onBlur(); }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={className}
        />
      </Wrapper>
    );
  }
}
