import Api from './Api';
import Server from './index';

class OpenAIApi extends Api {
  url = 'openai';
  async getEmbeddings(_object) {
    const url = `/${this.url}/embeddings`;
    return Server.__postRequest(url, _object);
  }
}

const singleton = new OpenAIApi();
export default singleton;
