import Model from './model';

import { ADMIN_ROLE, VIEWER_ROLE } from './permissions';

export default class ObjectModel extends Model {
  roles = [ADMIN_ROLE, VIEWER_ROLE];
  get isOwner() {
    return !!(this.userLoggedIn && (this.userLoggedIn === this.owner));
  }
  get isOwnerOrAdmin() {
    return !!(this.isDevAccount || this.userLoggedIn && (this.userLoggedIn === this.owner || this.users.find((_user) => (_user._id === this.userLoggedIn && _user.role === ADMIN_ROLE))));
  }
  get isAdmin() {
    return !!(this.userLoggedIn && (this.users.find((_user) => (_user._id === this.userLoggedIn && _user.role === ADMIN_ROLE))));
  }
  get isViewer() {
    return !!(this.userLoggedIn && (this.users.find((_user) => (_user._id === this.userLoggedIn && _user.role === VIEWER_ROLE))));
  }
  get isLocked() {
    return this.state === 'Locked';
  }
  get isDeleted() {
    return this.state === 'Deleted';
  }
  get isCheckedOut() {
    if (!this.editor) return false;
    if (this.editor._id === this.userLoggedIn) return true;
    if ((new Date() - new Date(this.editor.updateTime)) < 300000) return true;
    return false;
  }
  get isCheckoutAllowed() {
    if (this.isCheckedOut) return false;
    return this.isOwnerOrAdmin;
  }
  get isCheckinAllowed() {
    return this.isCheckedOut && this.editor._id === this.userLoggedIn;
  }
  get isEditor() {
    return this.isCheckedOut && this.editor._id === this.userLoggedIn;
  }
  get isDisabled() {
    return !this.isEditor;
  }
  addUser(_user, role) {
    if (this.users.find((user) => user._id === _user._id)) return;
    this.users.push({
      _id: _user._id,
      role,
    });
  }
  removeUser(_user) {
    const index = this.users.findIndex((user) => user._id === _user._id);
    if (index < 0) return;
    this.users.splice(index, 1);
  }
  addTeam(_team) {
    if (this.teams.find((user) => user._id === _team)) return;
    this.teams.push({
      _id: _team,
    });
  }
  toggleTag(_name, _option) {
    if (!this.tagGroups) this.tagGroups = [];
    const tagGroupIndex = this.tagGroups.findIndex(({ name }) => name === _name);
    const tagGroup = this.tagGroups[tagGroupIndex];
    if (tagGroup) {
      const includeIndex = tagGroup.options.indexOf(_option);
      if (includeIndex < 0) tagGroup.options.push(_option);
      else tagGroup.options.splice(includeIndex, 1);
      if (tagGroup.options.length === 0) this.tagGroups.splice(tagGroupIndex, 1);
    }
    else this.tagGroups.push({ name: _name, options: [_option] });
  }
  removeTeam(_team) {
    const index = this.teams.findIndex((user) => user._id === _team);
    if (index < 0) return;
    this.teams.splice(index, 1);
  }
  updateShareSetting(_setting) {
    this.shareSetting = _setting;
  }
  updateEmbedSetting(_setting) {
    this.embedSetting = _setting;
  }
  updateBackgroundColor(_color) {
    if (!this.background) this.background = {};
    this.background.color = _color;
  }
  updateBackgroundImage(_image) {
    if (!this.background) this.background = {};
    this.background.image = _image;
  }
  updateCustomSource(_url) {
    if (!this.customSource) this.customSource = {};
    this.customSource.url = _url;
  }
  updateCustomSourceConfig(_configString) {
    this.customSourceConfigString = _configString;
    if (!this.customSource) this.customSource = {};
    try {
      this.customSource.config = JSON.parse(_configString);
    } catch(e) {
      this.customSource.config = {};
    }
  }
}
