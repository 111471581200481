import React from 'react';
// import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ScriptLoader from '../../services/ScriptLoader';
import WindowSize from '../../services/WindowSize';

const PARTNERS = {
  "Communities (3/20)": {
    "Social Venture Partners...": {},
    "Austin Chamber of C...": {},
    "Gran Rapids Giving Day": {}
  },
  "Nonprofits (4/20)": {
    "Inner Explorer": {},
    "YMCA": {},
    "UNSDSN": {},
    "Queen’s Commonwealth...": {}
  },
  "Education (3/20)": {
    "UN Global Schools": {},
    "The Advocates": {},
    "SDG Academy": {}
  },
  "Authors (1/20)": {
    "Heart & Flow": {}
  },
  "Startups (2/20)": {
    "Tax Taker": {},
    "Charity Charge": {}
  },
  "Investors (5/20)": {
    "Private": {}
  }
}
let expertNode;

export default class LogicBaseExpertsPage extends React.PureComponent {
  static propTypes = {
    words: PropTypes.object,
  }
  componentDidMount() {
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/d3.min.js');
    ScriptLoader.onStateUpdate(this.loadd3Cloud);
    WindowSize.onStateUpdate(this);
    this.renderWordcloud();
  }
  componentWillUnmount() {
    ScriptLoader.offStateUpdate(this.loadd3Cloud);
    expertNode.parentNode.removeChild(expertNode);
    WindowSize.offStateUpdate(this);
  }
  loadd3Cloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js');
    this.renderWordcloud();
  }
  renderWordcloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) return;

    if (!expertNode) {
      // Specify the chart’s dimensions.
      const width = window.innerWidth - 210;
      const height = window.innerHeight - 64;
      const { mobile } = WindowSize;
      const radius = mobile ? 120 : Math.max(180, Math.min(width / 6, height / 6));

      function formatData(partners) {
        const data = [];
        Object.keys(partners).forEach((name) => {
          data.push({
            "name": name,
            "value": 1,
            "children": formatData(partners[name])
          })
        });
        return data;
      }

      const data = {
        "name": "flare",
        "children": formatData(PARTNERS)
      };

      // Create the color scale.
      const color = window.d3.scaleOrdinal(window.d3.quantize(window.d3.interpolateRainbow, data.children.length + 1));

      // Compute the layout.
      const hierarchy = window.d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);
      const root = window.d3.partition()
        .size([2 * Math.PI, hierarchy.height + 1])
        (hierarchy);
      root.each(d => d.current = d);

      // Create the arc generator.
      const arc = window.d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius - radius / 2)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1) - radius / 2)

      // Create the SVG container.
      const svg = window.d3.create("svg")
        .attr("viewBox", [-width / 2, -height / 2, width, height])
        .style("width", "100%")
        .style("height", "100%")
        .style("font", mobile ? "10px montserrat" : "12px montserrat");

      // Append the arcs.
      const path = svg.append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
        .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
        .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
        .attr("d", d => arc(d.current));

      // Make them clickable if they have children.
      path.filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);

      const format = window.d3.format(",d");
      path.append("title")
        .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);

      const label = svg.append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", d => +labelVisible(d.current))
        .attr("transform", d => labelTransform(d.current))
        .text(d => d.data.name);

      const parent = svg.append("circle")
        .datum(root)
        .attr("r", radius / 2)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .style("cursor", "pointer")
        .on("click", clicked);

      svg.append("image")
        .attr("xlink:href", "https://logictrystatic.s3.amazonaws.com/images/logiccircle061924.svg")
        .attr("x", -radius * 0.4)
        .attr("y", -radius * 0.4)
        .attr("width", radius * 0.8)
        .attr("height", radius * 0.8)
        .style('pointer-events', 'none');

      // Handle zoom on click.
      function clicked(event, p) {
        parent.datum(p.parent || root);

        root.each(d => d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        });

        const t = svg.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path.transition(t)
          .tween("data", d => {
            const i = window.d3.interpolate(d.current, d.target);
            return t => d.current = i(t);
          })
          .filter(function(d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
          .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")
          .attrTween("d", d => () => arc(d.current));

        label.filter(function(d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          }).transition(t)
          .attr("fill-opacity", d => +labelVisible(d.target))
          .attrTween("transform", d => () => labelTransform(d.current));
      }
      function arcVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
      }
      function labelVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
      }
      function labelTransform(d) {
        const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
        const y = (d.y0 + d.y1) / 2 * radius - radius / 2;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      }
      expertNode = svg.node()
    }

    document.getElementById('logictry_expert_mount').appendChild(expertNode);
  }
  render() {
    const { mobile } = WindowSize;
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: mobile ? 'fixed' : null, inset: 0 }} id="logictry_expert_mount" />
    )
  }
}