import Model from './model';

export default class LogicBaseCollection extends Model {
  constructor(_id, title, description, image, url, apps, owner, followers, editors) {
    super();
    this._id = _id;
    this.title = title;
    this.description = description;
    this.image = image;
    this.url = url;
    this.apps = apps;
    this.owner = owner;
    this.followers = followers;
    this.editors = editors || [];
  }
  getJson = () => {
    const { _id, title, description, image, url, apps, owner, followers, editors } = this;
    return {
      _id,
      t: title,
      d: description,
      i: image,
      u: url,
      a: apps,
      o: owner,
      fr: followers,
      e: editors,
    }
  }
}
