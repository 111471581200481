import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import UserProfileHeader from '../../components/UserProfileHeader';
import StatefulTextField from '../../components/StatefulTextField';
import Button from '../../components/Button';
import StatefulButton from '../../components/StatefulButton';
import CollectionsUserPopup from '../../components/CollectionsUserPopup';
import Scrollable from '../../components/Scrollable';
import ChangeableImage from '../../components/ChangeableImage';
import AppConstants from '../../submodules/logictry_config/s3_config.json';
import ConfirmDialog from '../../components/ConfirmDialog';

const Wrapper = styled.div`
  margin: auto;
  max-width: 52rem;
  padding: 4rem 1rem;
  h1 {
    font-weight: 600;
    font-size: ${Constants.VeryLargeFontSize};
    color: ${Constants.DarkText};
    text-align: center;
  }
  h2 {
    font-size: ${Constants.NormalFontSize};
  }
`;
const UserProfile = styled.div`
  color: ${Constants.Gray};
  
  p {
    margin: 0;
  }
  > div {
    textarea {
    border: 0.0625rem solid rgba(0,0,0,0.42);
    width: 100%;
    opacity: 1;
    height: 120px;
    overflow: hidden;
    resize: none;
    padding: 8px 8px;
    }
    margin: auto;
    > div {
      margin-top: 3rem;
    }
    > div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class LogicBaseCollectionEditPage extends React.PureComponent {
  state = {
    usersPopup: false,
    success: '',
    appname: Navigation.currentLocation[2],
    confirmDelete: false
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
  }
  revert = () => {
    if (this.collection._id) LogicBaseCollectionCache.revertObject(this.collection);
  }
  delete = () => {
    if (this.collection._id) LogicBaseCollectionCache.delete(this.collection);
    this.setState({ confirmDelete: false });
  }
  editCreate = () => {
    if (this.publicApps && this.publicApps.length > 0) {
      this.collection.apps = this.publicApps.map(({ _id }) => _id);
    }
    if (this.collection._id) LogicBaseCollectionCache.update(this.collection);
    else LogicBaseCollectionCache.create(this.collection);
    this.setState({ success: 'Success' })
  }
  onChange = (value) => {
    // const url = value.split('-').slice(1).join('-').replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
    // this.collection.url = `${this.prependString}${url}`;
    this.collection.url = value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
    this.forceUpdate();
  }
  // get prependString() {
  //   const { account } = UserAccount;
  //   return `${account.username.toLowerCase()}-`;
  // }
  render() {
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const notFound = <FullScreenVerticalCenterContent><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;
    const { mobile } = WindowSize;
    const { usersPopup, success, appname, confirmDelete } = this.state;
    const { account, isDevAccount } = UserAccount;
    let user;
    let publicUser;
    let contributors;
    const isCreating = appname === 'create';
    if (isCreating) {
      this.collection = this.collection || LogicBaseCollectionCache.createObject({ o: account._id, a: [] });
      user = account;
      publicUser = {};
      contributors = [];
      this.publicApps = [];
      if (!isDevAccount) return notFound;
    } else {
      const collections = LogicBaseCollectionCache.query({ u: appname.toLowerCase() });
      if (!collections) return loading;
      if (collections.length === 0) return notFound;
      this.collection = collections[0];
      this.publicApps = LogicBaseAppCache.getByIds(this.collection.apps);
      if (!this.publicApps) return loading;
      publicUser = LogicBaseUserCache.get(this.collection.owner);
      if (!publicUser) return loading;
      const users = UserCache.getUsersByIds([this.collection.owner]);
      if (!users) return loading;
      user = users[0];
      if (!user || !user._id) return notFound;
      contributors = this.collection.editors && UserCache.getUsersByIds(this.collection.editors) || [];
      if (account._id !== this.collection.owner && !isDevAccount) return notFound;
    }
    const s3Path = `${user._id}/`;
    const signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '0 1rem 2rem 1rem' : '0 2rem 2rem 1rem' }}
        rememberScrollPosition={`logicbasecollectionedit_${this.collection.title}`}
      >
        <Wrapper>
          <h1>{`${isCreating ? 'Create' : 'Edit'} Collection`}</h1>
          <UserProfile>
            <div>
              <div>
                <h2 style={{ cursor: 'pointer' }} onClick={() => this.setState({ usersPopup: true })}>Owner<i style={{ fontSize: Constants.SmallFontSize, marginLeft: '0.5rem' }} className="fas fa-edit"></i></h2>
                <UserProfileHeader
                  user={{
                    _id: user._id,
                    image: user.image,
                    description: user.description,
                    username: user.username,
                    fullname: user.fullname,
                    apps: publicUser.apps,
                    followers: publicUser.followers,
                    followees: publicUser.followees,
                  }}
                  small
                  hideFollow
                />
              </div>
              <div>
                <h2 style={{ cursor: 'pointer' }} onClick={() => this.setState({ usersPopup: true })}>Content Editors<i style={{ fontSize: Constants.SmallFontSize, marginLeft: '0.5rem' }} className="fas fa-edit"></i></h2>
                {contributors.map((editor) => (
                  <div key={editor._id} style={{ margin: '0.5rem 0' }}>
                    <UserProfileHeader
                      user={{
                        _id: editor._id,
                        image: editor.image,
                        description: editor.description,
                        username: editor.username,
                        fullname: editor.fullname,
                      }}
                      small
                      hideFollow
                    />
                  </div>
                ))}
              </div>
              <div>
                <h2>URL</h2>
                <StatefulTextField
                  value={this.collection.url || ''}
                  onChange={this.onChange}
                  hideUnderline
                  placeholder="Enter Url"
                  maxLength={50}
                  width={'100%'}
                />
              </div>
              <div>
                <h2>Title</h2>
                <StatefulTextField
                  value={this.collection.title}
                  onChange={(title) => { this.collection.title = title; this.forceUpdate(); }}
                  hideUnderline
                  placeholder="Enter Title"
                  maxLength={50}
                  width={'100%'}
                />
              </div>
              <div>
                <h2>Description</h2>
                <textarea onChange={(e) => { this.collection.description = e.target.value; this.forceUpdate(); }} defaultValue={this.collection.description} />
              </div>
              <ChangeableImage
                onlyAllowImage
                s3Path={s3Path}
                signingUrl={signingUrl}
                style={{
                  width: Constants.BoxSize,
                  height: Constants.BoxSize,
                  borderRadius: Constants.BoxRadius,
                  border: '1px dashed'
                }}
                backgroundImage={this.collection.image}
                onUploadFinish={(file) => {
                  const imageUrl = file.signedUrl.split('?')[0];
                  this.collection.image = imageUrl;
                  this.forceUpdate();
                }}
              />
              <div>
                <h2>Public Apps</h2>
                {this.publicApps.length > 0 && <LogicBaseAppList showUser apps={this.publicApps} listView allowSorting />}
              </div>
              <div>
                <Button onClick={this.revert}>Reset</Button>
                <StatefulButton
                  text={isCreating ? 'Create' : 'Update'}
                  onClick={this.editCreate}
                  success={success}
                />
                <Button onClick={() => this.setState({ confirmDelete: true })}>Delete</Button>
              </div>
            </div>
          </UserProfile>
          {usersPopup && <CollectionsUserPopup
            collection={this.collection}
            onClose={() => {
              this.setState({ usersPopup: false });
            }}
          />}
        </Wrapper>
        <ConfirmDialog text="Are you sure you wish to delete?" description="This is permanent and cannot be undone." open={!!confirmDelete} onNo={() => this.setState({ confirmDelete: false })} onYes={this.delete} />
      </Scrollable>
    );
  }
}
